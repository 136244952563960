import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ColDef, IServerSideDatasource, ServerSideStoreType } from 'ag-grid-community';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
    CommentActionButtonsRendererComponent,
    CommentDetailRendererComponent,
} from '@app/shared';
import { NotesService, ToastService } from '@core/services';
import { IResponseData } from '@core/interfaces';
import { dateFormatter } from '@core/utilities';

@Component({
    selector: 'app-view-comment',
    templateUrl: './view-comment.component.html',
    styleUrls: ['./view-comment.component.scss']
})
export class ViewCommentComponent implements OnDestroy {
    public columnDefs: ColDef[] = [
        {
            headerName: this.translateService.instant('Comment'),
            field: 'LastComment',
            cellRenderer: params => {
                return '<span title="' + params.data.LastComment + '">' + params.data.LastComment + '</span>';
            },
            minWidth: 180,
            suppressMenu: true
        },
        {
            field: 'LastCommentDate',
            headerName: this.translateService.instant('Date'),
            valueFormatter: dateFormatter,
            suppressMenu: true
        },
        {
            headerName: this.translateService.instant('CreatedBy'),
            field: 'FirstName',
            minWidth: 180,
            suppressMenu: true
        },

        {
            cellRendererSelector: () => {
                return {
                    component: CommentActionButtonsRendererComponent,
                    params: {
                        data: {
                            close: () => {
                                this.onCancel();
                            },
                            save: (comment) => {
                                this.onSave(comment);
                            }
                        }
                    }
                };
            },
            minWidth: 100,
            sortable: false,
            suppressMenu: true
        }
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        floatingFilter: false,
        resizable: true,
    };
    public cacheBlockSize: number = 50;
    public serverSideStoreType: ServerSideStoreType = 'partial';
    public rowModelType: string = 'serverSide';
    public headerHeight: number = 34;
    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            params.success(
                {
                    rowData: [this.config.data.comment],
                    rowCount: 1,
                }
            );
            this.params = params;
        },
    };
    public detailCellRenderer = CommentDetailRendererComponent;
    public detailCellRendererParams: any = {
        data: {DeviceVisitorId: this.config.data.DeviceVisitorId},
    };
    public params: IServerSideGetRowsParams;

    private unsubscribe$: Subject<void> = new Subject<void>();

    @ViewChild('addNoteRef') addNoteRef: ElementRef;

    constructor(
        private notesService: NotesService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getRowHeight = (params) => {
        const isDetailRow = params.node.detail;

        // for all rows that are not detail rows, return nothing
        if (!isDetailRow) {
            return undefined;
        }
        // otherwise return height based on number of rows in detail grid
        return 80;
    }

    onCancel(): void {
        this.ref.close();
    }

    onSave(comment): void {
        this.notesService.saveComment(comment)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: IResponseData<any[]>) => {
                if (res?.ResponseCode === 0) {
                    this.toastService.showToastMsg('success', 'EditingCommentSuccessMessage');
                    this.config.data.params.api.refreshServerSideStore();
                    this.ref.close();
                } else {
                    this.toastService.showToastMsg('error', res.Description);
                }
            });
    }

    subscribeToRouterEvents(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                takeUntil(this.unsubscribe$)
            ).subscribe(() => {
            this.ref.close();
        });
    }
}
