<ng-container *ngIf="!historyMode; else historyBlock">
    <form *ngIf="addNoteMode"
          [formGroup]="addNoteForm"
          class="add-note-form"
          #addNoteRef>
        <div class="p-fluid create-player-form-body">
            <div class="field mb-0">
                <label for="addNote">{{ 'AddNote' | translate }}</label>
                <textarea
                    pInputTextarea
                    formControlName="Message"
                    id="addNote"
                    [placeholder]="'WriteYourNoteHere' | translate"
                    [ngClass]="{'ng-touched ng-invalid' : addNoteForm.controls.Message.touched
                                && (addNoteForm.controls.Message.hasError('required')
                                || addNoteForm.controls.Message.hasError('maxlength'))}"></textarea>
                <small *ngIf="addNoteForm.controls.Message.touched
                           && addNoteForm.controls.Message.hasError('required')"
                       class="p-error">
                    {{ 'FieldRequiredErrorMessage' | translate }}
                </small>
                <small *ngIf="addNoteForm.controls.Message.touched
                           && addNoteForm.controls.Message.hasError('maxlength')"
                       class="p-error">
                    {{ 'MaxLengthValidationMessage' | translate: {length: 500} }}
                </small>
            </div>
        </div>
    </form>

    <app-ag-table
        class="radiused"
        [columnDefs]="columnDefs"
        [cacheBlockSize]="cacheBlockSize"
        [dataSource]="dataSource"
        [defaultColDef]="defaultColDef"
        [serverSideStoreType]="serverSideStoreType"
        [rowModelType]="rowModelType"
        [masterDetail]="true"
        [sideBar]="null"
        [headerHeight]="headerHeight"
        [detailCellRenderer]="detailCellRenderer"
        [getRowHeight]="getRowHeight"
    ></app-ag-table>

    <div class="p-dialog-footer">
        <button type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary">
        </button>
        <ng-container
            *ngIf="!addNoteMode && !(config.data.note && config.data.note[0].hideAddSaveBtn); else saveNoteButtonBlock">
            <button type="button"
                    pButton
                    pRipple
                    [label]="'Add' | translate"
                    (click)="onAddNote()"
                    icon="icon-plus"
                    class="p-button-text"></button>
        </ng-container>
        <ng-template #saveNoteButtonBlock>
            <button *ngIf="!(config.data.note && config.data.note[0].hideAddSaveBtn)"
                    type="button"
                    pButton
                    pRipple
                    [label]="'Save' | translate"
                    (click)="onSaveNote()"
                    icon="icon-save"
                    class="p-button-text"></button>
        </ng-template>
    </div>
</ng-container>
<ng-template #historyBlock>
    <div class="note-history">
        <div class="p-dialog-header">
             <span class="p-dialog-title">
                <button pButton
                        pRipple
                        type="button"
                        class="p-button-secondary p-button-text p-0 button-history"
                        (click)="goBack()">
                    <i class="icon-back p-0 ml-0 mr-1"></i>
                    {{ 'History' | translate }}
                </button>
             </span>
        </div>
        <div class="note-history-body">
                <ng-container *ngIf="loading; else historyBlock">
                    <span>{{ 'Loading' | translate }} <i class="pi pi-spin pi-spinner ml-2"></i></span>
                </ng-container>
                <ng-template #historyBlock>
                    <ng-container *ngIf="noteHistory.length; else emptyHistoryBlock">
                        <div class="note-history-body-inner">
                            <div *ngFor="let historyItem of noteHistory" class="note-history-item">
                                <div class="note-history-item-top flex">
                                    <span>
                                        {{ historyItem.CreatorFirstName }} {{ historyItem.CreatorLastName }}
                                    </span>
                                    <span>
                                        {{ historyItem.ChangeTime | date:'dd.MM.YYYY HH:mm:ss' }}
                                    </span>
                                    <span>
                                        {{ statesEnum[historyItem.State] }}
                                    </span>
                                </div>
                                <div class="note-history-item-bottom">
                                    {{ historyItem.Message }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #emptyHistoryBlock>
                        <div class="flex justify-content-center align-items-center no-data-found">
                            <i class="icon-no-data"></i>{{ 'NoDataFound' | translate }}
                        </div>
                    </ng-template>
                </ng-template>
            </div>
    </div>
</ng-template>
