import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { defer, EMPTY, iif, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import * as _ from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ChangePasswordDialogComponent, TransferDialogComponent } from '@app/shared';
import { AgentsService, AuthService, PartnersService, PermissionsService, ToastService } from '@core/services';
import { IAgentDetail, IChangePassword, IEditAgentForm, IEnum, ITransfer } from '@core/interfaces';
import { environment } from '@env/environment';
import { TransactionTypesEnum } from '@core/enums';

@Component({
    selector: 'app-personal-details',
    templateUrl: './personal-details.component.html'
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
    public isDesktopView: boolean = window.innerWidth > 620;

    public isEditMode: boolean = false;
    public pending: boolean = false;

    public agentDetailForm: FormGroup = new FormGroup<IEditAgentForm>({
        Id: new FormControl(null),
        FirstName: new FormControl('', Validators.maxLength(50)),
        LastName: new FormControl('', Validators.maxLength(50)),
        PhoneNumber: new FormControl(null, Validators.maxLength(50)),
        BankName: new FormControl('', Validators.maxLength(50)),
        BankAccountNumber: new FormControl('', Validators.maxLength(50)),
        Status: new FormControl(null),
        TimeZone: new FormControl<Date>(null)
    });
    private agentId: number;
    public agentDetail: IAgentDetail;
    private agentDetailOldValue: IAgentDetail;
    private isAgentDetailSame: boolean = false;

    public transactionTypesEnum = TransactionTypesEnum;

    public statuses: IEnum[];

    public isAdmin: boolean = environment.role === 'admin';

    private unsubscribe$: Subject<void> = new Subject<void>();

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.isDesktopView = window.innerWidth > 620;
    }

    constructor(
        private agentsService: AgentsService,
        private partnersService: PartnersService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private toastService: ToastService,
        public authService: AuthService,
        public permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.agentId = +this.route.parent.snapshot.params.id;
        this.statuses = this.agentsService.agentStates$.getValue();

        this.subscribeToAgentInfoChanges();
        this.followFormValueChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onSaveChanges(): void {
        if (this.agentDetailForm.valid) {
            if (!this.isAgentDetailSame) {
                this.changeAgentDetails();
            } else {
                this.isEditMode = false;
            }
        } else {
            this.agentDetailForm.markAllAsTouched();
        }
    }

    private changeAgentDetails(): void {
        this.pending = true;

        this.agentsService.changeAgentDetail(this.agentDetailForm.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.pending = false;
                this.agentsService.getAgentById(this.agentId);

                this.agentDetail = { ...this.agentDetail, ...this.agentDetailForm.value };
                this.formulateAgentDetail();
                this.agentDetailOldValue = this.agentDetailForm.value;

                this.isEditMode = false;

                this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Agent');
            }, (err: HttpErrorResponse) => {
                this.pending = false;
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public openTransferDialog(isPayout: boolean): void {
        const paramsData = this.agentsService.agentInfo$.getValue();
        const ref = this.dialogService.open(TransferDialogComponent, {
            header: this.translateService.instant(isPayout ? 'Payout' : 'OpenLimit'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
            data: {
                paramsData,
                showTransactionType: !isPayout,
                TransactionTypeId: isPayout ? null : this.transactionTypesEnum.Withdraw,
                Balance: isPayout ? paramsData.EarningBalance : paramsData.RealBalance,
                dialogInfo: [
                    {
                        Data: paramsData.UserName,
                        HasNavigation: false,
                        Icon: 'pi icon-user icon-size-lg',
                        Title: this.translateService.instant('Username'),
                    },
                    {
                        Data: paramsData.Id,
                        HasNavigation: true,
                        Icon: 'pi icon-client_type icon-size-lg',
                        Title: this.translateService.instant('AgentId'),
                    },
                    {
                        Data: paramsData.RealBalance,
                        HasNavigation: false,
                        Icon: 'pi icon-store_balance icon-size-lg',
                        Title: this.translateService.instant('Balance'),
                    },
                    ...(this.isAdmin && isPayout ?
                        [{
                            Data: paramsData.EarningBalance,
                            HasNavigation: false,
                            Icon: 'pi icon-store_balance icon-size-lg',
                            Title: this.translateService.instant('EarningBalance'),
                        }] : [])
                ]
            }
        });

        ref.onClose
            .pipe(
                filter(res => !!res),
                switchMap((modalData: any) => {
                    const data: Partial<ITransfer> = {
                        TransactionTypeId: modalData.TransactionTypeId,
                        ToAgentId: paramsData.Id,
                        ...modalData
                    };
                    if (this.isAdmin) {
                        data.FromUserId = this.authService.userData$.getValue().Id;
                    } else {
                        data.FromAgentId = this.authService.userData$.getValue().Id;
                    }
                    return defer(() => modalData && !isPayout ?
                        this.agentsService.transfer(data) : this.agentsService.payout(data));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.toastService.showToastMsg('success', 'SuccessfullySentMessage');
                this.agentsService.getAgentById(this.agentId);
                this.authService.getCurrentProfile();
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public openChangePasswordDialog(): void {
        const ref = this.dialogService.open(ChangePasswordDialogComponent, {
            header: this.translateService.instant('ChangePassword'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((modalData: any) => {
                    const changePass: IChangePassword = {
                        Id: this.agentDetail.Id,
                        ...modalData
                    };
                    return iif(() => modalData, this.agentsService.changePassword(changePass), EMPTY);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.toastService.showToastMsg(
                    'success',
                    this.translateService.instant('PasswordSuccessfullyChanged')
                );
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private followFormValueChanges(): void {
        this.agentDetailForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res && this.agentDetailForm.value) {
                    if (!this.agentDetailOldValue && this.agentDetailForm.controls.Id.value) {
                        this.agentDetailOldValue = { ...this.agentDetailForm.value };
                    }
                    this.isAgentDetailSame = _.isEqual(this.agentDetailOldValue, res);
                }
            });
    }

    public onCancel(): void {
        this.isEditMode = false;
    }

    private formulateAgentDetail(): void {
        if (this.agentDetail.Status) {
            this.agentsService.agentStates$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((agentStates) => {
                    this.agentDetail.StatusDetail = agentStates.find(item => item.Value === this.agentDetail.Status);
                });
        }
    }

    public enableEditMode(): void {
        this.isEditMode = true;
        this.agentDetailForm.reset();
        this.agentDetailForm.patchValue(this.agentDetail);
    }

    private subscribeToAgentInfoChanges(): void {
        this.agentsService.agentInfo$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((agentDetail) => {
                if (agentDetail) {
                    this.agentDetail = agentDetail;
                    this.formulateAgentDetail();
                }
            });
    }

}
