import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IEnum } from '@core/interfaces';

@Component({
    selector: 'app-agent-edit-info',
    templateUrl: './agent-edit-info.component.html'
})
export class AgentEditInfoComponent implements OnInit {
    @Input() agentDetail: any;
    @Input() isDesktopView: boolean;
    @Input() agentDetailForm: FormGroup;
    @Input() statuses: IEnum[];

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    public onUpdateTimeZone(event): void {
        if (event !== this.agentDetailForm.controls.TimeZone.value) {
            this.agentDetailForm.controls.TimeZone.setValue(event);
            this.agentDetailForm.controls.TimeZone.markAsDirty();
        }
    }
}
