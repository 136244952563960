<ng-container *ngIf="!agentDetail; else agentDetailBlock">
    <div class="spinner-container">
        <i class="pi pi-spin pi-spinner ml-2"></i>
    </div>
</ng-container>
<ng-template #agentDetailBlock>
    <div class="agent-info">
        <div [ngClass]="isDesktopView? 'main-actions' : 'm-main-actions'" *ngIf="!isEditMode, else editModeSection">

            <div class="title" *ngIf="isDesktopView">{{'MainActions' | translate}}:</div>
            <!-- When user has 'Admin' role, he must not ba able to change any Agent data except first level Agents -->
            <div *ngIf="!isAdmin || agentDetail && !agentDetail.ParentId"
                 [ngClass]="isDesktopView ? 'action-buttons' : 'm-action-buttons'">
                <ng-container *ngIf="isAdmin">
                    <ng-container *appCheckPermissions="permissionsService.permissions?.AgentPermissions?.MakeAgentPayout">
                        <div class="action-block">
                            <button type="button" pButton pRipple (click)="openTransferDialog(true)" class="p-button-text">
                                <span class="action-text" *ngIf="isDesktopView">{{'Payout' | translate}}</span>
                                <i class="pi icon-transactions icon-size-sm"></i>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *appCheckPermissions="isAdmin ?
            permissionsService.permissions?.AgentPermissions?.MakeAgentTransfer :
            permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.DepositToAgent
            .concat(permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.WithdrawalFromAgent)">
                    <div class="vertical-divider"></div>
                    <div class="action-block">
                        <button type="button" pButton pRipple (click)="openTransferDialog(false)" class="p-button-text">
                            <span class="action-text" *ngIf="isDesktopView">{{'Transfer' | translate}}</span>
                            <i class="pi icon-transactions icon-size-sm"></i>
                        </button>
                    </div>
                </ng-container>

                <ng-container *appCheckPermissions="isAdmin ?
                permissionsService.permissions?.AgentPermissions?.ChangeAgentPassword :
                permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.ChangeAgentPassword">
                    <div class="vertical-divider"></div>
                    <div class="action-block">
                        <button type="button" pButton pRipple (click)="openChangePasswordDialog()" class="p-button-text">
                            <span class="action-text" *ngIf="isDesktopView">{{'ChangePassword' | translate}}</span>
                            <i class="pi icon-password icon-size-sm"></i>
                        </button>
                    </div>
                </ng-container>

                <div *appCheckPermissions="permissionsService.permissions?.AgentPermissions?.EditAgent"
                     class="action-block">
                    <div class="vertical-divider"></div>
                    <button type="button" pButton pRipple (click)="enableEditMode()" class="p-button-text">
                        <span class="action-text" *ngIf="isDesktopView">{{'Edit' | translate}}</span>
                        <i class="pi icon-edit icon-size-sm"></i>
                    </button>
                </div>

            </div>
        </div>

        <ng-template #editModeSection>
            <div [ngClass]="{
                         'main-actions' : isDesktopView,
                         'm-main-actions' : !isDesktopView,
                         'm-action-edit-buttons' : !isDesktopView && isEditMode}">
                <div class="title"><span>{{'SaveYourChanges' | translate}}:</span></div>
                <div [ngClass]="{
                             'action-buttons' : isDesktopView,
                             'm-action-edit-buttons' : !isDesktopView && isEditMode}">
                    <div class="action-block">
                        <button type="button"
                                pButton
                                pRipple
                                [label]="'Cancel' | translate"
                                (click)="onCancel()"
                                icon="pi pi-times"
                                class="p-button-text p-button-secondary">
                        </button>
                    </div>
                    <div class="action-block">
                        <button type="button"
                                pButton
                                pRipple
                                [loading]="pending"
                                [label]="'Save' | translate"
                                [disabled]="agentDetailForm.valid && agentDetailForm.pristine"
                                (click)="onSaveChanges()"
                                icon="pi pi-check"
                                class="p-button-text">
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-container *ngIf="!isEditMode; else editModeBlock">
            <app-agent-info [agentDetail]="agentDetail"
                            [isDesktopView]="isDesktopView">
            </app-agent-info>
        </ng-container>

        <ng-template #editModeBlock>
            <app-agent-edit-info [agentDetail]="agentDetail"
                                 [agentDetailForm]="agentDetailForm"
                                 [isDesktopView]="isDesktopView"
                                 [statuses]="statuses"
            ></app-agent-edit-info>
        </ng-template>
    </div>

</ng-template>

