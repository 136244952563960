import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IAgentDetail, IBalance } from '@core/interfaces';
import {
    AgentsService,
    AuthService,
    BreadcrumbService,
    CommonService,
    PermissionsService
} from '@core/services';
import { environment } from '@env/environment';

@Component({
    selector: 'app-agent',
    templateUrl: './agent.component.html'
})
export class AgentComponent implements OnInit, OnDestroy {
    public tabMenuItems: MenuItem[];
    public activeItem: MenuItem;

    public agentDetail: IAgentDetail;
    public balances: Partial<IBalance> = {};
    private agentId: number;

    public isAdmin: boolean = environment.role === 'admin';

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        private translateService: TranslateService,
        private agentsService: AgentsService,
        private commonService: CommonService,
        private authService: AuthService,
        private permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.subscribeToRouteChanges();
        this.setTabMenuItems();
        this.subscribeToLangChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.agentsService.agentInfo$.next(null);
    }

    public setTabMenuItems(): void {
        this.tabMenuItems = [];

        this.setTabMenuItemsBasedOnPermissions();
        this.activeItem = this.tabMenuItems[0];
    }

    private setTabMenuItemsBasedOnPermissions(): void {
        if (this.isAdmin) {
            this.tabMenuItems.push(
                {
                    label: this.translateService.instant('PersonalDetails'),
                    routerLink: 'personal-details',
                    visible: !!this.permissionsService.checkPermission(this.permissionsService.permissions
                        ?.Other?.ViewPermissionTemplate)
                },
                {
                    label: this.translateService.instant('Commission'),
                    routerLink: 'commission',
                    visible: !!this.permissionsService.checkPermission(this.permissionsService.permissions
                        ?.CommissionsPermissions?.ViewCommissions)
                },
                {
                    label: this.translateService.instant('Permissions'),
                    routerLink: 'permissions',
                    visible: !!this.permissionsService.checkPermission(this.permissionsService.permissions
                        ?.AgentPermissions?.ViewAgentPermissions)
                },
                {
                    label: this.translateService.instant('Transactions'),
                    routerLink: 'transactions',
                    visible: !!this.permissionsService.checkPermission(this.permissionsService.permissions
                        ?.AgentPermissions?.ViewAgentTransactions)
                },
                {
                    label: this.translateService.instant('Earnings'),
                    routerLink: 'earnings',
                    visible: !!this.permissionsService.checkPermission(this.permissionsService.permissions
                        ?.CommissionsPermissions?.ViewEarningHistory)
                }
            );
        } else {
            this.tabMenuItems.push(
                {
                    label: this.translateService.instant('PersonalDetails'),
                    routerLink: 'personal-details',
                },
                {
                    label: this.translateService.instant('Commission'),
                    routerLink: 'commission',
                },
                {
                    label: this.translateService.instant('Permissions'),
                    routerLink: 'permissions',
                },
                {
                    label: this.translateService.instant('Transactions'),
                    routerLink: 'transactions',
                },
                {
                    label: this.translateService.instant('Earnings'),
                    routerLink: 'earnings',
                }
            );
        }
    }

    private getAgentDetail(): void {
        this.agentsService.getAgentById(this.agentId);

        combineLatest([
            this.agentsService.agentInfo$,
            this.commonService.accountTypes$
        ])
            .pipe(
                filter(r => !!r[0] && !!r[1]),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(res => {
                this.agentDetail = res[0];
                this.route.snapshot.data.breadcrumb.title = res[0]?.UserName;
                this.breadcrumbService.setBreadcrumb(this.router.routerState.snapshot.root, [], []);
                this.agentDetail.Accounts.forEach(account => {
                    this.balances[res[1].find(accountType => accountType.Value === account.AccountTypeId)?.Name] = account.Balance;
                });
            });
    }

    private subscribeToRouteChanges(): void {
        this.route.paramMap
            .subscribe((params: ParamMap) => {
                if (params.get('id')) {
                    this.agentId = +params.get('id');
                    if (this.agentId === this.authService.userData$.getValue().Id) {
                        this.router.navigate(['my-profile/personal-details']);
                    } else {
                        this.getAgentDetail();
                    }
                }
            });
    }

    private subscribeToLangChanges(): void {
        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.setTabMenuItems());
    }

}
