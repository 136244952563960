<div class="table-wrapper table-wrapper-full-page-height-permissions-table">
    <div class="permissions-container">
        <div class="permissions-container-item">
            <app-ag-table
                [name]="tableNameUserPartners"
                [columnDefs]="columnDefsUserPartners"
                [domLayout]="'normal'"
                [rowSelection]="rowSelection"
                [rowData]="userPartner"
                [isRowSelectable]="isRowSelectable"
                [getRowClass]="getRowClass"
                (selectionChanged)="userPartnersChange($event)"
            ></app-ag-table>
        </div>

        <div class="flex flex-column justify-content-center permission-buttons-container">
            <button pButton pRipple
                    *appCheckPermissions="permissionsService.permissions?.AdminUserPermissions?.AddPartnerToAdminUser"
                    [disabled]="!adminUserPartnerSelectedRow.length"
                    [label]="'Grant' | translate" (click)="addPartnerToUser()"
                    icon="pi icon-left" class="p-button-primary">
            </button>
            <button pButton pRipple
                    *appCheckPermissions="permissionsService.permissions?.AdminUserPermissions?.RemovePartnerFromAdminUser"
                    [disabled]="!userPartnerSelectedRow.length"
                    [label]="'Remove' | translate" (click)="removePartnerFromUser()" iconPos="right"
                    icon="pi icon-right" class="p-button-primary">
            </button>
        </div>

        <div class="permissions-container-item">
            <app-ag-table
                [name]="tableNameAdminUserPartners"
                [columnDefs]="columnDefsAdminUserPartners"
                [domLayout]="'normal'"
                [rowSelection]="rowSelection"
                [rowData]="adminUserPartner"
                [isRowSelectable]="isRowSelectable"
                [getRowClass]="getRowClass"
                (selectionChanged)="adminUserPartnersChange($event)"
            ></app-ag-table>
        </div>
    </div>
</div>
