<div class="inner-page-main-section">

    <form [formGroup]="commissionForm" (ngSubmit)="addEditCommission()">

        <div class="p-fluid p-formgrid grid form">
            <div class="field col-12">
                <label for="ProductCategory">{{ 'ProductCategory' | translate }} *</label>
                <p-dropdown [options]="productCategories"
                            [panelStyleClass]="'dialog-dropdown'"
                            [disabled]="config.data.commission"
                            (onChange)="getCommissionTypes($event.value)"
                            appendTo="body"
                            formControlName="ProductCategoryId"
                            optionLabel="Name"
                            optionValue="Value"
                            id="ProductCategory"
                            [placeholder]="'Select' | translate"
                            [ngClass]="{'ng-touched ng-invalid' : commissionForm.controls.ProductCategoryId.touched
                                                && commissionForm.controls.ProductCategoryId.invalid}">
                </p-dropdown>
                <small
                    *ngIf="commissionForm.controls.ProductCategoryId.touched
                                           && commissionForm.controls.ProductCategoryId.hasError('required')"
                    class="p-error">
                    {{ 'FieldRequiredErrorMessage' | translate }}
                </small>
            </div>
        <div class="field col-12">
            <label for="Type">{{ 'AccountType' | translate }} *</label>
            <p-dropdown [options]="types"
                        [panelStyleClass]="'dialog-dropdown'"
                        [disabled]="config.data.commission || !commissionForm.controls.ProductCategoryId.value"
                        (onChange)="setPercentMaxValue($event.value)"
                        appendTo="body"
                        formControlName="Type"
                        optionValue="Value"
                        id="Type"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : commissionForm.controls.Type.touched
                                                            && commissionForm.controls.Type.invalid}">
                <ng-template let-option pTemplate="selectedItem">
                    <span>{{ ('EnumCommissionAccountType' + option.Name) | translate }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{ ('EnumCommissionAccountType' + option.Name) | translate }}</span>
                </ng-template>
            </p-dropdown>
            <small
                *ngIf="commissionForm.controls.Type.touched
                                                       && commissionForm.controls.Type.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>
            <div class="field col-12">
                <label class="field-label-name">{{ 'Percent' | translate }} *</label>
                <input
                    pInputText
                    id="Percent"
                    type="number"
                    formControlName="Percent"
                    appTwoDigitDecimalNumber
                  />
                <app-decimal-up-down-container
                    class="decimal-up-down"
                [number]="commissionForm.controls.Percent.value"
                (updateNumber)="updateNumber($event)">

                </app-decimal-up-down-container>
                <small *ngIf="commissionForm.controls.Percent.touched
                       && commissionForm.controls.Percent.hasError('required')"
                       class="p-error">
                    {{ 'FieldRequiredErrorMessage' | translate }}
                </small>
                <small *ngIf="commissionForm.controls.Percent.touched
                       && commissionForm.controls.Percent.hasError('max')"
                       class="p-error">
                    {{ 'MaxValueError' | translate :
                    { value: commissionForm.controls.Percent.errors.max.max } }}
                </small>
            </div>


        </div>

        <div class="p-dialog-footer">
            <button
                type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary"
            ></button>
            <button
                type="submit"
                pButton
                pRipple
                [loading]="pending"
                [label]="'Save' | translate"
                [disabled]="commissionForm.valid && commissionForm.pristine"
                icon="pi pi-check"
                class="p-button-text"
            ></button>
        </div>

    </form>
</div>
