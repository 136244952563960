export enum TransactionTypesEnum {
    Deposit = 1,
    Withdraw = 2,
    Payout = 3,
}

export enum TransactionStatusTypesEnum {
    Completed = 1,
    Failed = 2
}
