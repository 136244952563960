import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    public pending: boolean = false;

    constructor(
        public ref: DynamicDialogRef,
        private router: Router,
        public config: DynamicDialogConfig,
    ) {
        this.subscribeToRouterEvents();
    }

    public onCancel(): void {
        this.ref.close(false);
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                take(1),
            )
            .subscribe(() => {
                this.ref.close();
            });
    }

    public onSave(): void {
        this.ref.close(true);
    }
}
