<div>
    <div class="inner-page-info-top-section agent-info-top-section">
        <div class="flex flex-wrap">
            <div class="top-section-item">
                <i class="pi icon icon-client_type icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'UserId' | translate }}
                    </div>
                    <div class="detail-value">
                        {{ adminUserDetail?.Id !== null ? adminUserDetail.Id : '-' }}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-currency icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Currency' | translate }}
                    </div>
                    <div class="detail-value uppercase">
                        {{ adminUserDetail?.Currency ? adminUserDetail.Currency : '-' }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="tabs-wrapper">
    <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem"></p-tabMenu>
    <router-outlet></router-outlet>
</div>
