<div class="table-wrapper table-wrapper-full-page-height">
    <div *appCheckPermissions="permissionsService.permissions?.AdminUserPermissions?.AddAdminUser"
         class="add-button-container">
        <button pButton pRipple [label]="'Add' | translate"
                icon="pi pi-plus" class="p-button-primary add-btn"
                (click)="openAddEditAdminUserDialog()">
        </button>
    </div>

    <app-ag-table
        [name]="tableName"
        [columnDefs]="columnDefs"
        [domLayout]="'normal'"
        [pagination]="pagination"
        [getContextMenuItems]="getContextMenuItems"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="paginationPageSize"
        [dataSource]="dataSource"
        [serverSideStoreType]="serverSideStoreType"
        [rowModelType]="rowModelType"
    ></app-ag-table>
</div>
