import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { AppMenuComponent } from './components/menu/app.menu.component';
import { AppMenuitemComponent } from './components/menu-item/app.menuitem.component';
import { AppTopBarComponent } from './components/topbar/app.topbar.component';
import {
    AppFooterComponent,
    AppMainComponent,
    AppConfigComponent,
} from '@core/components';
import { AppRightmenuComponent } from './components/rightmenu/app.rightmenu.component';
import { ConfigService } from '@core/services';
import { HttpInterceptor } from '@core/interceptors/http.interceptor';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { SubMenuPositionDirective } from '@shared/directives';


export function initConfig(config: ConfigService): object {
    return () => config.load();
}

const INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true
};

@NgModule({
    declarations: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppRightmenuComponent,
        SubMenuPositionDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule.forChild(),
        RadioButtonModule,
        DropdownModule,
        BreadcrumbModule,
        ButtonModule,
        RippleModule,
        InputTextModule,
        ReactiveFormsModule,
        InputSwitchModule,
        PanelMenuModule,
        MenuModule,
    ],
    exports: [
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppFooterComponent,
        AppMainComponent,
        AppConfigComponent,
        AppRightmenuComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [ConfigService],
            multi: true,
        },
        MessageService,
        INTERCEPTOR_PROVIDER,
        TitleCasePipe,
        DialogService,
    ]
})
export class CoreModule {
}
