export const AgentMenu = {
    items: [
        {
            label: 'Agents',
            icon: 'pi pi-fw icon-agents icon-size-sm',
            routerLink: ['/agents']
        },
        {
            label: 'Players',
            icon: 'pi pi-fw icon-players icon-size-sm',
            routerLink: ['/players']
        },
        {
            label: 'PlayerTransactions',
            icon: 'pi pi-fw icon-payments icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'DepositRequests',
                    routerLink: ['/player-requests', 'deposit']
                },
                {
                    label: 'WithdrawalRequests',
                    routerLink: ['/player-requests', 'withdrawal']
                },
                {
                    label: 'BalanceTransfers',
                    routerLink: ['/player-requests', 'transfers']
                }
            ]
        },
        {
            label: 'AgentTransaction',
            icon: 'pi pi-fw icon-transaction icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'BalanceTransfer',
                    routerLink: ['/agent-transactions', 'transfers']
                }
            ]
        },
        {
            label: 'MessagingCenter',
            icon: 'pi pi-fw icon-message icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'MessagesToPlayers',
                    routerLink: ['/messaging-center', 'messages', 'messages-to-players']
                },
                {
                    label: 'NotificationsToPlayers',
                    routerLink: ['/messaging-center', 'notifications', 'notifications-to-players']
                }
            ]
        },
        {
            label: 'Reports',
            icon: 'pi pi-fw icon-reports icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'ByBets',
                    routerLink: ['/reports', 'by-bets']
                },
                {
                    label: 'ByPlayers',
                    routerLink: ['/reports', 'by-players']
                },
                {
                    label: 'ByAgents',
                    routerLink: ['/reports', 'by-agents']
                },
                {
                    label: 'ByProduct',
                    routerLink: ['/reports', 'by-product']
                },
                {
                    label: 'ByCategory',
                    routerLink: ['/reports', 'by-category']
                },
                {
                    label: 'ByEarnings',
                    routerLink: ['/reports', 'by-earnings']
                }
            ]
        }
    ]
};

