import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Subject } from 'rxjs';
import { takeUntil, take, switchMap, filter } from 'rxjs/operators';

import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
    ColDef,
    ColGroupDef,
    IServerSideDatasource,
    ServerSideStoreType
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';

import {
    IAgentDetail,
    IEarning,
    IFilter,
    IPlayer,
    IResponseWithCount,
} from '@core/interfaces';
import {
    AgentsService,
    AuthService,
    CommonService,
    LanguageService,
    PlayersService,
    ToastService
} from '@core/services';
import { dataFormatterByValue, generateColumnDefs } from '@core/utilities';
import { AmountRendererComponent, DateCellRenderer } from '@app/shared';

@Component({
    selector: 'app-earning',
    templateUrl: './earning.component.html',
    styleUrls: ['./earning.component.scss']
})
export class EarningComponent implements OnInit, OnDestroy {

    public tableName: string;
    public pinnedBottomRowData: any = [{ isTotal: true }];
    public columnDefs: Array<ColDef | ColGroupDef> = [
        {
            field: 'ProductCategoryId',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Category'),
            valueFormatter: dataFormatterByValue.bind(this, this.commonService.productCategoryTypes$),
            filter: 'agSetColumnFilter',
            filterParams: {
                values: params => {
                    this.commonService.productCategoryTypes$
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(data => {
                            params.success(data.map(d => d.Value));
                        });
                },
                valueFormatter: dataFormatterByValue.bind(this, this.commonService.productCategoryTypes$),
            },
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'Date',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Date'),
            cellRenderer: DateCellRenderer,
            sortable: true,
            headerClass: 'center-aligned',
            cellClass: 'text-center',
            minWidth: 80,
        },
        {
            field: 'Amount',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Amount'),
            cellRenderer: AmountRendererComponent,
            filter: 'agNumberColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            type: 'rightAligned',
            minWidth: 80,
        }
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        floatingFilter: true,
        resizable: true
    };
    public pagination: boolean = true;
    public paginationPageSize: number = 50;
    public cacheBlockSize: number = 50;
    private filterObj: IFilter;
    public params: IServerSideGetRowsParams;
    public serverSideStoreType: ServerSideStoreType = 'partial';
    public rowModelType: string = 'serverSide';

    private isPlayersPage: boolean;
    private id: number;
    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            this.params = params;
            this.getEarningsById(this.filterObj, params);
        }
    };

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private languageService: LanguageService,
        private agentsService: AgentsService,
        private commonService: CommonService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private playersService: PlayersService,
    ) {
    }

    ngOnInit(): void {
        this.isPlayersPage = this.router.url.includes('players');
        this.id = +this.route.parent.snapshot.params.id;
        this.modifyColDefsRegardingTheCurrentRoute();
        this.setTableName();
        this.setTableData();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private modifyColDefsRegardingTheCurrentRoute(): void {
        if (this.isPlayersPage) {
            this.columnDefs.splice(1, 1, {
                field: 'EarningTypeId',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Type'),
                valueFormatter: dataFormatterByValue.bind(this, this.agentsService.earningTypes$),
                sortable: true,
                minWidth: 80,
            });
        } else {
            this.columnDefs.splice(2, 0, {
                field: 'EarningTypeId',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Type'),
                valueFormatter: dataFormatterByValue.bind(this, this.agentsService.earningTypes$),
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: params => {
                        this.agentsService.earningTypes$
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(data => {
                                params.success(data.map(d => d.Value));
                            });
                    },
                    valueFormatter: dataFormatterByValue.bind(this, this.agentsService.earningTypes$),
                },
                sortable: true,
                minWidth: 80,
            });
        }
    }

    private setTableName(): void {
        this.tableName = !this.id ? 'my-profile-earnings'
            : this.isPlayersPage ? 'player-bonus-history' : 'agent-earnings';
    }

    private setTableData(): void {
        if (this.id) {
            iif(() => this.isPlayersPage, this.playersService.playerDetail$, this.agentsService.agentInfo$)
                .pipe(
                    filter(res => !!res),
                    switchMap((res: IPlayer | IAgentDetail) => {
                        this.commonService.getProductCategoryTypes(res.PartnerId);
                        return this.commonService.productCategoryTypes$;
                    }),
                    takeUntil(this.unsubscribe$),
                )
                .subscribe({
                    next: () => this.columnDefs = generateColumnDefs(this.tableName, this.columnDefs)
                });
        } else {
            this.commonService.getProductCategoryTypes(this.authService.userGetter().PartnerId);
            this.columnDefs = generateColumnDefs(this.tableName, this.columnDefs);
        }
    }

    private getEarningsById(filterObj: IFilter, params: IServerSideGetRowsParams): void {
        const payload: any = {
            filterModel: params.request.filterModel,
            sortModel: params.request.sortModel,
            TakeCount: this.paginationPageSize,
            SkipCount: params.request.endRow / this.paginationPageSize - 1,
            ...(this.isPlayersPage ? { PlayerId: this.id } : {
                AgentId: this.id ? this.id :
                    this.authService.userData$.getValue().Id
            }),
            ...filterObj
        };

        this.agentsService.getEarningsById(payload)
            .pipe(take(1))
            .subscribe((res: IResponseWithCount<IEarning[]>) => {
                params.success({
                    rowData: res.Entities,
                    rowCount: res.Count
                });
                params.columnApi.autoSizeAllColumns(true);
                params.api.sizeColumnsToFit();
                res.Count ? params.api.hideOverlay() : params.api.showNoRowsOverlay();
                this.pinnedBottomRowData[0].Amount = res.Total?.Amount;
            }, err => {
                params.api.showNoRowsOverlay();
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private translateEnum(params: ValueFormatterParams): string {
        const translatedEnum = `EnumEarningTypes${dataFormatterByValue(this.agentsService.earningTypes$, params)}`;
        return this.languageService.localizeTableHeader.call(this, translatedEnum);
    }

    public getSelectedFilters(filterFields: IFilter): void {
        this.filterObj = filterFields;
        this.params?.api.paginationGoToPage(0);
        this.params?.api.onFilterChanged();
    }
}
