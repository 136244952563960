<form [formGroup]="createAdminUserForm">

    <div class="p-fluid p-formgrid grid form mt-0">

        <div class="field col-12 md:col-6">
            <label for="UserName">{{ 'Username' | translate }} *</label>
            <input pInputText id="UserName" type="text" formControlName="UserName"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.UserName.touched
                            && createAdminUserForm.controls.UserName.invalid}"/>
            <small *ngIf="createAdminUserForm.controls.UserName.touched
                       && createAdminUserForm.controls.UserName.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small *ngIf="createAdminUserForm.controls.UserName.touched
                       && createAdminUserForm.controls.UserName.hasError('pattern')"
                   class="p-error">{{ 'PatternPartnerName' | translate }}
            </small>
            <small class="p-error" *ngIf="createAdminUserForm.controls.UserName.touched
                                        && createAdminUserForm.controls.UserName.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="role">{{ 'Role' | translate }} *</label>
            <p-dropdown [options]="rolesService.roles$ | async"
                        [panelStyleClass]="'dialog-dropdown'"
                        appendTo="body"
                        formControlName="RoleId"
                        optionLabel="Name"
                        optionValue="Value"
                        id="role"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.RoleId.touched
                                    && createAdminUserForm.controls.RoleId.invalid}">
            </p-dropdown>
            <small
                *ngIf="createAdminUserForm.controls.RoleId.touched
                           && createAdminUserForm.controls.RoleId.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="currency">{{ 'Currency' | translate }} *</label>
            <p-dropdown [options]="commonService.currencies$ | async"
                        [panelStyleClass]="'dialog-dropdown'"
                        appendTo="body"
                        formControlName="Currency"
                        optionLabel="Name"
                        optionValue="Name"
                        id="currency"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.Currency.touched
                                    && createAdminUserForm.controls.Currency.invalid}">
            </p-dropdown>
            <small
                *ngIf="createAdminUserForm.controls.Currency.touched
                               && createAdminUserForm.controls.Currency.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="Password">{{ 'Password' | translate }} *</label>
            <input pInputText id="Password" type="password" formControlName="Password" autocomplete="new-password"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.Password.touched
                            && createAdminUserForm.controls.Password.invalid}"/>
            <small *ngIf="createAdminUserForm.controls.Password.touched
                       && createAdminUserForm.controls.Password.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small class="p-error" *ngIf="createAdminUserForm.controls.Password.touched
                                        && createAdminUserForm.controls.Password.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="ConfirmPassword">{{ 'ConfirmPassword' | translate }} *</label>
            <input pInputText id="ConfirmPassword" type="password" formControlName="ConfirmPassword"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.ConfirmPassword.touched
                            && createAdminUserForm.controls.ConfirmPassword.invalid}"/>
            <small *ngIf="createAdminUserForm.controls.ConfirmPassword.touched
                       && createAdminUserForm.controls.ConfirmPassword.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small class="p-error" *ngIf="createAdminUserForm.controls.ConfirmPassword.touched
                                        && createAdminUserForm.controls.ConfirmPassword.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
            <small *ngIf="createAdminUserForm.controls.ConfirmPassword.touched &&
                       !createAdminUserForm.controls.ConfirmPassword.hasError('required')
                       && createAdminUserForm.hasError('passwordMatch')"
                   class="p-error">{{ 'PasswordsMatchError' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="FirstName">{{ 'FirstName' | translate }}</label>
            <input pInputText id="FirstName" type="text" formControlName="FirstName"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.FirstName.touched
                            && createAdminUserForm.controls.FirstName.invalid}"/>
            <small *ngIf="createAdminUserForm.controls.FirstName.touched
                       && createAdminUserForm.controls.FirstName.hasError('pattern')"
                   class="p-error">{{ 'PatternPartnerName' | translate }}
            </small>
            <small class="p-error" *ngIf="createAdminUserForm.controls.FirstName.touched
                                        && createAdminUserForm.controls.FirstName.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="LastName">{{ 'LastName' | translate }}</label>
            <input pInputText id="LastName" type="text" formControlName="LastName"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.LastName.touched
                            && createAdminUserForm.controls.LastName.invalid}"/>
            <small *ngIf="createAdminUserForm.controls.LastName.touched
                       && createAdminUserForm.controls.LastName.hasError('pattern')"
                   class="p-error">{{ 'PatternPartnerName' | translate }}
            </small>
            <small class="p-error" *ngIf="createAdminUserForm.controls.LastName.touched
                                        && createAdminUserForm.controls.LastName.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="PhoneNumber">{{ 'PhoneNumber' | translate }}</label>
            <input pInputText id="PhoneNumber" type="text" formControlName="PhoneNumber"
                   [ngClass]="{'ng-touched ng-invalid' : createAdminUserForm.controls.PhoneNumber.touched
                            && createAdminUserForm.controls.PhoneNumber.invalid}"/>
            <small class="p-error" *ngIf="createAdminUserForm.controls.PhoneNumber.touched
                                        && createAdminUserForm.controls.PhoneNumber.hasError('maxlength')">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label class="field-label-name">{{ 'TimeZone' | translate }} </label>
            <div class="input-error-section">
                <app-time-zone-container
                    [timeZoneOffset]="createAdminUserForm.controls.TimeZone.value"
                    (updateTimeZone)="onUpdateTimeZone($event)">
                </app-time-zone-container>
            </div>
        </div>

    </div>

    <div class="p-dialog-footer">
        <button
            type="button"
            pButton
            pRipple
            [label]="'Cancel' | translate"
            (click)="onCancel()"
            icon="pi pi-times"
            class="p-button-text p-button-secondary"
        ></button>
        <button
            type="button"
            pButton
            pRipple
            [loading]="pending"
            [disabled]="createAdminUserForm.valid && createAdminUserForm.pristine"
            [label]="'Save' | translate"
            (click)="onCreateAdminUser()"
            icon="pi pi-check"
            class="p-button-text"
        ></button>
    </div>

</form>
