import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-boolean-transform',
    template: `
        <div class="field-value">
            <span class="status-badge" [ngClass]="boolean ? 'true-status' : 'false-status'">
                {{boolean ? ("Yes" | translate | uppercase) : ("No" | translate | uppercase)}}
            </span>
        </div>
    `,
    styleUrls: ['./boolean-transform.component.scss']
})
export class BooleanTransformComponent implements OnInit {

    @Input() boolean: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
