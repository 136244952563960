import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RolesService } from '@core/services';
import { IAdminUser, IAdminUserForm } from '@core/interfaces';

@Component({
    selector: 'app-admin-user-edit-info',
    templateUrl: './admin-user-edit-info.component.html',
    styleUrls: ['./admin-user-edit-info.component.scss']
})
export class AdminUserEditInfoComponent implements OnInit {

    @Input() adminUserDetail: IAdminUser;
    @Input() adminUserDetailForm: FormGroup<IAdminUserForm>;
    @Input() isDesktopView: boolean;

    constructor(
        public rolesService: RolesService,
    ) {
    }

    ngOnInit(): void {
    }

    public onUpdateTimeZone($event: number): void {
        if ($event !== this.adminUserDetailForm.controls.TimeZone.value ) {
            this.adminUserDetailForm.controls.TimeZone.setValue($event);
            this.adminUserDetailForm.controls.TimeZone.markAsDirty();
        }
    }
}
