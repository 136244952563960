import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-boolean-component',
    template: `
        <div class="icon-wrapper">
            <i [ngClass]="value ? 'icon-done_rounded' : 'icon-remove_rounded'" class="pi icon-size-md"></i>
        </div>
    `,
    styles: ['.icon-done_rounded { color: #79F2B8; } .icon-remove_rounded { color: #EE6464; }']
})
export class BooleanRendererComponent implements ICellRendererAngularComp {
    public value: boolean = false;

    constructor() {}

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        this.value = params.value;
    }
}
