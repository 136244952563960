import { Component, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Tooltip } from 'primeng/tooltip';

@Component({
    selector: 'app-text-with-copy-icon-component',
    template: `
        <ng-container *ngIf="!params.data.isTotal && text">
            <div class="w-full flex gap-1 justify-content-between align-items-center">
                <span class="cursor-auto">
                    {{ text }}
                </span>
                <i class="cursor-pointer pi icon icon-copy icon-size-sm"
                   (click)="showCopyTooltip($event, text)"
                   [pTooltip]="'Copied' | translate | titlecase"
                   [life]="2000"
                   appendTo="body"
                   tooltipEvent="focus">
                </i>
            </div>
        </ng-container>
    `
})
export class TextWithCopyIconRenderer implements ICellRendererAngularComp {
    @ViewChild(Tooltip) tooltip!: Tooltip;

    public text: string;
    public params: ICellRendererParams;

    constructor() {}

    public showCopyTooltip(evn: any, text: string): void {
        evn.stopPropagation();
        this.tooltip.activate();

        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.refresh(params);
    }

    refresh(params: ICellRendererParams): boolean {
        const dataParams = params.colDef.cellRendererParams;
        this.text = _.isFunction(dataParams.text) ? dataParams.text(params) : dataParams.text;
        return false;
    }
}
