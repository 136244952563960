import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
    CanActivateFn,
    CanMatchFn
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsService } from '@core/services';
import { environment } from '@env/environment';

const isAdmin: boolean = environment.role === 'admin';

const checkPermissions = (route: Route | ActivatedRouteSnapshot): Observable<boolean> | boolean => {
    const router = inject(Router);
    const permissionsService = inject(PermissionsService);

    if (isAdmin) {
        return permissionsService.userPermissions$
            .pipe(
                map(perms => {
                    if (permissionsService.filteredAdminMenuItems.length) {
                        const hasPermission: boolean = route.data.permissions
                            .every(permission => perms.hasOwnProperty(permission));
                        if (hasPermission) {
                            return true;
                        } else {
                            if (permissionsService.filteredAdminMenuItems[0].routerLink) {
                                router.navigate(permissionsService.filteredAdminMenuItems[0].routerLink);
                            } else {
                                router.navigate(permissionsService.filteredAdminMenuItems[0].items[0].routerLink);
                            }
                            return false;
                        }
                    } else {
                        router.navigate(['/access-denied']);
                        return false;
                    }
                })
            );
    } else {
        return true;
    }
};

const canMatchHandler = (
    route: Route,
    segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return checkPermissions(route);
};

const canActivateHandler = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return checkPermissions(route);
};

export const canMatchPermissionsGuard: CanMatchFn = canMatchHandler;
export const canActivatePermissionsGuard: CanActivateFn = canActivateHandler;
