import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AuthService, MessagingCenterService, ToastService } from '@core/services';
import { IMessage, IMessageGroup } from '@core/interfaces';

@Component({
    selector: 'app-notice-history-dialog',
    templateUrl: './notice-history-dialog.component.html',
    styleUrls: ['./notice-history-dialog.component.scss']
})
export class NoticeHistoryDialogComponent implements OnInit, OnDestroy {

    public configData: IMessageGroup;
    public viewOnlyDialog: boolean;
    public sendMessageFormControl: FormControl<string> = new FormControl<string>('');
    public messageHistoryData: IMessage[] = [];
    public timeZone: string;

    private unsubscribe$: Subject<void> = new Subject<void>();

    @ViewChild('scrollContainer') scrollContainer: ElementRef;


    constructor(
        public router: Router,
        public ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private messagingCenterService: MessagingCenterService,
        private toastService: ToastService,
        private authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.subscribeToRouterEvents();
        this.configData = this.config.data.paramsData;
        this.viewOnlyDialog = this.config.data.viewOnly;
        this.timeZone = this.authService.timezoneGetter;
        this.getConversationHistoryByMessageId();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private getConversationHistoryByMessageId(): void {
        this.messagingCenterService.loadConversationHistoryByMessageId(this.configData.Id)
            .pipe(
                switchMap((res: IMessage[]) => {
                    this.messageHistoryData = res;
                    return this.activateTimer();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.scrollToBottom();
            });
    }

    private activateTimer(): Observable<number> {
        return timer(0);
    }

    private scrollToBottom(): void {
        if (!this.router.url.includes('notifications')) {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    public sendMessage(): void {
        if (this.sendMessageFormControl.value) {
            if (this.sendMessageFormControl.value) {
                const payload = {
                    Id: this.configData.Id,
                    Text: this.sendMessageFormControl.value
                };
                this.messagingCenterService.replyMessage(payload)
                    .pipe(
                        switchMap((res: IMessage) => {
                            this.messageHistoryData.push(res);
                            return this.activateTimer();
                        }),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(() => {
                        this.scrollToBottom();
                    }, (err) => {
                        this.toastService.showToastMsg('error', err?.error?.message);
                    });
                this.sendMessageFormControl.setValue('');
            }
        }

    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
            ).subscribe(() => {
            this.ref.close();
        });
    }

}
