<div class="message-receiver-container">
    <span class="action-text">{{'SentTo' | translate | uppercase}}:</span>
    <span class="receiver-username">{{configData.PlayerUserName}}</span>
    <span>({{'ID' | translate}}: {{configData.PlayerId}})</span>
</div>


<div class="message-history-container" #scrollContainer>
    <ng-container *ngIf="!router.url.includes('notifications'); else notificationsContainer">
        <div class="flex" [ngClass]="{'flex-row-reverse': message.Direction === 1}"
             *ngFor="let message of messageHistoryData">
            <div class="message-container" *ngIf="message.Direction === 1; else receivedMessage">
                <div class="message-header flex justify-content-between flex-row-reverse">
                    <b>{{'Me' | translate}}</b>
                    <div>{{message.CreationTime | date:'dd.MM.YYYY - HH:mm:ss' : timeZone}}</div>
                </div>
                <div class="message-content sent-text">
                    {{message.Text}}
                </div>
            </div>
            <ng-template #receivedMessage>
                <div class="message-container">
                    <div class="message-header flex justify-content-between">
                        <b>
                            {{message.PlayerUserName | translate}}: ({{'ID' | translate}}: {{configData.PlayerId}})
                        </b>
                        <div>{{message.CreationTime | date:'dd.MM.YYYY - HH:mm:ss' : timeZone}}</div>
                    </div>
                    <div class="message-content received-text" [innerHTML]="message.Text">

                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #notificationsContainer>
        <div *ngIf="messageHistoryData.length" [innerHtml]="messageHistoryData[0].Text | safe:'html'"></div>
    </ng-template>
</div>

<div class="button-container col-12">
    <div class="p-dialog-footer" *ngIf="viewOnlyDialog; else dialogWithReply">
        <button
            pButton
            pRipple
            [label]="'Close' | translate"
            (click)="ref.close()"
            icon="pi pi-times"
            class="p-button-text p-button-secondary">
        </button>
    </div>

    <ng-template #dialogWithReply>
        <span class="p-input-icon-right w-full">
            <input pInputText type="text" class="w-full" [placeholder]="'WriteYourMessageHere' | translate"
                   [formControl]="sendMessageFormControl" (keyup.enter)="sendMessage()">
            <i class="pi icon-size-sm icon-vector cursor-pointer" (click)="sendMessage()"></i>
        </span>
    </ng-template>

</div>
