import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, iif, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import * as _ from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { PermissionsService, ToastService } from '@core/services';
import { AdminUsersService } from '@pages/admin-users';
import { ChangePasswordDialogComponent } from '@app/shared';
import { passwordsMatch } from '@core/validators';
import { IAdminUser, IAdminUserForm, IChangePassword, IEnum } from '@core/interfaces';
import { RegexesEnum } from '@core/enums';


@Component({
    selector: 'app-admin-user-personal-details',
    templateUrl: './admin-user-personal-details.component.html',
    styleUrls: ['./admin-user-personal-details.component.scss']
})
export class AdminUserPersonalDetailsComponent implements OnInit, OnDestroy {

    public isDesktopView: boolean = window.innerWidth > 620;

    public isEditMode: boolean = false;
    public pending: boolean = false;

    public adminUserDetailForm: FormGroup = new FormGroup<IAdminUserForm>({
        Id: new FormControl(+this.route.parent.snapshot.params.id),
        UserName: new FormControl('', [
            Validators.required,
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        FirstName: new FormControl('', [
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        LastName: new FormControl('', [
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        Currency: new FormControl('', Validators.required),
        PhoneNumber: new FormControl('', [
            Validators.maxLength(50)
        ]),
        TimeZone: new FormControl(new Date().getTimezoneOffset() / -60, [
            Validators.maxLength(50)
        ]),
        RoleId: new FormControl(null, Validators.required),
    }, { validators: [passwordsMatch('Password', 'ConfirmPassword')] });

    private unsubscribe$: Subject<void> = new Subject<void>();
    public adminUserDetail: IAdminUser;
    private adminUserDetailOldValue: IAdminUser;
    private isAdminUserDetailSame: boolean = false;

    constructor(
        private dialogService: DialogService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private adminUsersService: AdminUsersService,
        private route: ActivatedRoute,
        public permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.subscribeToAdminUserInfoChanges();
        this.followFormValueChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private subscribeToAdminUserInfoChanges(): void {
        this.adminUsersService.adminUserInfo$
            .pipe(
                filter(res => !!res),
                switchMap((res: IAdminUser) => {
                    this.adminUserDetail = res;
                    return this.adminUsersService.adminUserStates$;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (adminUserStates: IEnum[]) => {
                    if (this.adminUserDetail.Status) {
                        this.adminUserDetail.StatusDetail = adminUserStates.find(state => state.Value === this.adminUserDetail.Status);
                    }
                }
            });
    }

    private followFormValueChanges(): void {
        this.adminUserDetailForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res && this.adminUserDetailForm.value) {
                    if (!this.adminUserDetailOldValue && this.adminUserDetailForm.controls.Id.value) {
                        this.adminUserDetailOldValue = { ...this.adminUserDetailForm.value };
                    }
                    this.isAdminUserDetailSame = _.isEqual(this.adminUserDetailOldValue, res);
                }
            });
    }

    public openChangePasswordDialog(): void {
        const ref = this.dialogService.open(ChangePasswordDialogComponent, {
            header: this.translateService.instant('ChangePassword'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((modalData: any) => {
                    const changePass: IChangePassword = {
                        Id: +this.route.parent.snapshot.params.id,
                        ...modalData
                    };
                    return iif(() => modalData, this.adminUsersService.changePassword(changePass), EMPTY);
                }),
                take(1)
            )
            .subscribe(() => {
                this.toastService.showToastMsg(
                    'success',
                    this.translateService.instant('PasswordSuccessfullyChanged')
                );
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public enableEditMode(): void {
        this.isEditMode = true;
        this.adminUserDetailForm.reset();
        this.adminUserDetailForm.patchValue(this.adminUsersService.adminUserInfo$.getValue());
    }

    public onCancel(): void {
        this.isEditMode = false;
    }

    public onSaveChanges(): void {
        if (this.adminUserDetailForm.valid) {
            if (!this.isAdminUserDetailSame) {
                this.changeAdminUserDetails();
            } else {
                this.isEditMode = false;
            }
        } else {
            this.adminUserDetailForm.markAllAsTouched();
        }
    }

    private changeAdminUserDetails(): void {
        this.pending = true;

        this.adminUsersService.changeAdminUserDetails(this.adminUserDetailForm.value)
            .pipe(take(1))
            .subscribe({
                next: res => {
                    this.pending = false;
                    this.adminUsersService.getAdminUserById(+this.route.parent.snapshot.params.id);

                    this.adminUserDetail = { ...this.adminUserDetail, ...this.adminUserDetailForm.value };
                    this.adminUserDetailOldValue = this.adminUserDetailForm.value;

                    this.isEditMode = false;

                    this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Agent');
                },
                error: (err: HttpErrorResponse) => {
                    this.pending = false;
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

}
