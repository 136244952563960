import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AuthService } from '@core/services';

@Component({
    selector: 'app-amount-component',
    template: `
        <ng-container *ngIf="!params.data.IsTotal; else total">
            <span>{{ (params.data[params.colDef.field]  | number: roundingOption )}}</span>
        </ng-container>
        <ng-template #total>
            <span>
                {{ params.data[params.colDef.field] | number : roundingOption }}
                {{ params.data[params.colDef.field] && params.data.IsTotal ? currency: '' }}
            </span>
        </ng-template>
    `
})
export class AmountRendererComponent implements ICellRendererAngularComp {
    public amount: number;
    public params: ICellRendererParams;
    public currency: string;
    public points: number;
    public roundingOption: string;
    constructor(
        private authService: AuthService,
    ) {
        this.getUserCurrency();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        this.amount = params.value;
        this.params = params;
        this.roundingOption = '.2-2';
    }

    private getUserCurrency(): void {
      // this.currency = this.authService.userData$.getValue()?.CurrencyId;
        this.currency = 'USD';
    }
}
