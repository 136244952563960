import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '@env/environment';
import { HttpService } from '@core/services/http.service';
import { ToastService } from '@core/services/toast.service';
import { UpdateStorageService } from '@core/services/update-storage.service';
import { Methods } from '@core/enums';
import {
    ICommonSelectItem,
    ILanguage,
    IPaymentRequestInterface,
    IResponseWithCount,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public accountTypes$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject([]);
    public transactionTypes$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject([]);
    public transactionStatusesTypes$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject([]);
    public productCategoryTypes$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject([]);
    public currencies$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public langList$: BehaviorSubject<ILanguage[]> = new BehaviorSubject<ILanguage[]>(null);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpService,
        private toastService: ToastService,
        private updateStorageService: UpdateStorageService,
    ) {
        this.subscribeToConfigVersionChanges();
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.getAccountTypes();
                this.getTransactionTypes();
                this.loadCurrencyEnum();
                this.loadLanguages();
                this.getTransactionStatusesTypes();
            });
    }

    public getAccountTypes(): void {
        if (localStorage.getItem('enum_account-types')) {
            this.accountTypes$.next(JSON.parse(localStorage.getItem('enum_account-types')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.ACCOUNT_GET_ACCOUNT_TYPES)
                .pipe(
                    map(epics => epics.filter(epic => epic.Name !== 'Cashback')),
                    take(1)
                )
                .subscribe((res) => {
                    localStorage.setItem('enum_account-types', JSON.stringify(res));
                    this.accountTypes$.next(res);
                });
        }

    }

    public getTransactionTypes(): void {
        if (localStorage.getItem('enum_transaction-types')) {
            this.transactionTypes$.next(JSON.parse(localStorage.getItem('enum_transaction-types')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.ACCOUNT_GET_TRANSACTION_TYPES)
                .pipe(take(1))
                .subscribe((res) => {
                    res.map(status => {
                        status.Background = status.Name === 'Deposit' ? '#79F2B8' : '#FFD6DA';
                        status.Color = '#3F3F3F';
                    });
                    localStorage.setItem('enum_transaction-types', JSON.stringify(res));
                    this.transactionTypes$.next(res);
                });
        }
    }

    public getTransactionStatusesTypes(): void {
        if (localStorage.getItem('enum_transaction-statuses-types')) {
            this.transactionStatusesTypes$.next(JSON.parse(localStorage.getItem('enum_transaction-statuses-types')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_TRANSACTIONS_STATUSES)
                .pipe(take(1))
                .subscribe((res) => {
                    res.map(status => {
                        status.Background = status.Name === 'Completed' ? '#79F2B8' : '#FFD6DA';
                        status.Color = '#3F3F3F';
                    });
                    localStorage.setItem('enum_transaction-statuses-types', JSON.stringify(res));
                    this.transactionStatusesTypes$.next(res);
                });
        }
    }

    public onRetryPayment(payload: any): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ACCOUNT_RETRY_PAYMENT,
            payload
        );
    }

    public changePassword(data: any, isAdmin: boolean): Observable<any> {
        const endpoint = isAdmin ? Methods.USER_CHANGE_PASSWORD : Methods.AGENT_CHANGE_PASSWORD;
        return this.http.request('put', environment.ApiUrl + endpoint, data);
    }

    public getPlayerDepositRequests(payload: any): Observable<IResponseWithCount<IPaymentRequestInterface[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_DEPOSIT_REQUESTS,
            payload
        );
    }

    public getPlayerWithdrawRequests(payload: any): Observable<IResponseWithCount<IPaymentRequestInterface[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_WITHDRAW_REQUESTS,
            payload
        );
    }

    public approveRequest(requestId: number): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.AGENT_APPROVE_REQUEST,
            null,
            false,
            { params: { requestId } }
        );
    }

    public rejectRequest(requestId: number): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.AGENT_REJECT_REQUEST,
            null,
            false,
            { params: { requestId } });
    }

    public getProductCategoryTypes(partnerId): void {
        this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PRODUCT_CATEGORY_TYPES,
            null,
            false,
            { params: { partnerId } }
        )
            .pipe(take(1))
            .subscribe((res) => {
                this.productCategoryTypes$.next(res);
            });
    }

    public loadCurrencyEnum(): void {

        if (localStorage.getItem('enum_currencies')) {
            this.currencies$.next(JSON.parse(localStorage.getItem('enum_currencies')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_CURRENCIES,
                null,
                false,
                null,
                false
            )
                .subscribe((res: any) => {
                    // TODO: ask Backend to change response structure, in order to avoid current unnecessary iteration
                    const currencies = [];
                    res.forEach((currency: string) => {
                        currencies.push({ Name: currency });
                    });
                    localStorage.setItem('enum_currencies', JSON.stringify(currencies));
                    this.currencies$.next(currencies);
                }, (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                });
        }
    }

    public loadLanguages(forceCall = false): void {
        if (localStorage.getItem('enum_languages') && !forceCall) {
            this.langList$.next(JSON.parse(localStorage.getItem('enum_languages')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_LANGUAGES_ENUM)
                .subscribe({
                    next: res => {
                        localStorage.setItem('enum_languages', JSON.stringify(res));
                        this.langList$.next(res);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.toastService.showToastMsg('error', err?.error?.message);
                    }
                });
        }
    }
}
