import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, skip, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ICommonSelectItem, IDialogInfo, IParent, IPlayerByUserName, ITransferForm } from '@core/interfaces';
import { AgentsService, AuthService, CommonService, PlayersService, ToastService } from '@core/services';
import { AccountTypesEnum, TransactionTypesEnum } from '@core/enums';
import { environment } from '@env/environment';

@Component({
    selector: 'app-transfer-dialog',
    templateUrl: './transfer-dialog.component.html',
    styleUrls: ['./transfer-dialog.component.scss'],
    // If you want to set the feature level config for ngx-mask, add provideNgxMask to your component providers
    // providers: [provideNgxMask()]
})
export class TransferDialogComponent implements OnInit, OnDestroy {
    public transactionTypesEnum = TransactionTypesEnum;
    public showTransactionType: boolean = false;
    public showAgentsDropdown: boolean = false;
    public showPlayersDropdown: boolean = false;
    public showFromAgentDropdown: boolean = false;
    public isAdmin: boolean = environment.role === 'admin';

    public transferForm: FormGroup = new FormGroup<ITransferForm>({
        AccountTypeId: new FormControl(AccountTypesEnum.Real, [Validators.required]),
        Amount: new FormControl(
            null,
            [Validators.required, Validators.maxLength(50), Validators.min(0)]
        ),
        Comment: new FormControl(null, [Validators.maxLength(250)]),
    });
    public dialogInfo: IDialogInfo[];

    public players: IPlayerByUserName[];
    public agentSearchOptions: { [key: string]: IParent[] } = {
        agents: [],
        fromAgents: [],
        toAgents: []
    };
    public accountTypes: ICommonSelectItem[];
    public selectedPlayer: IPlayerByUserName;
    public selectedAgents: { [key: string]: IParent } = {
        agent: null,
        fromAgent: null,
        toAgent: null
    };

    public onPlayerFilterChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public onAgentFilterChange$: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject<{ [key: string]: string }>({
        value: '',
        optionKey: ''
    });

    private unsubscribe$: Subject<void> = new Subject<void>();

    public userBalance: number;
    public calculator = {
        column_1: [1, 10, 100, 1000, 10000, 100000],
        column_2: [2, 20, 200, 2000, 20000, 200000],
        column_3: [5, 50, 500, 5000, 50000, 500000]
    };

    constructor(
        public config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private translateService: TranslateService,
        private router: Router,
        private commonService: CommonService,
        private authService: AuthService,
        private agentsService: AgentsService,
        private toastService: ToastService,
        private playersService: PlayersService,
    ) {
        this.dialogInfo = this.config.data.dialogInfo;
        this.showTransactionType = this.config.data.showTransactionType;
        this.showAgentsDropdown = this.config.data.showAgentsDropdown;
        this.showFromAgentDropdown = this.config.data.showFromAgentDropdown;
        this.showPlayersDropdown = this.config.data.showPlayersDropdown;

        if (this.showTransactionType) {
            this.transferForm.addControl('TransactionTypeId', new FormControl(this.config.data.TransactionTypeId));
        }

        if (this.showAgentsDropdown) {
            this.transferForm.addControl('ToAgentId', new FormControl(null, Validators.required));
        }

        if (this.showFromAgentDropdown) {
            this.transferForm.addControl('FromAgentId', new FormControl(null, Validators.required));
        }

        if (this.showPlayersDropdown) {
            this.transferForm.addControl('ToPlayerId', new FormControl(null, Validators.required));
        }
    }

    ngOnInit(): void {
        this.subscribeToRouterEvents();
        this.subscribeToAgentChanges();
        if (this.showPlayersDropdown) {
            this.subscribeToPlayerChanges();
        }
        // this.getAccountTypes();
        if ( !this.isAdmin ) {
            this.setUserBalance();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onCancel(): void {
        this.ref.close();
    }

    // private getAccountTypes(): void {
    //     this.commonService.accountTypes$
    //         .pipe(takeUntil(this.unsubscribe$))
    //         .subscribe((res: ICommonSelectItem[]) => {
    //             this.accountTypes = res;
    //         });
    // }

    private getAgents(params: any): void {
        const payload: any = {
            UserName: params.value,
        };

        payload[this.isAdmin ? 'PartnerId' : 'ParentId'] = this.config.data.PartnerId || this.authService.userGetter().Id;

        this.agentsService.getAgentByUserName(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res) => {
                    this.agentSearchOptions[params.optionKey] = res;
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    private getPlayers(userName: string): void {
        const payload: any = {
            UserName: userName,
            PartnerId: this.config.data.PartnerId
        };

        this.playersService.getPlayerByUserName(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res) => {
                    this.players = res;
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    private setUserBalance(): void {
        this.userBalance = this.authService.userData$.getValue().Accounts
            ?.find((account) => account.AccountTypeId === AccountTypesEnum.Real)?.Balance;
    }

    public onOpenLimit(): void {
        if (this.transferForm.valid) {
            this.ref.close(this.transferForm.value);
        } else {
            this.transferForm.markAllAsTouched();
        }
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
            this.ref.close();
        });
    }

    private subscribeToAgentChanges(): void {
        this.onAgentFilterChange$
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(700),
                skip(1))
            .subscribe({
                next: params => {
                    params.value ? this.getAgents(params) : this.agentSearchOptions[params.optionKey] = [];
                }
            });
    }

    private subscribeToPlayerChanges(): void {
        this.onPlayerFilterChange$
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(700),
                skip(1))
            .subscribe({
                next: value => {
                    value ? this.getPlayers(value) : this.players = [];
                }
            });
    }

    public onChangeTransactionType(transactionTypeId: number): void {
        this.transferForm.controls.TransactionTypeId.setValue(transactionTypeId);

        this.config.data.TransactionTypeId = transactionTypeId;
    }

    public calculateAmount(count: number): void {
        this.transferForm.markAsDirty();
        const amountController = this.transferForm.controls.Amount;
        amountController.setValue(count + Number(amountController.value));
    }

    public agentSelected(event, formControlKey: string, selectedAgentKey: string): void {
        if (event.value) {
            this.selectedAgents[selectedAgentKey] = event.value;
            this.transferForm.controls[formControlKey].setValue(event.value.Id,
                {emitEvent: false, emitModelToViewChange: false});
        } else {
            this.selectedAgents[selectedAgentKey] = null;
        }
    }

    public playerSelected(event): void {
        if (event.value) {
            this.selectedPlayer = event.value;
            this.transferForm.controls.ToPlayerId.setValue(event.value.Id,
                {emitEvent: false, emitModelToViewChange: false});
        } else {
            this.selectedPlayer = null;
        }
    }
}
