import { Component, Input, OnInit } from '@angular/core';

import { IEnum } from '@core/interfaces';

@Component({
    selector: 'app-status-view',
    templateUrl: './status-view.component.html'
})
export class StatusViewComponent implements OnInit {
    @Input() statusDetail: IEnum;
    @Input() translationPrefix: string = '';

    constructor() {
    }

    ngOnInit(): void {
    }

}
