<div class="layout-footer">
<!--    <div class="footer-logo-container">-->
<!--        <img id="footer-logo"-->
<!--             src="/assets/images/powered_by.svg"-->
<!--             alt="logo"/>-->
<!--    </div>-->
    <span class="copyright">
<!--        {{'PoweredBy' | translate}},-->
        {{'versionShort' | translate}}{{projectVersion}}
    </span>
</div>
