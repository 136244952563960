import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EMPTY, iif, pipe, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Tooltip } from 'primeng/tooltip';

import {
    AuthService,
    BreadcrumbService,
    CommonService,
    ConfigService,
    LanguageService,
    ThemeService,
    ToastService,
    WssService,
} from '@core/services';
import { AgentsService } from '@core/services/agents.service';
import { TransferDialogComponent } from '@app/shared';
import { AppMainComponent } from '@core/components';
import { IAppConfig, IWssResponse } from '@core/interfaces';
import { IAccounts, IAgentDetail, ILanguage, ILanguageMenuItem, ITransfer, IUser } from '@core/interfaces';
import { AccountTypesEnum, TransactionTypesEnum } from '@core/enums';
import { environment } from '@env/environment';
import { AGENTS_TABLE_PARAMS } from '@pages/agents/containers';
import { WssMethods } from '@core/enums/wss-methods';


@Component({
    selector: 'app-topbar',
    styleUrls: ['./app.topbar.component.scss'],
    templateUrl: './app.topbar.component.html',
})

export class AppTopBarComponent implements OnInit, OnDestroy {
    @ViewChild(Tooltip) tooltip!: Tooltip;

    public breadcrumbItems: MenuItem[];
    public selectedLang: { [key: string]: string };

    public config: IAppConfig;
    public darkModeActive: boolean = false;

    public isAdmin: boolean = environment.role === 'admin';

    private unsubscribe$: Subject<void> = new Subject<void>();
    public totalBalance: IAccounts;
    public earningBalance: IAccounts;
    public panelMenuItems: MenuItem[];
    public languagesForPanelMenu: ILanguageMenuItem[] = [];
    private partnerSiteUrl: string;
    private rIds: string[] = [];

    constructor(
        public breadcrumbService: BreadcrumbService,
        public configService: ConfigService,
        public appMain: AppMainComponent,
        public languageService: LanguageService,
        public authService: AuthService,
        public themeService: ThemeService,
        private dialogService: DialogService,
        private toastService: ToastService,
        private translateService: TranslateService,
        public agentsService: AgentsService,
        private commonService: CommonService,
        private router: Router,
        private wssService: WssService,
    ) {
        this.changeColorScheme(themeService.colorScheme);

        this.breadcrumbService.breadcrumbItems$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                this.breadcrumbItems = response;
            });
        this.generateLanguageDropdown();

        this.config = this.configService.config;
        this.darkModeActive = this.themeService.colorScheme === 'dim';

        if (!this.router.url.includes('my-profile')) {
            this.authService.getCurrentProfile();
        }

        this.authService.userData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((user: IUser) => {
                if (user && !this.isAdmin) {
                    this.totalBalance =
                        user.Accounts?.find((balanceItem: IAccounts) => balanceItem.AccountTypeId === AccountTypesEnum.Real);
                    this.earningBalance =
                        user.Accounts?.find((balanceItem: IAccounts) => balanceItem.AccountTypeId === AccountTypesEnum.Earning);
                    this.partnerSiteUrl = user.SiteUrl;
                }
            });
        this.wssService.initWebSocket();
    }

    ngOnInit(): void {
        this.wssService.wssIsAuth$
            .pipe(
                filter(res => !!res),
                switchMap(() => {
                    const rId: string = this.wssService.ridGeneratorGetter;
                    this.rIds.push(rId);
                    this.wssService.send(rId, WssMethods.TASK_NOTIFICATIONS, this.wssService.taskNotifications$);
                    return this.wssService.taskNotifications$;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.wssService.drop(this.rIds);
        this.wssService.closeConnection();
    }

    private fixupItems(items): void {
        items?.forEach((item) => {
            item.command = (e) => {
                e.originalEvent.stopPropagation();
                if (!e.item.items) {
                    this.changeLanguage(e.item.value.id);
                    this.panelMenuItems[0].expanded = false;
                }
            };
            this.fixupItems(item.items);
        });
    }

    public changeLanguage(lang: string): void {
        this.languageService.setLanguage(lang);
        this.appMain.onTopbarUserMenuButtonClick();
        this.generateLanguageDropdown();
        this.setLanguageItems();
    }

    public changeColorScheme(scheme): void {
        if (scheme === 'light') {
            this.configService.updateConfig({ ...this.config, ...{ dark: false } });
        } else {
            this.configService.updateConfig({ ...this.config, ...{ dark: true } });
        }
        this.themeService.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.themeService.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.themeService.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.themeService.setRootColorVariables();
    }

    public changeTheme(e): void {
        e.originalEvent.stopPropagation();
        const scheme = e.checked ? 'dim' : 'light';

        if (scheme === 'light') {
            this.configService.updateConfig({ ...this.config, ...{ dark: false } });
        } else {
            this.configService.updateConfig({ ...this.config, ...{ dark: true } });
        }
        this.themeService.colorScheme = scheme;
        localStorage.setItem('selectedTheme', scheme);
        this.themeService.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.themeService.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
        this.themeService.setRootColorVariables();
    }

    public openBalanceTransferDialog(): void {
        const paramsData: IAgentDetail = this.authService.userData$.getValue() as IAgentDetail;
        const ref = this.dialogService.open(TransferDialogComponent, {
            header: this.translateService.instant('Payout'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
            data: {
                paramsData,
                showTransactionType: false,
                TransactionTypeId: TransactionTypesEnum.Payout,
                Balance: paramsData?.EarningBalance,
                dialogInfo: [
                    {
                        Data: paramsData.UserName,
                        HasNavigation: false,
                        Icon: 'pi icon-user icon-size-lg',
                        Title: this.translateService.instant('Username'),
                    },
                    {
                        Data: paramsData.Id,
                        HasNavigation: true,
                        Icon: 'pi icon-client_type icon-size-lg',
                        Title: this.translateService.instant('AgentId'),
                    },
                    {
                        Data: paramsData.RealBalance,
                        HasNavigation: false,
                        Icon: 'pi icon-store_balance icon-size-lg',
                        Title: this.translateService.instant('Balance'),
                    },
                    {
                        Data: paramsData.EarningBalance,
                        HasNavigation: false,
                        Icon: 'pi icon-store_balance icon-size-lg',
                        Title: this.translateService.instant('EarningBalance'),
                    }

                ]
            }
        });
        ref.onClose
            .pipe(
                switchMap((modalData: ITransfer) => {
                    if (modalData) {
                        delete modalData.AccountTypeId;
                    }
                    return iif(() => !!modalData, this.agentsService.earningTransfer(modalData), EMPTY);
                }),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.toastService.showToastMsg('success', 'SuccessfullySentMessage');
                    this.authService.getCurrentProfile();
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public openTransferDialog(): void {
        const paramsData: IAgentDetail = this.authService.userData$.getValue() as IAgentDetail;
        const ref = this.dialogService.open(TransferDialogComponent, {
            header: this.translateService.instant('Transfer'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding topbar-transfer-dialog',
            data: {
                paramsData,
                showTransactionType: true,
                showAgentsDropdown: true,
                TransactionTypeId: TransactionTypesEnum.Deposit,
                Balance: paramsData?.EarningBalance,
            }
        });
        ref.onClose
            .pipe(
                filter(res => !!res),
                switchMap((modalData: any) => {
                    const data: ITransfer = {
                        TransactionTypeId: modalData.TransactionTypeId,
                        FromAgentId: this.authService.userData$.getValue().Id,
                        ...modalData
                    };
                    return iif(() => modalData, this.agentsService.transfer(data), EMPTY);
                }),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.toastService.showToastMsg('success', 'SuccessfullySentMessage');
                    this.authService.getCurrentProfile();
                    AGENTS_TABLE_PARAMS?.api?.refreshServerSide();
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public onCopyPlayerRegistrationLink(event): void {
        event.stopPropagation();
        this.tooltip.activate();

        if (navigator.clipboard) {
            const registrationLink =
                `${this.partnerSiteUrl}/agent/register?AgentId=${this.authService.userData$.getValue().Id}`;
            navigator.clipboard.writeText(registrationLink);
        }
    }

    private generateLanguageDropdown(): void {
        this.commonService.langList$
            .pipe(
                filter(r => !!r),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (languages: ILanguage[]) => {
                    let isLangExisted: boolean = false;
                    const storageLang = localStorage.getItem('lang');

                    this.languagesForPanelMenu = languages.map((item => {
                        if (item.Id === storageLang) {
                            isLangExisted = true;
                        }

                        const obj: ILanguageMenuItem = {
                            label: item.Name,
                            value: {
                                id: item.Id,
                                name: item.Name
                            }
                        };
                        return obj;
                    }));
                    this.selectedLang =
                        this.languagesForPanelMenu
                            .find(lang => lang.value.id ===
                                (storageLang && isLangExisted ? storageLang : 'en'))?.value;

                    if (storageLang && !isLangExisted) {
                        this.languageService.setLanguage(this.selectedLang.id);
                    }

                    this.setLanguageItems();
                }
            });
    }

    private setLanguageItems(): void {
        const items = [
            {
                label: this.selectedLang.name,
                icon: 'pi icon-translations icon-size-sm',
                command: (e) => e.originalEvent.stopPropagation(),
                items: this.languagesForPanelMenu,
                styleClass: 'language-section'
            },
        ];

        this.fixupItems(items);
        this.panelMenuItems = [...items];
    }

    public logout(): void {
        this.authService.logout()
            .pipe(take(1))
            .subscribe();
    }
}
