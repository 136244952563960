import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '@env/environment';
import { HttpService, ToastService, UpdateStorageService } from '@core/services/index';
import { IEnum } from '@core/interfaces';
import { MessageStatesEnum, Methods } from '@core/enums';

@Injectable({
    providedIn: 'root'
})

export class MessagingCenterService {
    public messagesGroup$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public notifications$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public messagesStatesEnum$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public messagesTypesEnum$: BehaviorSubject<Partial<IEnum>[]> = new BehaviorSubject<Partial<IEnum>[]>([]);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpService,
        private toastService: ToastService,
        private updateStorageService: UpdateStorageService,
    ) {
        this.subscribeToConfigVersionChanges();
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.loadMessagesStatesEnum();
                this.loadMessagesTypes();
            });
    }

    public loadMessagesStatesEnum(): void {
        if (localStorage.getItem('enum_messages-states')) {
            this.messagesStatesEnum$.next(JSON.parse(localStorage.getItem('enum_messages-states')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_MESSAGES_STATES_ENUM,
                null,
                false,
                null,
                false
            )
                .subscribe((res: IEnum[]) => {
                    res.map(status => {
                        status.Background = status.Value === MessageStatesEnum.Read ? '#79F2B8' : '#EE6464';
                        status.Color = '#3F3F3F';
                    });
                    localStorage.setItem('enum_messages-states', JSON.stringify(res));
                    this.messagesStatesEnum$.next(res);
                }, (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                });
        }
    }

    public loadMessagesTypes(): void {
        if (localStorage.getItem('enum_message-types')) {
            this.messagesTypesEnum$.next(JSON.parse(localStorage.getItem('enum_message-types')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_MESSAGES_TYPES_ENUM,
                null,
                false,
                null,
                false
            )
                .subscribe((res: Partial<IEnum[]>) => {
                    const all = { Value: null, Name: 'All' };
                    res.unshift(all);
                    localStorage.setItem('enum_message-types', JSON.stringify(res));
                    this.messagesTypesEnum$.next(res);
                }, (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                });
        }
    }

    public loadMessageGroups(requestMethod, payload): void {
        this.http.request(
            'post',
            environment.ApiUrl + requestMethod,
            payload
        ).pipe(tap((messagesGroup) => {
            this.messagesGroup$.next(messagesGroup);
        })).subscribe();
    }

    public loadNotifications(requestMethod, payload): void {
        this.http.request(
            'post',
            environment.ApiUrl + requestMethod,
            payload
        ).pipe(tap((notifications) => {
            this.notifications$.next(notifications);
        })).subscribe();
    }

    public createMessage(payload: any, method: string): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + method,
            payload,
        );
    }

    public loadConversationHistoryByMessageId(messageId: number): Observable<any> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PLAYER_MESSAGES_BY_MESSAGE_ID,
            null,
            false,
            { params: { messageId } }
        );
    }

    public replyMessage(payload): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.REPLY_PLAYER_MESSAGE_BY_MESSAGE_ID,
            { ...payload },
            false,
            null,
            false
        );
    }

}
