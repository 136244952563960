import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ICreateComment } from '@core/interfaces';

@Component({
    selector: 'app-comment-action-buttons-component',
    template: `
        <div class="action-buttons">
            <button pButton pRipple type="button" icon="icon-square-cancel"
                    (click)="onCancel()"
                    class="p-button-text"></button>
            <button pButton pRipple type="button" icon="icon-save"
                    (click)="onSave()"
                    class="p-button-text button-save"></button>
        </div>
    `
})
export class CommentActionButtonsRendererComponent implements ICellRendererAngularComp, OnDestroy {
    public id: number;
    public params: ICellRendererParams;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor() {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
        this.params = params;
        this.params.data.editMode = true;
        this.params.node.setExpanded(true);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    onCancel(): void {
        this.params.data.Comment = this.params.data.LastComment;
        this.params.api.refreshCells();
    }

    onSave(): void {
        if ((this.params.data.Comment)
            && (this.params.data.Comment !== this.params.data.LastComment)) {
            const comment: ICreateComment = {
                DeviceVisitorId: this.params.data.DeviceVisitorId,
                Comment: this.params.data.Comment,
            };

            this.params.data.save(comment);
        }
    }

    getCellParams(params: ICellRendererParams): void {
        this.id = params.data.Id;
    }
}
