import * as JsEncryptModule from 'jsencrypt';

export function encryptData(data: any): string | false {
    const encryptor = new JsEncryptModule.JSEncrypt();
    const generalConfigs = JSON.parse(localStorage.getItem('generalConfigs'));
    if (generalConfigs) {
        const publicKey = generalConfigs.find(config => config.Key === 'General').Value
            .find(item => item.Key === 'RsaKey').Value;
        encryptor.setPublicKey(publicKey);
    }

    return encryptor.encrypt(JSON.stringify(data));
}
