<div class="p-fluid p-formgrid form">
    <app-dialog-info *ngIf="dialogInfo" [dialogInfo]="dialogInfo"></app-dialog-info>

    <form [formGroup]="moveAgentForm" (ngSubmit)="onMoveAgent()">
        <div class="field col-12">
            <label for="parentId">{{ 'Parent' | translate }} *</label>
            <p-dropdown formControlName="NewParentId"
                        [panelStyleClass]="'dialog-dropdown'"
                        [options]="parents"
                        (onFilter)="onParentFilterChange$.next($event.filter)"
                        (onChange)="parentSelected($event)"
                        [showClear]="true"
                        [placeholder]="'Select' | translate"
                        optionLabel="UserName"
                        [filter]="true"
                        filterBy="UserName"
                        appendTo="body"
                        id="parentId">
                <ng-template let-selectedParent pTemplate="selectedItem">
                    <span>{{ selectedParent.UserName }} ( {{ selectedParent.Role }} )</span>
                </ng-template>
                <ng-template let-parent pTemplate="item">
                    <span>{{ parent.UserName }} ( {{ parent.Role }} )</span>
                </ng-template>
            </p-dropdown>
            <small *ngIf="moveAgentForm.controls.NewParentId.touched
                    && moveAgentForm.controls.NewParentId.hasError('required')" class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="p-dialog-footer">
            <button
                    type="button"
                    pButton
                    pRipple
                    [label]="'Cancel' | translate"
                    (click)="onCancel()"
                    icon="pi pi-times"
                    class="p-button-text p-button-secondary"
            ></button>
            <button
                    type="submit"
                    pButton
                    pRipple
                    [loading]="pending"
                    [label]="'Save' | translate"
                    icon="pi pi-check"
                    class="p-button-text"
            ></button>
        </div>
    </form>
</div>
