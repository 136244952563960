import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { ConfigService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({ selector: '[subMenuPosition]' })
export class SubMenuPositionDirective implements OnInit, OnDestroy {
    private sidebarScrollTop: number;

    private unsubscribe$: Subject<void> = new Subject<void>();

    @HostListener('mouseenter', ['$event'])
    onMouseEnter(): void {
        this.setSubMenuPosition(this.sidebarScrollTop);
    }

    @HostListener('click', ['$event'])
    onClick(): void {
        this.setSubMenuPosition(this.sidebarScrollTop);
    }

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private configService: ConfigService,
    ) {
    }

    ngOnInit(): void {
      this.subscribeToSidebarScrollTopChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private subscribeToSidebarScrollTopChanges(): void {
        this.configService.sidebarScrollTop$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((sidebarScrollTop) => {
                this.sidebarScrollTop = sidebarScrollTop;
                this.setSubMenuPosition(this.sidebarScrollTop);
            });
    }

    private setSubMenuPosition(sidebarScrollTop: number): void {
        const subMenuExists = this.el.nativeElement.children.length && this.el.nativeElement.children[1];

        if (subMenuExists) {
            const subMenu = this.el.nativeElement.children[1];

            if ((this.el.nativeElement.offsetTop - sidebarScrollTop + 200) < window.innerHeight) {
                const menuOffsetTop = this.el.nativeElement.offsetTop - sidebarScrollTop;
                const topLogoHeight = 56;

                this.renderer.setStyle(
                    subMenu,
                    'top',
                    `${menuOffsetTop}px`
                );

                this.renderer.setStyle(
                    subMenu,
                    'bottom',
                    'auto'
                );

                this.renderer.setStyle(
                    subMenu,
                    'opacity',
                    menuOffsetTop < topLogoHeight ? '0' : '1'
                );
            } else {
                const iconHeight = 35;
                const menuOffsetBottom = window.innerHeight - (this.el.nativeElement.offsetTop - sidebarScrollTop)
                    - iconHeight;
                const footerLogoHeight = 34;

                this.renderer.setStyle(
                    subMenu,
                    'bottom',
                    `${menuOffsetBottom}px`
                );

                this.renderer.setStyle(
                    subMenu,
                    'top',
                    'auto'
                );

                this.renderer.setStyle(
                    subMenu,
                    'opacity',
                    menuOffsetBottom < footerLogoHeight ? '0' : '1'
                );

            }
        }
    }
}
