<ng-container *ngIf="!agentPermissionsInfo; else agentPermissionDetailBlock">
    <div class="spinner-container">
        <i class="pi pi-spin pi-spinner ml-2"></i>
    </div>
</ng-container>
<ng-template #agentPermissionDetailBlock>
    <div class="inner-page-main-section">
        <div class="section-title-name mx-3">{{ 'PermissionList' | translate | uppercase }}</div>

        <div class="permissions-grid-container form">
            <ng-container *ngIf="isInProfilePage; else partialPermissionsBlock">
                <ng-container *ngFor="let permissionName of agentPermissions">
                    <div class="field-section" *ngIf="agentPermissionsInfo.Permissions[permissionName]">
                        <div class="field">
                            <label class="field-name">{{ permissionName | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo.Permissions[permissionName]"
                            ></app-boolean-transform>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #partialPermissionsBlock>
                <ng-container *ngFor="let permissionName of agentPermissions">
                    <div class="field-section">
                        <div class="field">
                            <label class="field-name">{{ permissionName | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo.Permissions[permissionName]"
                            ></app-boolean-transform>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
    </div>



    <ng-container *ngIf="isAdmin">
        <div class="inner-page-main-section">
            <div class="p-fluid p-formgrid grid form">
                <div class="col-12 md:col-6 field-section">
                    <div>
                        <div class="field ">
                            <label class="field-name">{{ 'CreateAgent' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.AgentAdd"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'BlockAgent' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.AgentBlock"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">
                                {{ 'ChangeAgentPassword' | translate }}
                            </label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.AgentChangePassword"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'DepositToAgent' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.DepositToAgent"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'WithdrawalFromAgent' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.WithdrawalFromAgent"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'AgentIndirectTransfer' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.AgentIndirectTransfer"
                            ></app-boolean-transform>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 field-section">
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'CreatePlayer' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.PlayerAdd"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'BlockPlayer' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.PlayerBlock"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">
                                {{ 'ChangePlayerPassword' | translate }}
                            </label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.PlayerChangePassword"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">
                                {{ 'DepositToPlayer' | translate }}
                            </label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.DepositToPlayer"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">
                                {{ 'WithdrawalFromPlayer' | translate }}
                            </label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.WithdrawalFromPlayer"
                            ></app-boolean-transform>
                        </div>
                    </div>
                    <div>
                        <div class="field editable-field">
                            <label class="field-name">{{ 'PlayerIndirectTransfer' | translate }}</label>
                            <app-boolean-transform
                                [boolean]="agentPermissionsInfo?.PlayerIndirectTransfer"
                            ></app-boolean-transform>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</ng-template>
