import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-created-updated-component',
    template: `
        <div class="relative">
            <span class="absolute username">{{ name }}</span>
            <span class="absolute time">{{ time | date: 'dd.MM.YYYY HH:mm:ss' }}</span>
        </div>
    `
})
export class CreatedUpdatedRendererComponent implements ICellRendererAngularComp {
    public name: string;
    public time: string;

    constructor() {}

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        if (params.colDef.field === 'CreatorFirstName') {
            this.name = `${params.data.CreatorFirstName} ${params.data.CreatorLastName}`;
            this.time = params.data.CreationTime;
        } else {
            this.name = `${params.data.UpdaterFirstName} ${params.data.UpdaterLastName}`;
            this.time = params.data.LastUpdateTime;
        }
    }
}
