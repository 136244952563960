import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService, UpdateStorageService } from '@core/services';
import { environment } from '@env/environment';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private updateStorageService: UpdateStorageService,
    ) {

    }

    private static modifyRequest(
        req: HttpRequest<any>,
        Token?: string | null,
    ): HttpRequest<any> {
        if (!req.url.includes('.json') && !(req.body instanceof FormData)) {

            // changed table filter model to match backend models
            const tableFilters = req?.body?.filterModel;
            if (tableFilters && Object.keys(tableFilters).length !== 0) {
                Object.keys(tableFilters).forEach((key: string) => {
                    if (tableFilters[key].filterType !== 'set') {
                        if (!tableFilters[key].operator) {
                            // one filter case
                            tableFilters[key] = {
                                filterType: tableFilters[key].filterType,
                                operator: 'AND',
                                conditions: [
                                    { ...tableFilters[key] }
                                ]
                            };
                        } else {
                            if (!tableFilters[key].conditions) {
                                tableFilters[key] = { ...tableFilters[key] };
                                tableFilters[key].conditions = [tableFilters[key].condition1, tableFilters[key].condition2];
                                delete tableFilters[key].condition1;
                                delete tableFilters[key].condition2;
                            }
                        }
                    }

                    tableFilters[key]?.conditions?.forEach(cond => {
                        if (cond.dateFrom) {
                            cond.filter = new Date(cond.dateFrom);
                            delete cond.dateFrom;
                            delete cond.dateTo;
                        } else {
                            if (typeof cond.filter === 'number') {
                                cond.filter += '';
                            }
                        }
                    });
                });
            }

        }

        if (!req.url.includes('.json') && Token) {
            req = req.clone({
                setHeaders: { Token }
            });
        }

        return req;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = HttpInterceptor.modifyRequest(
            req,
            this.authService.tokenGetter() ? this.authService.tokenGetter() : null,
        );
        return next.handle(req)
            .pipe(
                tap(event => {
                    if (event.type === HttpEventType.Response) {
                        if (!event.url?.includes('.json')) {
                            let key: string;

                            if (environment.role === 'agent') {
                                key = 'agent-underconstraction';
                            } else if (environment.role === 'admin') {
                                key = 'agent-mgmt-underconstraction';
                            }

                            const maintenance = event.headers.get(key);
                            this.navigateMaintenance(maintenance);


                            // const currentConfigVersion: string = event.headers.get('agent-conf-v');
                            // const configVersionLocal: string = localStorage.getItem('configVersion');
                            // if (configVersionLocal) {
                            //     if (configVersionLocal !== currentConfigVersion) {
                            //         const generalConfigs = localStorage.getItem('generalConfigs');
                            //         const selectedTheme = localStorage.getItem('selectedTheme');
                            //         const lang = localStorage.getItem('lang');
                            //
                            //         localStorage.clear();
                            //
                            //         this.updateStorageService.configVersionHasChanged$.next(true);
                            //
                            //         localStorage.setItem('generalConfigs', generalConfigs);
                            //         localStorage.setItem('selectedTheme', selectedTheme);
                            //         localStorage.setItem('lang', lang);
                            //         localStorage.setItem('configVersion', currentConfigVersion);
                            //     }
                            // } else {
                            //     localStorage.setItem('configVersion', currentConfigVersion);
                            // }
                        }
                    }
                }),
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        this.authService.unauthenticated();
                    }
                    if (err instanceof HttpErrorResponse && err.status === 0) {
                        console.log('Check Your Internet Connection And Try again Later');
                    }
                    return throwError(err);
                })
            );

    }

    private navigateMaintenance(maintenance): void {
        if (maintenance) {
            this.router.navigate(['/under-maintenance']);
        } else if (this.router.url === '/under-maintenance') {
            this.router.navigate(['/']);
        }
    }

}
