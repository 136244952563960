<div class="agent-info">
    <div [ngClass]="isDesktopView? 'main-actions' : 'm-main-actions'" *ngIf="!isEditMode, else editModeSection">
        <div class="title" *ngIf="isDesktopView">{{'MainActions' | translate}}:</div>
        <div [ngClass]="isDesktopView ? 'action-buttons' : 'm-action-buttons'">
            <div *appCheckPermissions="permissionsService.permissions?.AdminUserPermissions?.ChangeAdminUserPassword"
                 class="action-block">
                <button type="button" pButton pRipple (click)="openChangePasswordDialog()" class="p-button-text">
                    <span class="action-text" *ngIf="isDesktopView">{{'ChangePassword' | translate}}</span>
                    <i class="pi icon-password icon-size-sm"></i>
                </button>
            </div>
            <ng-container *appCheckPermissions="permissionsService.permissions?.AdminUserPermissions?.EditAdminUser">
                <div class="vertical-divider"></div>
                <div class="action-block">
                    <button class="p-button-text" type="button" pButton pRipple (click)="enableEditMode()">
                        <span class="action-text" *ngIf="isDesktopView">{{'Edit' | translate}}</span>
                        <i class="pi icon-edit icon-size-sm"></i>
                    </button>
                </div>
            </ng-container>

        </div>
    </div>
    <ng-template #editModeSection>
        <div [ngClass]="{
                         'main-actions' : isDesktopView,
                         'm-main-actions' : !isDesktopView,
                         'm-action-edit-buttons' : !isDesktopView && isEditMode}">
            <div class="title"><span>{{'SaveYourChanges' | translate}}:</span></div>
            <div [ngClass]="{
                             'action-buttons' : isDesktopView,
                             'm-action-edit-buttons' : !isDesktopView && isEditMode}">
                <div class="action-block">
                    <button type="button"
                            pButton
                            pRipple
                            [label]="'Cancel' | translate"
                            (click)="onCancel()"
                            icon="pi pi-times"
                            class="p-button-text p-button-secondary">
                    </button>
                </div>
                <div class="action-block">
                    <button type="button"
                            pButton
                            pRipple
                            [loading]="pending"
                            [label]="'Save' | translate"
                            [disabled]="adminUserDetailForm.valid && adminUserDetailForm.pristine"
                            (click)="onSaveChanges()"
                            icon="pi pi-check"
                            class="p-button-text">
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="!isEditMode; else editModeBlock">
        <app-admin-user-info
            [adminUserDetail]="adminUserDetail"
            [isDesktopView]="isDesktopView"
        ></app-admin-user-info>
    </ng-container>

    <ng-template #editModeBlock>
        <app-admin-user-edit-info
            [adminUserDetail]="adminUserDetail"
            [adminUserDetailForm]="adminUserDetailForm"
            [isDesktopView]="isDesktopView"
        ></app-admin-user-edit-info>
    </ng-template>
</div>


