<div class="p-fluid p-formgrid form">

    <app-dialog-info *ngIf="dialogInfo" [dialogInfo]="dialogInfo"></app-dialog-info>

    <form [formGroup]="transferForm" (ngSubmit)="onOpenLimit()">
        <!--        <div class="field col-12">-->
        <!--            <label for="AccountType">{{ 'AccountType' | translate }} *</label>-->
        <!--            <p-dropdown [options]="accountTypes"-->
        <!--                        [panelStyleClass]="'dialog-dropdown'"-->
        <!--                        appendTo="body"-->
        <!--                        formControlName="AccountTypeId"-->
        <!--                        optionLabel="Name"-->
        <!--                        optionValue="Value"-->
        <!--                        id="AccountType"-->
        <!--                        [placeholder]="'Select' | translate"-->
        <!--                        [ngClass]="{'ng-touched ng-invalid' : transferForm.controls.AccountTypeId.touched-->
        <!--                                    && transferForm.controls.AccountTypeId.invalid}">-->
        <!--            </p-dropdown>-->
        <!--            <small-->
        <!--                *ngIf="transferForm.controls.AccountTypeId.touched-->
        <!--                               && transferForm.controls.AccountTypeId.hasError('required')"-->
        <!--                class="p-error">-->
        <!--                {{ 'FieldRequiredErrorMessage' | translate }}-->
        <!--            </small>-->
        <!--        </div>-->

        <ng-container *ngIf="showTransactionType">
            <div class="col-12 field flex transaction-type-wrapper">
                <div class="field-radiobutton"
                     (click)="onChangeTransactionType(transactionTypesEnum.Deposit)">
                    <p-radioButton name="TransactionTypeId"
                                   [value]="transactionTypesEnum.Deposit"
                                   formControlName="TransactionTypeId"
                                   id="Deposit"
                    ></p-radioButton>
                    <label for="Deposit">
                        {{ 'Deposit' | translate }}
                    </label>
                </div>
                <div class="field-radiobutton"
                     (click)="onChangeTransactionType(transactionTypesEnum.Withdraw)">
                    <p-radioButton name="TransactionTypeId"
                                   [value]="transactionTypesEnum.Withdraw"
                                   formControlName="TransactionTypeId"
                                   id="Withdrawal"
                    ></p-radioButton>
                    <label for="Withdrawal">
                        {{ 'Withdrawal' | translate }}
                    </label>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showAgentsDropdown && !isAdmin">
            <div class="field col-12">
                <label for="agentId">{{ 'Agent' | translate }} *</label>
                <p-dropdown formControlName="ToAgentId"
                            [panelStyleClass]="'dialog-dropdown'"
                            [options]="agentSearchOptions.agents"
                            (onFilter)="onAgentFilterChange$.next({
                                value: $event.filter,
                                optionKey: 'agents'
                            })"
                            (onChange)="agentSelected($event, 'ToAgentId', 'agent')"
                            [showClear]="true"
                            [placeholder]="'Select' | translate"
                            optionLabel="UserName"
                            [filter]="true"
                            filterBy="UserName"
                            appendTo="body"
                            id="agentId"
                            [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.ToAgentId.touched
                                 && transferForm.controls.ToAgentId.invalid)}">
                    <ng-template let-selectedAgent pTemplate="selectedItem">
                        <span>{{ selectedAgent.UserName }} ( {{ selectedAgent.Role }} )</span>
                    </ng-template>
                    <ng-template let-agent pTemplate="item">
                        <span>{{ agent.UserName }} ( {{ agent.Role }} )</span>
                    </ng-template>
                </p-dropdown>
                <div>
                    <small *ngIf="transferForm.controls.ToAgentId.touched
                       && transferForm.controls.ToAgentId.hasError('required')"
                           class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                    </small>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isAdmin">
            <div class="grid m-0">
                <div class="field col-12"
                     *ngIf="showFromAgentDropdown"
                     [ngClass]="transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Deposit
                        ? 'flex-order-1' : 'flex-order-2'">
                    <label [for]="'fromAgentId'">{{
                        (transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Deposit
                            ? 'FromAgent' : 'ToAgent') | translate }} *</label>
                    <p-dropdown formControlName="FromAgentId"
                                [panelStyleClass]="'dialog-dropdown'"
                                [options]="agentSearchOptions.fromAgents"
                                (onFilter)="onAgentFilterChange$.next({
                                value: $event.filter,
                                optionKey: 'fromAgents'
                            })"
                                (onChange)="agentSelected($event, 'FromAgentId', 'fromAgent')"
                                [showClear]="true"
                                [placeholder]="'Select' | translate"
                                optionLabel="UserName"
                                [filter]="true"
                                filterBy="UserName"
                                appendTo="body"
                                inputId="fromAgentId"
                                [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.FromAgentId.touched
                                 && transferForm.controls.FromAgentId.invalid)}">
                        <ng-template let-selectedAgent pTemplate="selectedItem">
                            <span>{{ selectedAgent.UserName }} ( {{ selectedAgent.Role }} )</span>
                        </ng-template>
                        <ng-template let-agent pTemplate="item">
                            <span>{{ agent.UserName }} ( {{ agent.Role }} )</span>
                        </ng-template>
                    </p-dropdown>
                    <div>
                        <small *ngIf="transferForm.controls.FromAgentId.touched
                       && transferForm.controls.FromAgentId.hasError('required')"
                               class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                        </small>
                    </div>
                    <div class="select-amount-absolute-wrapper">
                        <div class="select-amount-text"
                             *ngIf="
                            transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Deposit &&
                            selectedAgents['fromAgent']
                         "
                             (click)="
                            transferForm.markAsDirty();
                            transferForm.controls.Amount.setValue(selectedAgents['fromAgent']?.RealBalance)
                         ">
                            {{'Balance' | translate}}:
                            <span class="select-amount-value">{{ selectedAgents['fromAgent']?.Currency }}
                                {{ selectedAgents['fromAgent']?.RealBalance | number : '1.2-2' }}
                        </span>
                        </div>
                    </div>
                </div>

                <div class="field col-12"
                     *ngIf="showAgentsDropdown"
                     [ngClass]="transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw
                        ? 'flex-order-1' : 'flex-order-2'">
                    <label [for]="'toAgentId'">{{
                        (transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw
                            ? 'FromAgent' : 'ToAgent') | translate }} *</label>
                    <p-dropdown formControlName="ToAgentId"
                                [panelStyleClass]="'dialog-dropdown'"
                                [options]="agentSearchOptions.toAgents"
                                (onFilter)="onAgentFilterChange$.next({
                                value: $event.filter,
                                optionKey: 'toAgents'
                            })"
                                (onChange)="agentSelected($event, 'ToAgentId', 'toAgent')"
                                [showClear]="true"
                                [placeholder]="'Select' | translate"
                                optionLabel="UserName"
                                [filter]="true"
                                filterBy="UserName"
                                appendTo="body"
                                inputId="toAgentId"
                                [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.ToAgentId.touched
                                 && transferForm.controls.ToAgentId.invalid)}">
                        <ng-template let-selectedAgent pTemplate="selectedItem">
                            <span>{{ selectedAgent.UserName }} ( {{ selectedAgent.Role }} )</span>
                        </ng-template>
                        <ng-template let-agent pTemplate="item">
                            <span>{{ agent.UserName }} ( {{ agent.Role }} )</span>
                        </ng-template>
                    </p-dropdown>
                    <div>
                        <small *ngIf="transferForm.controls.ToAgentId.touched
                       && transferForm.controls.ToAgentId.hasError('required')"
                               class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                        </small>
                    </div>
                    <div class="select-amount-absolute-wrapper">
                        <div class="select-amount-text"
                             *ngIf="
                                transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw &&
                                selectedAgents['toAgent']
                             "
                             (click)="
                                transferForm.markAsDirty();
                                transferForm.controls.Amount.setValue(selectedAgents['toAgent']?.RealBalance)
                             ">
                            {{'Balance' | translate}}:
                            <span class="select-amount-value">{{ selectedAgents['toAgent']?.Currency }}
                                {{ selectedAgents['toAgent']?.RealBalance | number : '1.2-2' }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="field col-12"
                     *ngIf="showPlayersDropdown"
                     [ngClass]="transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw
                        ? 'flex-order-1' : 'flex-order-2'">
                    <label [for]="'toPlayerId'">{{
                        (transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw
                            ? 'FromPlayer' : 'ToPlayer') | translate }} *</label>
                    <p-dropdown formControlName="ToPlayerId"
                                [panelStyleClass]="'dialog-dropdown'"
                                [options]="players"
                                (onFilter)="onPlayerFilterChange$.next($event.filter)"
                                (onChange)="playerSelected($event)"
                                [showClear]="true"
                                [placeholder]="'Select' | translate"
                                optionLabel="UserName"
                                [filter]="true"
                                filterBy="UserName"
                                appendTo="body"
                                inputId="toPlayerId"
                                [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.ToPlayerId.touched
                                 && transferForm.controls.ToPlayerId.invalid)}">
                    </p-dropdown>
                    <div>
                        <small *ngIf="transferForm.controls.ToPlayerId.touched
                       && transferForm.controls.ToPlayerId.hasError('required')"
                               class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                        </small>
                    </div>
                    <div class="select-amount-absolute-wrapper">
                        <div class="select-amount-text"
                             *ngIf="
                            transferForm.controls.TransactionTypeId.value === transactionTypesEnum.Withdraw &&
                            selectedPlayer
                         "
                             (click)="
                            transferForm.markAsDirty();
                            transferForm.controls.Amount.setValue(selectedPlayer?.Balance)
                         ">
                            {{'Balance' | translate}}:
                            <span class="select-amount-value">{{ selectedPlayer?.Currency }}
                                {{ selectedPlayer?.Balance | number : '1.2-2' }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="field col-12">
            <div class="flex align-items-center justify-content-between mb-2">
                <label for="Amount">{{ 'Amount' | translate }} *</label>
                <div class="select-amount-text"
                     *ngIf="!isAdmin && !showAgentsDropdown"
                     (click)="transferForm.markAsDirty(); transferForm.controls.Amount.setValue(
                             (config.data?.TransactionTypeId === transactionTypesEnum.Withdraw ||
                              config.data?.TransactionTypeId === transactionTypesEnum.Payout)
                              ? config.data.Balance : userBalance
                            )">
                    {{'SelectAllAmount' | translate}}:
                    <span class="select-amount-value">{{ config.data.paramsData.Currency }}
                        {{ (config.data?.TransactionTypeId === transactionTypesEnum.Withdraw ||
                            config.data?.TransactionTypeId === transactionTypesEnum.Payout)
                            ? (config.data.Balance | number : '1.2-2') : (userBalance | number : '1.2-2') }}
                    </span>
                </div>
                <div class="select-amount-text"
                     *ngIf="!isAdmin && showAgentsDropdown"
                     (click)="transferForm.markAsDirty(); transferForm.controls.Amount.setValue(
                        config.data?.TransactionTypeId === transactionTypesEnum.Withdraw
                              ? selectedAgents['agent']?.RealBalance
                              : userBalance
                            )">
                    {{'SelectAllAmount' | translate}}:
                    <span class="select-amount-value">{{ config.data.paramsData.Currency }}
                        {{ config.data?.TransactionTypeId === transactionTypesEnum.Withdraw
                            ? (selectedAgents['agent']?.RealBalance | number : '1.2-2')
                            : (userBalance | number : '1.2-2') }}
                    </span>
                </div>
            </div>
            <span class="p-input-icon-right">
                <i *ngIf="transferForm.controls.Amount.value"
                   (click)="transferForm.controls.Amount.setValue(null)"
                   class="pi pi-times cursor-pointer"></i>
                <input pInputText
                       id="Amount"
                       type="text"
                       formControlName="Amount"
                       mask="separator"
                       thousandSeparator=","
                       [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.Amount.touched
                                 && transferForm.controls.Amount.invalid)}"/>
            </span>
            <div>
                <small *ngIf="transferForm.controls.Amount.touched
                       && transferForm.controls.Amount.hasError('required')"
                       class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                </small>
                <small *ngIf="transferForm.controls.Amount.touched
                       && transferForm.controls.Amount.hasError('maxlength')"
                       class="p-error">
                    {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                </small>
                <small *ngIf="transferForm.controls.Amount.hasError('min')"
                       class="p-error">{{ 'InvalidAmount' | translate }}
                </small>
            </div>
        </div>

        <div class="field col-12">
            <div class="flex justify-content-between calculator-wrapper">
                <div class="flex-1 calculator-item" *ngFor="let item of calculator | keyvalue">
                    <button *ngFor="let count of item.value"
                            pButton pRipple [label]="count | number"
                            icon="pi pi-plus" class="p-button-primary add-btn" type="button"
                            (click)="calculateAmount(count)">
                    </button>
                </div>
            </div>
        </div>

        <div class="field col-12">
            <label for="comment">{{ 'Comment' | translate }}</label>
            <textarea id="comment"
                      rows="3"
                      formControlName="Comment"
                      pInputTextarea
                      [ngClass]="{'ng-touched ng-invalid' : (transferForm.controls.Comment.touched
                && transferForm.controls.Comment.invalid)}"
            ></textarea>
            <small *ngIf="transferForm.controls.Comment.touched
                       && transferForm.controls.Comment.hasError('maxlength')"
                   class="p-error">{{ 'MaxLengthValidationMessage' | translate: {length: 250} }}</small>
        </div>

        <div class="p-dialog-footer">
            <button
                type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary"
            ></button>
            <button
                type="submit"
                pButton
                pRipple
                [label]="'Save' | translate"
                [disabled]="transferForm.valid && transferForm.pristine"
                icon="pi pi-check"
                class="p-button-text"
            ></button>
        </div>
    </form>

</div>
