<div>
    <ng-container *appCheckPermissions="permissionsService.permissions?.AgentPermissions?.UpdateAgentPermission">
        <div [ngClass]="isDesktopView? 'main-actions' : 'm-main-actions'"
             *ngIf="!isEditMode, else editModeSection">
            <div class="title" *ngIf="isDesktopView">{{'MainActions' | translate}}:</div>
            <div [ngClass]="isDesktopView ? 'action-buttons' : 'm-action-buttons'">
                <div class="action-block">
                    <button type="button" pButton pRipple (click)="enableEditMode()" class="p-button-text">
                        <span class="action-text" *ngIf="isDesktopView">{{'Edit' | translate}}</span>
                        <i class="pi icon-edit icon-size-sm"></i>
                    </button>
                </div>
            </div>
        </div>
        <ng-template #editModeSection>
            <div [ngClass]="{
                         'main-actions' : isDesktopView,
                         'm-main-actions' : !isDesktopView,
                         'm-action-edit-buttons' : !isDesktopView && isEditMode}">
                <div class="title"><span>{{'SaveYourChanges' | translate}}:</span></div>
                <div [ngClass]="{
                             'action-buttons' : isDesktopView,
                             'm-action-edit-buttons' : !isDesktopView && isEditMode}">
                    <div class="action-block">
                        <button type="button"
                                pButton
                                pRipple
                                [label]="'Cancel' | translate"
                                (click)="onCancel()"
                                icon="pi pi-times"
                                class="p-button-text p-button-secondary">
                        </button>
                    </div>
                    <div class="action-block">
                        <button type="button"
                                pButton
                                pRipple
                                [loading]="pending"
                                [label]="'Save' | translate"
                                (click)="onSaveChanges()"
                                icon="pi pi-check"
                                class="p-button-text">
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="!isEditMode; else editModeBlock">
        <app-permission-info [agentPermissions]="agentPermissions"
                             [agentPermissionsInfo]="agentPermissionsInfo"
                             [isDesktopView]="isDesktopView"
        ></app-permission-info>
    </ng-container>

    <ng-template #editModeBlock>
        <app-permission-edit-info [agentPermissions]="agentPermissions"
                                  [agentPermissionsInfo]="agentPermissionsInfo"
                                  [permissionManagementForm]="permissionManagementForm"
                                  [isDesktopView]="isDesktopView"
        ></app-permission-edit-info>
    </ng-template>
</div>
