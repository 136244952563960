import { Component, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import {
    ColDef,
    IServerSideDatasource,
    ServerSideStoreType
} from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { TranslateService } from '@ngx-translate/core';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';

import {
    AmountRendererComponent,
    DateCellRenderer,
    StateCellRendererComponent
} from '@app/shared';
import { dataFormatterByValue, generateColumnDefs } from '@core/utilities';
import { IBalanceTransfer, IFilter, IResponseWithCount } from '@core/interfaces';
import {
    AgentsService,
    CommonService,
    LanguageService,
    AuthService,
    ToastService
} from '@core/services';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {

    public tableName: string;
    public columnDefs: Array<ColDef> = [
        {
            field: 'Id',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'ID'),
            headerTooltip: this.translateService.instant('ID'),
            filter: 'agNumberColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'FromAgent',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'From'),
            headerTooltip: this.translateService.instant('From'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 120,
        },
        {
            field: 'ToAgent',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'To'),
            headerTooltip: this.translateService.instant('To'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 120,
        },
        {
            field: 'Date',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'CreationDate'),
            headerTooltip: this.translateService.instant('CreationDate'),
            cellRenderer: DateCellRenderer,
            headerClass: 'center-aligned',
            cellClass: 'text-center',
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'TransactionTypeId',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Type'),
            headerTooltip: this.translateService.instant('Type'),
            cellRenderer: StateCellRendererComponent,
            cellRendererParams: {
                data: {
                    statesSubject: this.commonService.transactionTypes$,
                    translationPrefix: 'EnumTransactionTypes'
                }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: params => {
                    this.commonService.transactionTypes$
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(data => {
                            params.success(data.map(d => d.Value));
                        });
                },
                valueFormatter: dataFormatterByValue.bind(this, this.commonService.transactionTypes$),
            },
            sortable: true,
            minWidth: 120,
        },
        {
            field: 'AccountTypeId',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'AccountType'),
            headerTooltip: this.translateService.instant('AccountType'),
            valueFormatter: this.translateEnum.bind(this),
            filter: 'agSetColumnFilter',
            filterParams: {
                values: params => {
                    this.commonService.accountTypes$
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(data => {
                            params.success(data.map(d => d.Value));
                        });
                },
                valueFormatter: dataFormatterByValue.bind(this, this.commonService.accountTypes$),
            },
            sortable: true,
            minWidth: 120,
        },
        {
            field: 'Amount',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Amount'),
            headerTooltip: this.translateService.instant('Amount'),
            sortable: true,
            type: 'rightAligned',
            cellRenderer: AmountRendererComponent,
            filter: 'agNumberColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            minWidth: 80,
        },
    ];
    public pagination: boolean = true;
    public paginationPageSize: number = 50;
    public cacheBlockSize: number = 50;
    private filterObj: IFilter;
    public params: IServerSideGetRowsParams;
    public serverSideStoreType: ServerSideStoreType = 'partial';
    public rowModelType: string = 'serverSide';
    public pinnedBottomRowData: any = [{ isTotal: true }];

    private agentId: number;
    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            this.params = params;
            this.getBalanceTransfers(this.filterObj, params);
        }
    };
    public transfers: IBalanceTransfer[];

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
        private commonService: CommonService,
        private agentsService: AgentsService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private toastService: ToastService,
    ) {
    }

    ngOnInit(): void {
        this.setTableName();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setTableName(): void {
        if (+this.route.parent.snapshot.params.id) {
            this.tableName = 'agent-balance-transfers';
            this.agentId = +this.route.parent.snapshot.params.id;
        } else {
            this.tableName = 'my-profile-balance-transfers';
        }
        this.columnDefs = generateColumnDefs(this.tableName, this.columnDefs);
    }

    public getSelectedFilters(filterFields: IFilter): void {
        this.filterObj = filterFields;
        this.params?.api.paginationGoToPage(0);
        this.params?.api.onFilterChanged();
    }

    private getBalanceTransfers(filterObj: IFilter, params: IServerSideGetRowsParams): void {
        const payload: any = {
            filterModel: params.request.filterModel,
            sortModel: params.request.sortModel,
            TakeCount: this.paginationPageSize,
            SkipCount: params.request.endRow / this.paginationPageSize - 1,
            AgentId: this.agentId ? this.agentId : this.authService.userData$.getValue().Id,
            ...filterObj
        };
        this.agentsService.getBalanceTransfers(payload)
            .pipe(take(1))
            .subscribe((res: IResponseWithCount<IBalanceTransfer[]>) => {
                params.success({
                    rowData: res.Entities,
                    rowCount: res.Count
                });
                this.pinnedBottomRowData[0].Amount = res?.Total?.Amount;
                params.columnApi.autoSizeAllColumns(true);
                params.api.sizeColumnsToFit();
                res.Count ? params.api.hideOverlay() : params.api.showNoRowsOverlay();
            }, err => {
                params.api.showNoRowsOverlay();
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private translateEnum(params: ValueFormatterParams): string | void {
        if (!params.data.isTotal) {
            const translatedEnum = `EnumAccountTypes${dataFormatterByValue(this.commonService.accountTypes$, params)}`;
            return this.languageService.localizeTableHeader.call(this, translatedEnum);
        }
    }

}
