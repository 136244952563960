import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    CanActivateFn,
    CanActivateChildFn
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@core/services';

const authGuardHandler = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean> | boolean => {
    const router = inject(Router);
    const authService = inject(AuthService);

    if (state.url && state.url !== '/login' && !authService.isAuthenticated()) {
        authService.unauthenticated();
        router.navigate(['/login']);
        return false;
    }
    return true;
};

export const canActivateAuthGuard: CanActivateFn = authGuardHandler;
export const canActivateChildAuthGuard: CanActivateChildFn = authGuardHandler;
