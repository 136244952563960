import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ColDef, IServerSideDatasource, ServerSideStoreType } from 'ag-grid-community';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
    CreatedUpdatedRendererComponent,
    NoteActionButtonsRendererComponent,
    NoteDetailRendererComponent,
} from '@app/shared';
import { NotesService, ToastService } from '@core/services';
import { StatesEnum } from '@core/enums';
import { INote, INoteHistory, IResponseData } from '@core/interfaces';

@Component({
    selector: 'app-view-notes',
    templateUrl: './view-notes.component.html',
    styleUrls: ['./view-notes.component.scss']
})
export class ViewNotesComponent implements OnDestroy {
    public columnDefs: ColDef[] = [
        {
            headerName: this.translateService.instant('ID').toUpperCase(),
            field: 'Id',
            cellRenderer: 'agGroupCellRenderer',
            minWidth: 100,
            suppressMenu: true,
        },
        {
            headerName: this.translateService.instant('CreatedBy'),
            field: 'CreatorFirstName',
            cellRenderer: CreatedUpdatedRendererComponent,
            minWidth: 180,
            suppressMenu: true
        },
        {
            headerName: this.translateService.instant('Status'),
            field: 'State',
            cellRenderer: params => {
                return '<span>' + this.statesEnum[params.data.State] + '</span>';
            },
            minWidth: 120,
            suppressMenu: true
        },
        {
            headerName: this.translateService.instant('UpdatedBy'),
            field: 'UpdaterFirstName',
            cellRenderer: CreatedUpdatedRendererComponent,
            minWidth: 180,
            suppressMenu: true
        },
        {
            headerName: this.translateService.instant('Text'),
            field: 'Message',
            cellRenderer: params => {
                return '<span title="' + params.data.Message + '">' + params.data.Message + '</span>';
            },
            minWidth: 180,
            suppressMenu: true
        },
        {
            cellRendererSelector: params => {
                return {
                    component: NoteActionButtonsRendererComponent,
                    params: {
                        data: {
                            getHistoryById: (id) => {
                                this.getNoteHistory(id);
                            },
                            close: (note) => {
                                this.onCancel(note);
                            }
                        }
                    }
                };
            },
            minWidth: 100,
            sortable: false,
            suppressMenu: true
        }
    ];
    public defaultColDef: ColDef = {
        flex: 1,
        floatingFilter: false,
        resizable: true,
    };
    public cacheBlockSize: number = 50;
    public serverSideStoreType: ServerSideStoreType = 'partial';
    public rowModelType: string = 'serverSide';
    public headerHeight: number = 34;
    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            const queryParams = {
                filterModel: params.request.filterModel,
                ObjectId: this.config.data.objectId,
                ObjectTypeId: this.config.data.objectTypeId,
                Type: 1
            };

            if (this.config.data.note) {
                params.success(
                    {
                        rowData: this.config.data.note,
                        rowCount: this.config.data.note.length,
                    }
                );
                this.params = params;
            } else {
                this.notesService.getNotes(queryParams)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(response => {
                        params.success(
                            {
                                rowData: response.ResponseObject,
                                rowCount: response.ResponseObject?.length,
                            }
                        );
                        this.params = params;
                    });
            }
        },
    };
    public detailCellRenderer = NoteDetailRendererComponent;
    public params: IServerSideGetRowsParams;

    public addNoteForm: UntypedFormGroup = new UntypedFormGroup({
        Message: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)])
    });

    public addNoteMode: boolean = false;
    public statesEnum = StatesEnum;
    public noteHistory: INoteHistory[] = [];
    public historyMode = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    public loading: boolean = false;

    @ViewChild('addNoteRef') addNoteRef: ElementRef;

    constructor(
        private notesService: NotesService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getRowHeight = (params) => {
        const isDetailRow = params.node.detail;

        // for all rows that are not detail rows, return nothing
        if (!isDetailRow) {
            return undefined;
        }
        // otherwise return height based on number of rows in detail grid
        return 70;
    }

    onCancel(note?: INote): void {
        this.ref.close(note);
    }

    onAddNote(): void {
        this.addNoteMode = true;

        setTimeout(() => {
            this.addNoteRef.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        });
    }

    onSaveNote(): void {
        if (this.addNoteForm.valid) {
            const note: INote = {
                Id: 0,
                ObjectId: this.config.data.objectId,
                ObjectTypeId: this.config.data.objectTypeId,
                Message: this.addNoteForm.get('Message').value,
                State: StatesEnum.Active,
                Type: 1
            };

            this.notesService.saveNote(note)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: IResponseData<INoteHistory[]>) => {
                    if (res?.ResponseCode === 0) {
                        this.toastService.showToastMsg('success', 'AddingNoteSuccessMessage');
                        this.addNoteMode = false;
                        this.params.api.refreshServerSideStore();
                        this.addNoteForm.reset();
                    } else {
                        this.toastService.showToastMsg('error', res.Description);
                    }
                });
        } else {
            this.addNoteForm.get('Message').markAsTouched();
        }
    }

    getNoteHistory(id: number): void {
        this.loading = true;
        this.historyMode = true;

        this.notesService.getNoteHistory(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: IResponseData<INoteHistory[]>) => {
                if (res?.ResponseCode === 0) {
                    this.noteHistory = res.ResponseObject;
                    this.loading = false;
                }
            });
    }

    goBack(): void {
        this.historyMode = false;
    }

    subscribeToRouterEvents(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                takeUntil(this.unsubscribe$)
            ).subscribe(() => {
            this.ref.close();
        });
    }
}
