import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { UpdateStorageService, HttpService, ToastService } from '@core/services';
import { environment } from '@env/environment';
import { MemberStatesEnum, Methods } from '@core/enums';
import {
    IChangePassword,
    IEnum,
    IMovePlayer,
    IPlayer,
    IPlayerBalanceTransfer,
    IPlayerByUserName,
    IResponseWithCount,
    ITransfer,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})

export class PlayersService {

    public playerStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public playerDetail$: BehaviorSubject<IPlayer> = new BehaviorSubject<IPlayer>(null);
    public requestStatesEnum$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpService,
        private router: Router,
        private toastService: ToastService,
        private updateStorageService: UpdateStorageService,
    ) {
        this.subscribeToConfigVersionChanges();
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.loadPlayerStates();
                    this.loadRequestStatesEnum();
                }
            });
    }

    public loadPlayerStates(): void {
        if (localStorage.getItem('enum_player-states')) {
            this.playerStates$.next(JSON.parse(localStorage.getItem('enum_player-states')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.PLAYER_GET_STATUSES)
                .subscribe({
                    next: (res: IEnum[]) => {
                        res.map((status: IEnum) => {
                            status.Background = status.Value === MemberStatesEnum.Active ? '#79F2B8' : '#E3E3E8';
                            status.Color = status.Value === MemberStatesEnum.Active ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_player-states', JSON.stringify(res));
                        this.playerStates$.next(res);
                    }
                });
        }
    }

    public loadRequestStatesEnum(): void {
        if (localStorage.getItem('enum_requests-states')) {
            this.requestStatesEnum$.next(JSON.parse(localStorage.getItem('enum_requests-states')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_REQUEST_STATES_ENUM,
                null,
                false,
                null,
                false
            )
                .subscribe({
                    next: (res: IEnum[]) => {
                        res.map(status => {
                            status.Background = status.Name === 'Approved' || status.Name === 'Read' ? '#79F2B8' :
                                status.Name === 'Rejected' ? '#FFD6DA' : '#F2F2F2';
                            status.Color = '#3F3F3F';
                        });
                        localStorage.setItem('enum_requests-states', JSON.stringify(res));
                        this.requestStatesEnum$.next(res);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.toastService.showToastMsg('error', err?.error?.message);
                    }
                });
        }
    }

    public getPlayers(payload?): Observable<IResponseWithCount<IPlayer>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_ALL,
            payload);
    }

    public addPlayer(player: IPlayer): Observable<IPlayer> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_PLAYER,
            player);
    }

    public editPlayer(player: IPlayer): Observable<IPlayer> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.EDIT_PLAYER,
            player);
    }

    public changePassword(changePasswordData: IChangePassword): Observable<IPlayer> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.PLAYER_CHANGE_PASSWORD,
            changePasswordData);
    }

    public changeStatus(data: any): Observable<IPlayer> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.PLAYER_CHANGE_STATUS,
            data);
    }

    public transfer(data: ITransfer): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ACCOUNT_PLAYER_TRANSFER,
            data);
    }

    public getPlayerBalanceTransfers(payload: any): Observable<IResponseWithCount<IPlayerBalanceTransfer>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_BALANCE_TRANSFERS,
            payload);
    }

    public registerPlayer(player: IPlayer): Observable<IPlayer> {
        return this.http.request('post', environment.ApiUrl + Methods.REGISTER_PLAYER, player);
    }

    public getPlayerById(playerId: number): void {
        this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PLAYER_BY_ID,
            null,
            false,
            { params: { playerId } })
            .pipe(
                map(player => {
                    this.playerDetail$.next(player);
                }),
                catchError((e: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', e?.error?.message);
                    if (e.status !== 401) {
                        this.router.navigate(['/players']);
                    }
                    return of(e);
                })
            ).subscribe();
    }

    public getTransactionById(payload: any): Observable<IResponseWithCount<IPlayerBalanceTransfer[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_TRANSACTION,
            payload,
            false);
    }

    public generatePlayer(payload: any): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_GENERATED_PLAYER,
            payload);
    }

    public movePlayer(payload: IMovePlayer): Observable<void> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MOVE_PLAYER,
            payload
        );
    }

    public getPlayerByUserName(payload: any): Observable<IPlayerByUserName[]> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PLAYER_BY_USER_NAME,
            payload,
            false,
            null,
            false
        );
    }

    public moneyTransfer(payload: any): Observable<void> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.PLAYER_MONEY_TRANSFER,
            payload
        );
    }

}
