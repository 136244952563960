import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private translateService: TranslateService,
        private service: MessageService,
    ) {
    }

    public showToastMsg(severity: string, detail: string, key?: string): void {
        let message: string = detail;

        if (detail) {
            message = this.translateService.instant(detail, {key});
        } else if (severity === 'error') {
            message = detail ? this.translateService.instant(detail) : this.translateService.instant('SomethingWentWrong');
        } else {
            return;
        }

        this.service.add({
            key: 'custom-toast', severity, detail: message
        });
    }
}
