import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
    CanActivateFn,
    CanMatchFn
} from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

const checkUserType = (route): boolean | UrlTree => {
    const router = inject(Router);
    const types: string[] = route.data.userTypes as Array<string>;

    if (types.indexOf(environment.role) === -1) {
        router.navigate(['/']);
        return false;
    }
    return true;
};

const canMatchHandler = (
    route: Route,
    segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return checkUserType(route);
};

const canActivateHandler = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return checkUserType(route);
};

export const canMatchUserTypeGuard: CanMatchFn = canMatchHandler;
export const canActivateUserTypeGuard: CanActivateFn = canActivateHandler;
