import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AuthService, ToastService } from '@core/services';
import { Methods } from '@core/enums';
import { environment } from '@env/environment';

@Component({
    selector: 'app-tinymce',
    template: `
        <editor
                (onInit)="onEditorInit()"
                [formControl]="editorFormControl"
                [init]="init"
                [ngClass]="{
            'ng-touched ng-invalid' : editorFormControl.touched && editorFormControl.hasError('required'),
            'hidden': !editorLoaded,
            }">
        </editor>
    `,
})
export class TinymceComponent implements OnInit {

    @Input() partnerId: number;
    @Input() editorFormControl: FormControl<string>;
    @Input() placeholder: string;
    @Input() minHeight: number;
    public init: object = {};
    private wrap: Element;
    private skeleton: HTMLDivElement;
    public editorLoaded: boolean = false;

    constructor(
        private authService: AuthService,
        private toastService: ToastService,
    ) {
    }

    ngOnInit(): void {
        this.wrap = document.querySelector('app-tinymce');
        this.createSkeletonBox();
        this.init = this.initEditor();
    }

    private initEditor(): object {
        return {
            selector: 'textarea#open-source-plugins',
            plugins: '' +
                'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars ' +
                'fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime ' +
                'advlist lists wordcount help charmap quickbars emoticons',
            menubar: 'file edit view insert format tools table help',
            toolbar: '' +
                'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter ' +
                'alignright alignjustify | outdent indent | ltr rtl | numlist bullist table | forecolor backcolor removeformat ' +
                '| charmap emoticons | insertfile image media template link codesample pagebreak',
            // | fullscreen  preview save print anchor
            placeholder: this.placeholder,
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,
            image_uploadtab: true,
            images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {

                const fileReader = new FileReader();
                fileReader.readAsDataURL(blobInfo.blob());

                fileReader.onload = () => {
                    resolve(fileReader.result);
                };

                fileReader.onerror = () => {
                    reject(fileReader.error);
                };

                fileReader.onprogress = (e) => {
                    if (e.lengthComputable) {
                        progress(e.loaded / e.total * 100);
                    }
                };

                // const xhr = new XMLHttpRequest();
                // xhr.open(
                //     'POST',
                //     environment.CMSApiUrl + Methods.FILE_UPLOAD
                // );
                //
                // xhr.setRequestHeader('Userid', this.authService.userData$.getValue().UserId.toString());
                // xhr.setRequestHeader('Token', this.authService.tokenGetter());
                //
                // const formData = new FormData();
                // formData.append('Image', blobInfo.blob(), blobInfo.filename());
                // formData.append('Request', JSON.stringify({ PartnerId: this.partnerId }));
                //
                // xhr.upload.onprogress = (e) => {
                //     progress(e.loaded / e.total * 100);
                // };

                // xhr.onload = () => {
                //     if (xhr.status === 403) {
                //         reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                //         this.toastService.showToastMsg('error', 'ImageUploadErrorMessage');
                //         return;
                //     }
                //
                //     if (xhr.status < 200 || xhr.status >= 300) {
                //         reject('HTTP Error: ' + xhr.status);
                //         this.toastService.showToastMsg('error', 'ImageUploadErrorMessage');
                //         return;
                //     }
                //
                //     const res = JSON.parse(xhr.responseText);
                //     resolve(environment.ImageCDNUrl + '/' + res.fileName);
                //
                //     resolve(res.location);
                // };

                // xhr.onerror = () => {
                //     reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                //     this.toastService.showToastMsg('error', 'ImageUploadErrorMessage');
                // };
                //
                // xhr.send(formData);
            }),
            importcss_append: true,
            min_height: this.minHeight,
            image_caption: true,
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
            noneditable_class: 'mceNonEditable',
            toolbar_mode: 'sliding',
            // contextmenu: 'link image table',
            skin: localStorage.getItem('selectedTheme') === 'light' ? 'oxide' : 'oxide-dark',
            content_css: localStorage.getItem('selectedTheme') === 'light' ? 'default' : 'dark',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
            branding: false,
            promotion: false,
            base_url: '/tinymce',
            suffix: '.min',
            // editimage_cors_hosts: ['picsum.photos'],
            // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
            // link_list: [
            //   { title: 'My page 1', value: 'https://www.tiny.cloud' },
            //   { title: 'My page 2', value: 'http://www.moxiecode.com' }
            // ],
            // image_list: [
            //   { title: 'My page 1', value: 'https://www.tiny.cloud' },
            //   { title: 'My page 2', value: 'http://www.moxiecode.com' }
            // ],
            // image_class_list: [
            //   { title: 'None', value: '' },
            //   { title: 'Some class', value: 'class-name' }
            // ],
            // file_picker_callback: (callback: any, value: any, meta: any) => {
            //   /* Provide file and text for the link dialog */
            //   if (meta.filetype === 'file') {
            //     callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
            //   }
            //
            //   /* Provide image and alt text for the image dialog */
            //   if (meta.filetype === 'image') {
            //     callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
            //   }
            //
            //   /* Provide alternative source and posted for the media dialog */
            //   if (meta.filetype === 'media') {
            //     callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
            //   }
            // },
            // templates: [
            //     {
            //         title: 'New Table', description: 'creates a new table',
            //         content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr>' +
            //             '<th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
            //     },
            //     { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
            //     {
            //         title: 'New list with dates', description: 'New List with dates',
            //         content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span>' +
            //             '<h2>My List</h2><ul><li></li><li></li></ul></div>'
            //     }
            // ],
            // template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
            // template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        };
    }

    private createSkeletonBox(): void {
        this.skeleton = document.createElement('div');
        this.skeleton.classList.add('skeleton');
        this.skeleton.style.padding = '15px';
        this.skeleton.style.height = `${this.minHeight}px`;
        this.skeleton.style.borderRadius = '5px';

        const style: HTMLElement = document.createElement('style');
        style.textContent = `

        .skeleton {
          position: relative;
          background-color: #DDDBDD;
        }

        .skeleton::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-image:
           linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
          background-size: 200% 100%;
          animation: skeleton-loading 2s linear infinite;
        }

        @keyframes skeleton-loading {
          0% {
            background-position: 100% 0;
          }
          100% {
            background-position: -100% 0;
          }
        }
        `;

        document.head.appendChild(style);
        this.wrap.appendChild(this.skeleton);
    }

    public onEditorInit(): void {
        this.editorLoaded = true;
        this.wrap.querySelector('.skeleton')?.remove();
        this.skeleton?.remove();
    }

}
