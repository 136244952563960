<p-toolbar>
    <ng-template pTemplate="left">
        <ng-content></ng-content>
    </ng-template>
    <ng-template pTemplate="right">
        <div class="d-filters">
            <app-d-filters *ngIf="isDesktop"
                [selectedCurrencyOption]="filterForm.controls.Currency?.value"
                [selectedPartnerOption]="filterForm.controls.PartnerId?.value"
                [selectedStateOption]="filterForm.controls.Status?.value"
                [selectedParentOption]="filterForm.controls.ParentId?.value"
                (selectedPeriod)="formulateFilterBySelectedTimePeriod($event)"
                (dateTimeComponent)="getDateTimeComponent($event)"
            ></app-d-filters>
        </div>
        <div *ngIf="!isDesktop" class="filters-sidebar-btn"
             aria-disabled="true" (click)="onFilterMenuClick()">
            <i class="pi icon icon-filter_filled"></i>
            <p class="filter-title">{{'Filters' | translate}}</p>
        </div>
        <p-sidebar
            *ngIf="!isDesktop"
            [(visible)]="filtersSidebarActive"
            position="right"
            styleClass="filters-sidebar no-header"
            [showCloseIcon]="false"
            [baseZIndex]="10000"
        >
            <app-m-filters
                [selectedCurrencyOption]="filterForm.controls.Currency?.value"
                [selectedPartnerOption]="filterForm.controls.PartnerId?.value"
                [selectedStateOption]="filterForm.controls.Status?.value"
                [selectedParentOption]="filterForm.controls.ParentId?.value"
                (selectedPeriod)="formulateFilterBySelectedTimePeriod($event)"
                (dateTimeComponent)="getDateTimeComponent($event)"
            ></app-m-filters>
        </p-sidebar>
    </ng-template>
</p-toolbar>
