import { Component } from '@angular/core';

import { ThemeService } from '@core/services';

@Component({
    selector: 'app-notfound',
    templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent {
    constructor(public themeService: ThemeService) {
    }
}
