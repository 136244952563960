import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { IEnum } from '@core/interfaces';
import { TransactionStatusTypesEnum } from '@core/enums';
import { environment } from '@env/environment';

@Component({
    selector: 'app-transaction-statuses-renderer',
    template: `
        <div [ngStyle]="{ 'color': status?.Color, 'background-color': status?.Background }"
             [ngClass]="statusTransactionBadgeClasses"
             class='transaction-statuses'
        >
            <button *ngIf="showRetryButton"
                    class="loading-icon p-button-statuses custom-tooltip"
                    pButton pRipple
                    appendTo="body"
                    tooltipEvent="hover"
                    [icon]="'pi icon-refresh icon-size-sm'"
                    [pTooltip]="'Retry' | translate"
                    [tooltipPosition]="'left'"
                    [tooltipStyleClass]="'retry-tooltip'"
                    [positionLeft]="7"
                    (click)="onRetryPayment()"
            >
            </button>
            {{ status?.Name | translate }}
        </div>
    `,
    styles: [`
        .status-transaction-badge {
            padding: 4.5px 12px 4.5px 0;
        }

        .p-button-statuses {
            padding: 7px 4px 7px 7px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .p-button-statuses:focus {
            box-shadow: none;
        }
    `]
})
export class TransactionStatusesRenderer implements ICellRendererAngularComp, OnDestroy {

    public status: IEnum;
    public params: ICellRendererParams;

    public isPaymentFailed: boolean = false;
    public statusTransactionBadgeClasses: { [key: string]: boolean };
    public showRetryButton: boolean;

    private unsubscribeStatuses$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.unsubscribeStatuses$.next();
        this.unsubscribeStatuses$.complete();
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        params.data.statusesSubject
            .pipe(takeUntil(this.unsubscribeStatuses$))
            .subscribe(res => {
                this.status = res.find(status => status.Value === params.value);
                this.showRetryButton = this.params.data.Status === TransactionStatusTypesEnum.Failed
                    && environment.role !== 'admin';
                this.statusTransactionBadgeClasses = {
                    'status-badge': true,
                    'status-transaction-badge': this.showRetryButton
                };
            });

    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    public onRetryPayment(): void {
        this.isPaymentFailed = true;
        const payload = {
            Id: this.params.data.Id,
            AgentId: this.params.data.AgentId,
            PlayerId: this.params.data.AgentPlayerId,
            TransactionType: this.params.data.TransactionTypeId,
            Status: this.params.data.Status,
            Amount: this.params.data.Amount,
            Date: this.params.data.Date,
            Comment: this.params.data.Comment,
        };
        this.params.data.onRetryPayment(payload);
    }

}
