import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-comment-detail-component',
    template: `
        <div class="note-detail note-detail-edit">
            <form [formGroup]="editNoteForm" class="edit-note-form p-fluid grid">
                <div class="col mr-1 ml-1">
                    <textarea
                        formControlName="Comment"
                        pInputTextarea
                        [ngClass]="{'ng-touched ng-invalid' : editNoteForm.controls.Comment.touched
                            && (editNoteForm.controls.Comment.hasError('required')
                            || editNoteForm.controls.Comment.hasError('maxlength'))}"></textarea>
                    <small *ngIf="editNoteForm.controls.Comment.touched
                       && editNoteForm.controls.Comment.hasError('required')"
                           class="p-error">{{ 'FieldRequiredErrorComment' | translate }}</small>
                    <small *ngIf="editNoteForm.controls.Comment.touched
                       && editNoteForm.controls.Comment.hasError('maxlength')"
                           class="p-error">{{ 'MaxLengthValidationMessage' | translate: {length: 500} }}</small>
                </div>

            </form>
        </div>
    `
})
export class CommentDetailRendererComponent implements ICellRendererAngularComp, OnInit, OnDestroy {
    public params: ICellRendererParams;

    public editNoteForm: UntypedFormGroup = new UntypedFormGroup({
        Comment: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
    });

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor() {
    }

    ngOnInit(): void {
        this.subscribeToEditFormChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        this.params = params;
        this.getEditNoteFormData();
    }

    getEditNoteFormData(): void {
        this.editNoteForm.get('Comment').patchValue(this.params.data.LastComment);
    }

    subscribeToEditFormChanges(): void {
        this.editNoteForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => {
                this.params.data.Comment = value.Comment;
            });
    }
}
