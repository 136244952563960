import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import { HttpService } from '@core/services';
import {
    IRolePermission,
    IResponseWithCount,
    IRoleData,
    IEnum,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root',
})
export class RolesService {

    public roles$: BehaviorSubject<IEnum[]> = new BehaviorSubject([]);
    public permissions$: BehaviorSubject<IRolePermission[]> = new BehaviorSubject([]);
    private isAdmin: boolean = environment.role === 'admin';

    constructor(private http: HttpService) {
        if (this.isAdmin) {
            this.getPermissions();
        }
    }

    /**
     * @desc Method for adding new role
     * @param role
     * @returns {Observable<IRoleData>} - Observable of the new role
     */
    public addRole(role: any): Observable<IRoleData> {
        return this.http.request('post', environment.ApiUrl + Methods.ADD_ROLE, role);
    }

    /**
     * @desc Method for getting roles data in table.
     * @param payload
     * @returns {Observable<IResponseWithCount<IRoleData>>} - Observable of the roles data
     */
    public getAllRoles(payload: any): Observable<IResponseWithCount<IRoleData>> {
        return this.http.request('post', environment.ApiUrl + Methods.GET_ALL_ROLES, payload);
    }

    /**
     * @desc Method for updating the role.
     * @param role
     * @returns {Observable<IRoleData>} - Observable of the updated role
     */
    public updateRole(role: IRoleData): Observable<IRoleData> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_ROLE, role);
    }

    /**
     * @desc Method for deleting the role by Id.
     * @param roleId
     * @returns {Observable<IRoleData>} - Observable of the role by Id
     */
    public deleteRole(roleId: number): Observable<IRoleData> {
        return this.http.request('delete', environment.ApiUrl + Methods.DELETE_ROLE + roleId);
    }

    /**
     * @desc Method for getting the role by Id.
     * @param roleId
     * @returns {Observable<IRoleData>} - Observable of the role by Id
     */
    public getRoleById(roleId: number): Observable<IRoleData> {
        return this.http.request('get', environment.ApiUrl + Methods.GET_ROLE_BY_ID + roleId);
    }

    /**
     * @desc Method for getting roles list and updating roles list BehaviorSubject.
     * @returns {Observable<IEnum[]> } - Observable of the roles list
     */
    public getRoles(): Observable<IEnum[]> {
        return this.http
            .request('get', environment.ApiUrl + Methods.GET_ROLES)
            .pipe(tap((res: IEnum[]) => this.roles$.next(res)));
    }

    /** @des Method for getting permissions and storing enum in Local Storage.*/
    public getPermissions(): void {
        if (localStorage.getItem('enum_permissions')) {
            this.permissions$.next(JSON.parse(localStorage.getItem('enum_permissions')));
        } else {
            this.http
                .request('get', environment.ApiUrl + Methods.GET_PERMISSIONS)
                .subscribe((res: IRolePermission[]) => {
                    localStorage.setItem('enum_permissions', JSON.stringify(res))
                    this.permissions$.next(res);
                });
        }
    }
}
