<ng-container *ngIf="loading; else commissionsBlock">
    <div class="spinner-container">
        <i class="pi pi-spin pi-spinner ml-2"></i>
    </div>
</ng-container>
<ng-template #commissionsBlock>
    <div class="inner-page-main-section p-fluid" *ngIf="agent">
        <div class="section"
             [ngClass]="{'add-commission-plan-section':
         isAdmin && agent.ParentId === null && !agentCommissionInfo}">


            <h3 *ngIf="!isAdmin && agentCommissionInfo || isAdmin"
                class="title commission-plan">{{'CommissionPlan' | translate | uppercase}}</h3>
            <div>
                <div
                    *ngIf="!isAdmin ||  isAdmin && agentCommissionInfo && viewCommissionPlanPermission; else AddPlan">
                    <div class="inner-page-main-section" *ngIf="agentCommissionInfo">

                        <div class="p-fluid p-formgrid grid form">
                            <div class="col-12 md:col-4 field-section">
                                <div>
                                    <div class="field">
                                        <label class="field-name">{{ 'Period' | translate }}</label>
                                        <div
                                            class="field-value">
                                            {{ agentCommissionInfo.PeriodName ? agentCommissionInfo.PeriodName : '-' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 field-section">
                                <div>
                                    <div class="field ">
                                        <label class="field-name">{{ 'Negative' | translate }}</label>
                                        <app-boolean-transform
                                            [boolean]="agentCommissionInfo.IsNegative"
                                        ></app-boolean-transform>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 field-section">
                                <div>
                                    <div class="field ">
                                        <label class="field-name">{{ 'LastCalculation' | translate }}</label>
                                        <div class="field-value">
                                            {{ agentCommissionInfo.CalculationTime ?
                                            (agentCommissionInfo.CalculationTime | date:
                                                'dd.MM.YYYY HH:mm': timeZone)
                                            : '-' }}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="table-wrapper section">
                        <h3 class="title">{{'Commission' | translate | uppercase}}</h3>
                        <div
                            *ngIf="!myProfileCommissionSection &&
                            (!this.isAdmin || this.isAdmin && this.addCommissionPermission )"
                            class="add-button-container">
                            <button pButton pRipple [label]="'Add' | translate"
                                    icon="pi pi-plus" class="p-button-primary add-btn"
                                    [disabled]="!agentCommissionInfo"
                                    (click)="openAddEditCommissionDialog()">
                            </button>
                        </div>
                        <app-ag-table
                            [name]="tableName"
                            [columnDefs]="columnDefs"
                            [domLayout]="'autoHeight'"
                            [pagination]="pagination"
                            [paginationPageSize]="paginationPageSize"
                            [rowData]="commissions"
                            [getContextMenuItems]="getContextMenuItems"
                        ></app-ag-table>
                    </div>

                    <div
                        *ngIf="agentCommissionInfo && commissionChangeHistory && viewCommissionsChangeHistoryPermission"
                        class="table-wrapper section">
                        <h3 class="title">{{'CommissionChangeHistory' | translate | uppercase}}</h3>
                        <app-ag-table
                            [name]="tableNameHistory"
                            [columnDefs]="columnDefsHistory"
                            [domLayout]="'autoHeight'"
                            [pagination]="pagination"
                            [paginationPageSize]="paginationPageSize"
                            [rowData]="commissionChangeHistory"
                        ></app-ag-table>
                    </div>
                </div>

                <ng-template #AddPlan>
                    <ng-container
                        *appCheckPermissions="permissionsService.permissions?.CommissionsPermissions?.AddCommissionPlan">
                        <div class="add-commission-plan" (click)="addCommissionPlan()">
                            <i class="pi icon-add_note icon-size-md"></i>
                            <p class="m-0 text">
                                {{'AddCommissionPlan' | translate}}
                            </p>
                        </div>
                    </ng-container>

                </ng-template>
            </div>
        </div>
    </div>
</ng-template>
