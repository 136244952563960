<div>
    <div class="inner-page-info-top-section agent-info-top-section">
        <div class="flex flex-wrap">
            <div class="top-section-item">
                <i class="pi icon icon-client_type icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'AgentId' | translate }}
                    </div>
                    <div class="detail-value">
                        {{ agentDetail?.Id !== null ? agentDetail.Id : '-' }}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-currency icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Currency' | translate }}
                    </div>
                    <div class="detail-value uppercase">
                        {{ agentDetail?.Currency ? agentDetail.Currency : '-' }}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-wallet icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Balance' | translate }}
                    </div>
                    <div class="detail-value">
                        {{agentDetail?.RealBalance | number : '1.2-2'}}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-bonus-point icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Earning' | translate }}
                    </div>
                    <div class="detail-value">
                        {{agentDetail?.EarningBalance | number : '1.2-2'}}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-bonus-point icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'LastPaid' | translate }}
                    </div>
                    <div class="detail-value">
                        {{agentDetail?.LastPaidAmount | number : '1.2-2'}}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-bonus-point icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Pending' | translate }}
                    </div>
                    <div class="detail-value">
                        {{agentDetail?.PendingEarnings | number : '1.2-2'}}
                    </div>
                </div>
            </div>

            <div class="top-section-item">
                <i class="pi icon icon-users-group icon-size-lg"></i>
                <div class="detail-inner p0 border-none">
                    <div class="detail-key">
                        {{ 'Players' | translate }}
                    </div>
                    <div class="detail-value">
                        {{ agentDetail?.PlayersCount !== null ? agentDetail.PlayersCount : '-' }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="tabs-wrapper">
    <p-tabMenu [model]="tabMenuItems" [scrollable]="true" [activeItem]="activeItem"></p-tabMenu>
    <router-outlet></router-outlet>
</div>
