<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi icon icon-menu icon-size-xl"></i>
        </a>
        <!--        <img id="logo-horizontal" class="horizontal-logo" [routerLink]="['/']"-->
        <!--             src="assets/layout/images/logo-white.svg" alt="logo" />-->
        <span class="topbar-separator"></span>

        <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>

        <img
            id="logo-mobile"
            class="mobile-logo"
            [src]="themeService.colorScheme === 'light' ? themeService.selectedTheme?.SecondaryShortLogo : themeService.selectedTheme?.PrimaryShortLogo"
            alt="logo"/>
    </div>

    <app-menu></app-menu>

    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
        <ul class="topbar-menu" *ngIf="authService.userData$ | async as user">
            <!--            <li class="search-item">-->
            <!--                <a tabindex="0" (click)="appMain.onSearchClick($event)">-->
            <!--                    <i class="pi icon icon-search"></i>-->
            <!--                </a>-->
            <!--            </li>-->
            <!--            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">-->
            <!--                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">-->
            <!--                    <i class="pi icon icon-notifications"></i>-->
            <!--                    <span class="topbar-badge">5</span>-->
            <!--                </a>-->
            <!--                <ul class="notifications-menu fade-in-up">-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-shopping-cart"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">New Order</div>-->
            <!--                                <div class="notification-detail">You have <strong>3</strong> new orders.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-check-square"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Withdrawn Completed</div>-->
            <!--                                <div class="notification-detail">Funds are on their way.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-chart-line"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Monthly Reports</div>-->
            <!--                                <div class="notification-detail">New reports are ready.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-comments"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Comments</div>-->
            <!--                                <div class="notification-detail"><strong>2</strong> new comments.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li role="menuitem">-->
            <!--                        <a href="#" tabindex="0">-->
            <!--                            <i class="pi icon pi-exclamation-circle"></i>-->
            <!--                            <div class="notification-item">-->
            <!--                                <div class="notification-summary">Chargeback Request</div>-->
            <!--                                <div class="notification-detail"><strong>1</strong> to review.</div>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </li>-->

            <li class="transaction-item" *ngIf="!isAdmin">
                <a (click)="openTransferDialog()">
                    <i class="pi icon-transactions icon-size-md"></i>
                </a>
            </li>

            <li class="balance-container" *ngIf="!isAdmin">
                <div class="balance-item-container">
                    <i class="pi icon icon-wallet icon-size-xs"></i>
                    <span>{{user.Currency + ' ' +
                    ((totalBalance?.Balance !== null ? totalBalance?.Balance : 0) | number : '1.2-2')}}
                    </span>
                </div>
                <div class="balance-item-container">
                    <i class="pi icon icon-bonus-point icon-size-xs"></i>
                    <span>{{user.Currency + ' ' +
                    ((earningBalance?.Balance !== null ? earningBalance?.Balance : 0)  | number : '1.2-2')}}
                    </span>
                </div>
            </li>

            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a class="p-0" href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <div class="profile-name">
                        <span class="username">{{ user.UserName }}</span>
                        <span class="user-id">{{ user.Id }}</span>
                    </div>
                    <i class="pi icon icon-user icon-size-lg"></i>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li class="profile-name custom-menu-item profile-menu-li ">
                        <span class="username">{{ user.UserName }}</span>
                        <span class="user-id">{{ user?.Id }}</span>
                    </li>
                    <li class="balance-container custom-menu-item profile-menu-li" *ngIf="!isAdmin">
                        <div class="balance-item-container">
                            <i class="pi icon icon-wallet icon-size-xs"></i>
                            <span>{{user.Currency + ' ' +
                            ((totalBalance?.Balance !== null ?
                                totalBalance?.Balance : 0) | number : '1.2-2')}}
                    </span>
                        </div>
                        <div class="balance-item-container">
                            <i class="pi icon icon-bonus-point icon-size-xs"></i>
                            <span>{{user.Currency + ' ' +
                            ((earningBalance?.Balance !== null ?
                                earningBalance?.Balance : 0)  | number : '1.2-2')}}
                    </span>
                        </div>
                    </li>
                    <li [routerLink]="['my-profile/personal-details']" class="profile-menu-li">
                        <a>
                            <i class="pi icon-user_outline icon-size-sm"></i>
                            <span class="profile-menu-item">{{'Profile' | translate}}</span>
                        </a>
                    </li>
                    <li class="dropdown-section profile-menu-li">
                        <p-panelMenu class="profile-menu" styleClass="languages" [model]="panelMenuItems"></p-panelMenu>
                    </li>
                    <li (click)="$event.stopPropagation()" class="switcher-section profile-menu-li">
                        <a class="switcher">
                            <div>
                                <i class="pi icon pi-moon"></i>
                                <span class="profile-menu-item">{{'DarkMode' | translate}}</span>
                            </div>

                            <p-inputSwitch
                                [(ngModel)]="darkModeActive"
                                (onChange)="changeTheme($event)"
                            ></p-inputSwitch>
                        </a>
                    </li>

                    <ng-container *ngIf="!isAdmin">
                        <li (click)="openBalanceTransferDialog()" class="profile-menu-li">
                            <a>
                                <i class="pi icon-transactions icon-size-sm"></i>
                                <span class="profile-menu-item">{{'EarningTransfer' | translate}}</span>
                            </a>
                        </li>
                    </ng-container>

                    <li class="profile-menu-li"
                        (click)="onCopyPlayerRegistrationLink($event)"
                        *ngIf="!isAdmin"
                        [pTooltip]="'PlayerRegistrationLinkCopied' | translate"
                        [life]="3000"
                        tooltipEvent="focus">
                        <a>
                            <i class="pi pi-copy"></i>
                            <span class="profile-menu-item">{{'GetRegisterLink' | translate}}</span>
                        </a>
                    </li>

                    <li *ngIf="authService.isAuthenticated$ | async" (click)="logout()"
                        class="profile-menu-li">
                        <a>
                            <i class="pi icon icon-logout icon-size-sm"></i>
                            <span class="profile-menu-item">{{'Logout' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!--            <li class="right-sidebar-item" aria-disabled="true">-->
            <!--                <a tabindex="0" (click)="appMain.onRightMenuClick($event)" >-->
            <!--                    <i class="pi icon icon-filter_filled"></i>-->
            <!--                </a>-->
            <!--            </li>-->
        </ul>
    </div>
</div>
