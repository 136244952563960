import { Component, Input, OnInit } from '@angular/core';

import { IAgentDetail } from '@core/interfaces';

@Component({
    selector: 'app-agent-info',
    templateUrl: './agent-info.component.html',
    styleUrls: ['./agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit {
    @Input() agentDetail: IAgentDetail;
    @Input() isDesktopView: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
