import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, skip, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    IDialogInfo,
    IMoveAgentForm,
    IParent,
} from '@core/interfaces';
import {
    AgentsService,
    ToastService,
} from '@core/services';

@Component({
    selector: 'app-move-agent',
    templateUrl: './move-agent.component.html'
})
export class MoveAgentComponent implements OnDestroy {
    public moveAgentForm: FormGroup = new FormGroup<IMoveAgentForm>({
            AgentId: new FormControl(null, Validators.required),
            NewParentId: new FormControl(null, Validators.required)
        }
    );

    public dialogInfo: IDialogInfo[];
    public parents: IParent[] = [];

    public onParentFilterChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public pending: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public ref: DynamicDialogRef,
        private router: Router,
        public config: DynamicDialogConfig,
        private agentService: AgentsService,
        private toastService: ToastService,
    ) {
        this.subscribeToRouterEvents();
        this.subscribeToParentChanges();
        this.dialogInfo = this.config.data.dialogInfo;
        this.moveAgentForm.controls.AgentId.setValue(this.config.data.AgentId);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getParents(UserName: string): void {
        const payload: any = {
            PartnerId: this.config.data.PartnerId,
            UserName,
        };

        this.agentService.getAgentByUserName(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res) => {
                    this.parents = res.map(parent => {
                        return {
                            ...parent,
                            disabled: parent.Id === this.config.data.AgentId
                        };
                    });
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public onMoveAgent(): void {
        if (this.moveAgentForm.valid) {
            this.pending = true;

            this.agentService.moveAgent(this.moveAgentForm.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.pending = false;
                        this.ref.close(true);
                        this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Agent');
                    },
                    error: (err: HttpErrorResponse) => {
                        this.pending = false;
                        this.toastService.showToastMsg('error', err?.error?.message);
                    }
                });
        } else {
            this.moveAgentForm.markAllAsTouched();
        }
    }

    public onCancel(): void {
        this.ref.close();
    }

    public parentSelected(event): void {
        if (event.value) {
            this.moveAgentForm.controls.NewParentId.setValue(event.value.Id,
                {emitEvent: false, emitModelToViewChange: false});
        }
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }

    private subscribeToParentChanges(): void {
        this.onParentFilterChange$
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(700),
                skip(1))
            .subscribe({
                next: value => {
                    if (value) {
                        this.getParents(value);
                    } else {
                        this.parents = [];
                    }
                }
            });
    }
}
