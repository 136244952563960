import { Component } from '@angular/core';

import { ConfigService } from '@core/services';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

    public projectVersion: string = packageJson.version;

    constructor(
      public configService: ConfigService,
  ) {}
}
