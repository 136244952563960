import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

import { AppMainComponent } from '@core/components';
import { ConfigService, PermissionsService, ThemeService } from '@core/services';
import { IMenuItems } from '@core/interfaces';
import { environment } from '@env/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    public model$: Observable<IMenuItems[]>;
    private model: IMenuItems[] = [];
    private isAdmin: boolean = environment.role === 'admin';

    constructor(
        public appMain: AppMainComponent,
        public configService: ConfigService,
        public themeService: ThemeService,
        private cookieService: CookieService,
        private permissionsService: PermissionsService,
    ) {

    }

    ngOnInit(): void {

        this.generateMenu();

        this.model$ = of(this.model);
    }

    private generateMenu(): void {
        if (this.cookieService.get('permissions') && this.isAdmin) {
            this.model.push({ items: this.permissionsService.filteredAdminMenuItems });
        } else {
            this.model.push(environment.menuItems);
        }
    }

    public onScrollSidebar(event): void {
        if (this.appMain.isDesktop()) {
            this.configService.sidebarScrollTop$.next(event.target.scrollTop);
        }
    }
}
