import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    template: `
        <div class="member-container">
<!--            TODO improve-->
<!--            <div class="square"-->
<!--                 [ngClass]="params?.data && params?.data && [this.rendererKey] === 1 ? 'active-state' : 'blocked-state'">-->
<!--            </div>-->
            <div class="square" [ngClass]="params.data[this.rendererKey] === 1 ? 'active-state' : 'blocked-state'"></div>
            <span>{{params?.value}}</span>
        </div>
    `,
    styles: ['.member-container {display: flex; align-items: center; gap: 8px};' +
    '.square {min-width: 8px; height: 8px; border-radius: 2px}; .active-state {background-color: #79F2B8};' +
    '.blocked-state {background-color: #EE6464}']
})

export class MemberStateRenderer implements ICellRendererAngularComp {

    public params: ICellRendererParams;
    public rendererKey: string;

    agInit(params: ICellRendererParams): void {
        this.rendererKey = params?.colDef?.cellRendererParams?.rendererKey;
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

}
