import { ColDef } from 'ag-grid-community';

/** In case we want to generateColumnDefs with ColGroupDef[], we need to pass here ColGroupDef[0].children field from the component
 * and  in teh component we should have the syntax like this:
 *  this.columnGroupDef[0].children =  generateColumnDefs(this.tableName, this.columnGroupDef[0].children);
 */

export function generateColumnDefs(name: string, columnDefs: ColDef[]): ColDef[] {
    if (localStorage.getItem(`column-state_${name}`)) {
        const savedState = JSON.parse(localStorage.getItem(`column-state_${name}`));
        const newState = [];
        savedState.forEach(state => {
            const column = columnDefs.find(c => c.field === state.colId);
            if (column) {
                newState.push({ ...column, ...state });
            }
        });
        return newState;
    }
    return columnDefs;
}
