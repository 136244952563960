<div class="p-fluid p-formgrid form">

    <form [formGroup]="noteForm" (ngSubmit)="saveComment()">

        <div class="field col-12">
            <label for="Comment">{{ 'Comment' | translate }} *</label>
            <input
                pInputText
                id="Comment"
                formControlName="Comment"
                [ngClass]="{'ng-touched ng-invalid': noteForm.controls.Comment.invalid &&
                noteForm.controls.Comment.touched}"
                type="text" />
            <div>
                <small *ngIf="noteForm.controls.Comment.touched
                       && noteForm.controls.Comment.hasError('required')"
                       class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                </small>
            </div>
        </div>

        <div class="p-dialog-footer">
            <button
                type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary">
            </button>
            <button
                type="submit"
                pButton
                pRipple
                [disabled]="noteForm.valid && noteForm.pristine"
                [label]="'Save' | translate"
                icon="pi pi-check"
                class="p-button-text">
            </button>
        </div>
    </form>

</div>
