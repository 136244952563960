import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

import { NotesService, ToastService } from '@core/services';
import { INote, INoteHistory, IResponseData } from '@core/interfaces';

@Component({
    selector: 'app-note-action-buttons-component',
    template: `
        <div class="action-buttons">
            <ng-container *ngIf="!params.data?.editMode; else editModeBlock">
                <button pButton pRipple type="button" icon="icon-history"
                        (click)="onShowHistory()"
                        class="p-button-text"></button>
                <button pButton pRipple type="button" icon="icon-edit"
                        (click)="onEdit()"
                        class="p-button-text"></button>
            </ng-container>
            <ng-template #editModeBlock>
                <button pButton pRipple type="button" icon="icon-square-cancel"
                        (click)="onCancel()"
                        class="p-button-text"></button>
                <button pButton pRipple type="button" icon="icon-save"
                        (click)="onSave()"
                        class="p-button-text button-save"></button>
            </ng-template>
        </div>
    `
})
export class NoteActionButtonsRendererComponent implements ICellRendererAngularComp, OnDestroy {
    public id: number;
    private unsubscribe$: Subject<void> = new Subject<void>();
    public params: ICellRendererParams;
    public notePreviousValue: INote;

    constructor(
        private notesService: NotesService,
        public toastService: ToastService,
        private translateService: TranslateService,
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
        this.params = params;
        if (this.params.data.ShowExpanded && !this.params.data.editMode) {
            this.onEdit();
        }
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    onShowHistory(): void {
        this.params.data.getHistoryById(this.params.data.Id);
    }

    onEdit(): void {
        this.params.data.editMode = true;
        this.params.api.forEachNode((node) => {
            if (node?.data.Id === this.id) {
                node.setExpanded(true);
                if (!this.params.data.ShowExpanded) {
                    this.params.api.refreshCells();
                }
                return;
            }
        });

        this.notePreviousValue = {...this.params.data};
    }

    onCancel(): void {
        this.params.data.Message = this.notePreviousValue.Message;
        this.params.data.State = this.notePreviousValue.State;

        this.params.data.editMode = false;

        this.params.api.forEachNode((node) => {
            if (node?.data.Id === this.id) {
                node.setExpanded(false);
                return;
            }
        });
    }

    onSave(): void {
        if ((this.params.data.Message && this.params.data.State)
            && (this.params.data.Message !== this.notePreviousValue.Message
            || this.params.data.State !== this.notePreviousValue.State)) {
            const note = {...this.params.data};
            if (note.hasOwnProperty('statesSubject')) {
                delete note.statesSubject;
            }
            this.notesService.saveNote(note)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: IResponseData<INoteHistory[]>) => {
                    if (res?.ResponseCode === 0) {
                        this.toastService.showToastMsg('success', 'EditingNoteSuccessMessage');
                        this.params.api.refreshServerSideStore();
                        if (this.params.data.ShowExpanded) {
                            this.params.data.close(res.ResponseObject);
                        } else {
                            this.params.data.editMode = false;
                        }
                    } else {
                        this.toastService.showToastMsg('error', res.Description);
                    }
                });
        }
    }

    getCellParams(params: ICellRendererParams): void {
        this.id = params.data.Id;
    }
}
