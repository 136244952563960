import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { StatesEnum } from '@core/enums';

@Component({
    selector: 'app-note-detail-component',
    template: `
        <div class="note-detail note-detail-edit">
            <form [formGroup]="editNoteForm" class="edit-note-form p-fluid grid">
                <div class="col-6 md:col-10 pr-1">
                    <textarea
                        formControlName="Message"
                        pInputTextarea
                        [ngClass]="{'ng-touched ng-invalid' : editNoteForm.controls.Message.touched
                            && (editNoteForm.controls.Message.hasError('required')
                            || editNoteForm.controls.Message.hasError('maxlength'))}"></textarea>
                        <small *ngIf="editNoteForm.controls.Message.touched
                       && editNoteForm.controls.Message.hasError('required')"
                               class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}</small>
                        <small *ngIf="editNoteForm.controls.Message.touched
                       && editNoteForm.controls.Message.hasError('maxlength')"
                               class="p-error">{{ 'MaxLengthValidationMessage' | translate: {length: 500} }}</small>
                </div>
                <div class="col-6 md:col-2 pl-1">
                    <p-dropdown
                        formControlName="State"
                        appendTo="body"
                        [options]="states"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="selectedState"
                        (ngModelChange)="onSelectState()"
                    >
                    </p-dropdown>
                </div>
            </form>
        </div>
    `
})
export class NoteDetailRendererComponent implements ICellRendererAngularComp, OnInit, OnDestroy {
    public params: ICellRendererParams;

    public editNoteForm: UntypedFormGroup = new UntypedFormGroup({
        Message: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
        State:  new UntypedFormControl('', Validators.required),
    });

    public states = [{ id: 1, name: 'Active'}, { id: 2, name: 'Deleted' }];
    public selectedState: number;
    public statesEnum = StatesEnum;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor() {}

    ngOnInit(): void {
        this.subscribeToEditFormChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        this.params = params;
        this.getEditNoteFormData();
    }

    onSelectState(): void {
        this.params.data.State = this.selectedState;
    }

    getEditNoteFormData(): void {
        this.editNoteForm.get('Message').patchValue(this.params.data.Message);
        this.editNoteForm.get('State').patchValue(this.params.data.State);
        this.selectedState = this.params.data.State;
    }

    subscribeToEditFormChanges(): void {
        this.editNoteForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => {
                this.params.data.Message = value.Message;
                this.params.data.State = value.State;
            });
    }
}
