import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
    AuthService,
    HttpService,
    PermissionsService,
    ToastService,
    UpdateStorageService
} from '@core/services';
import {
    ICategory,
    IEnum,
    IPartner,
    IPartnerConfig,
    IPartnerProduct,
    IResponseWithCount,
    IRole,
} from '@core/interfaces';
import { Methods, PartnerProductStatesEnum } from '@core/enums';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})

export class PartnersService {

    public partnerInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public partnerStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public partnerProductStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public partners$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
        private updateStorageService: UpdateStorageService,
        private authService: AuthService,
        private permissionsService: PermissionsService
    ) {
        if (environment.role === 'admin') {
            this.getRemovePartnersBasedOnAuthStateAndPermissions();
            this.subscribeToConfigVersionChanges();
        }
    }

    /** Current function is being called, because in 'admin' role we have 'Partner' filters in various parts of the project,
     * and we need them to be available before the API calls are being called based on them
     */
    private getRemovePartnersBasedOnAuthStateAndPermissions(): void {
        this.authService.isAuthenticated$
            .subscribe({
                next: (isAuth: boolean) => {
                    if (isAuth) {
                        this.loadPartners();
                    } else {
                        this.partners$.next([]);
                        localStorage.removeItem('enum_partners');
                    }
                }
            });
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.loadPartnerStates();
                this.loadPartnerProductStates();
            });
    }

    public loadPartners(forceCall = false): void {
        if (localStorage.getItem('enum_partners') && !forceCall) {
            this.partners$.next(JSON.parse(localStorage.getItem('enum_partners')));
        } else {
            this.getPartnerEnum()
                .subscribe((res: IEnum[]) => {
                    localStorage.setItem('enum_partners', JSON.stringify(res));
                    this.partners$.next(res);
                });
        }
    }

    public getPartners(): Observable<IPartner[]> {
        return this.http.request('get', environment.ApiUrl + Methods.GET_PARTNERS);
    }
    public getPartnerEnum(): Observable<IEnum[]> {
        return this.http.request('get', environment.ApiUrl + Methods.GET_PARTNER_ENUM);
    }

    private loadPartnerStates(): void {
        if (localStorage.getItem('enum_partner-states')) {
            this.partnerStates$.next(JSON.parse(localStorage.getItem('enum_partner-states')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_PARTNERS_STATES)
                .subscribe(res => {
                    res.map(status => {
                        status.Background = status.Name === 'Active' ? '#79F2B8' :
                            status.Name === 'Inactive' ? '#EE6464' : '#E3E3E8';

                        status.Color = (status.Name === 'Active' || status.Name === 'Inactive') ? '#3F3F3F' : '#B3B3BC';
                        status.Id = status.Value;
                    });
                    localStorage.setItem('enum_partner-states', JSON.stringify(res));
                    this.partnerStates$.next(res);
                });
        }
    }

    public createPartner(partner: Partial<IPartner>): Observable<any> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.CREATE_PARTNER, partner);
    }

    public updatePartner(partner: Partial<IPartner>): Observable<any> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.UPDATE_PARTNER, partner);
    }

    public getPartnerInfoById(id: number): void {
        this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PARTNER_BY_ID,
            null,
            false,
            { params: { partnerId: id } })
            .pipe(
                tap((partner) => {
                    this.partnerInfo$.next(partner);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                    if (err.status !== 401) {
                        this.router.navigate(['../']);
                    }
                    return of(err);
                }))
            .subscribe();
    }

    public getPermissionTemplateByPartnerId(partnerId: number, agentId?: number): Observable<IRole[]> {
        const payload: any = { partnerId };
        if (agentId) {
            payload.agentId = agentId;
        }

        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PERMISSION_TEMPLATE_BY_PARTNER_ID,
            payload
        );
    }

    public createRole(role: IRole): Observable<IRole> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_PERMISSION_TEMPLATE,
            role
        );
    }

    public updateRole(role: IRole): Observable<any> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.UPDATE_PERMISSION_TEMPLATE,
            role
        );
    }

    public getCategoriesByPartnerId(partnerId: number): Observable<ICategory[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_CATEGORIES_BY_PARTNER_ID,
            null,
            false,
            { params: { partnerId } });
    }

    public createCategory(category: ICategory): Observable<ICategory> {
        return this.http.request('post', environment.ApiUrl + Methods.ADD_PARTNER_CATEGORY, category);
    }

    public updateCategory(category: ICategory): Observable<ICategory> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_PARTNER_CATEGORY, category);
    }

    public loadPartnerProductStates(): void {
        if (localStorage.getItem('enum_partner-product-states')) {
            this.partnerProductStates$.next(JSON.parse(localStorage.getItem('enum_partner-product-states')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_PARTNER_PRODUCTS_STATES_ENUM)
                .subscribe({
                    next: (res: IEnum[]) => {
                        res.map(status => {
                            status.Background = status.Value === PartnerProductStatesEnum.Active ? '#79F2B8' :
                                status.Value === PartnerProductStatesEnum.Inactive ? '#EE6464' : '#E3E3E8';

                            status.Color = (status.Value === PartnerProductStatesEnum.Active ||
                                status.Value === PartnerProductStatesEnum.Inactive) ? '#3F3F3F' : '#B3B3BC';
                        });
                        localStorage.setItem('enum_partner-product-states', JSON.stringify(res));
                        this.partnerProductStates$.next(res);
                    },
                    error: (err: HttpErrorResponse) => {
                        this.toastService.showToastMsg('error', err?.error?.message);
                    },
                });
        }
    }

    public getAllProducts(payload: any): Observable<IResponseWithCount<IPartnerProduct>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PARTNER_PRODUCTS,
            payload
        );
    }

    public addPartnerProduct(payload: any): Observable<IPartnerProduct> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_PARTNER_PRODUCT,
            payload
        );
    }

    public editPartnerProduct(payload: any): Observable<IPartnerProduct> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.EDIT_PARTNER_PRODUCT,
            payload
        );
    }

    public getPartnerConfig(PartnerId: number): Observable<IPartnerConfig[]> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_PARTNER_CONFIG,
            null,
            false,
            { params: { partnerId: PartnerId } }
        );
    }

    public editPartnerConfig(payload: any): Observable<IPartnerConfig> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.UPDATE_PARTNER_CONFIG,
            payload
        );
    }

}
