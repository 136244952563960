import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { IAgentPermissions, IAgentPermissionsInfo } from '@core/interfaces';
import { environment } from "@env/environment";

@Component({
  selector: 'app-permission-edit-info',
  templateUrl: './permission-edit-info.component.html',
  styleUrls: ['./permission-edit-info.component.scss']
})
export class PermissionEditInfoComponent {
    @Input() agentPermissionsInfo: IAgentPermissionsInfo | IAgentPermissions;
    @Input() agentPermissions: string[];
    @Input() isDesktopView: boolean;
    @Input() permissionManagementForm: FormGroup;

    public pending: boolean = false;

    public isAdmin: boolean = environment.role === 'admin';

    public isInProfilePage: boolean;

    constructor(private router: Router) {
        this.isInProfilePage = this.router.url.includes('my-profile');
    }
}
