import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionsService } from '@core/services';
import { IAgentPermissions, IUserPermissions } from '@core/interfaces';
import { environment } from '@env/environment';

@Directive({
    selector: '[appCheckPermissions]'
})
export class CheckPermissionsDirective implements OnInit {

    @Input() appCheckPermissions: string[];

    public isAdmin: boolean = environment.role === 'admin';

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.checkPermission();
    }

    private checkPermission(): void {
        const currentUserPermissions: Partial<IUserPermissions | IAgentPermissions> = this.permissionsService.userPermissions$.getValue();

        let hasPermissions: boolean = false;

        this.appCheckPermissions
            .every((requiredPermission: string): void => {
                hasPermissions = currentUserPermissions[requiredPermission];
            });

        if (!this.isAdmin && hasPermissions === undefined) {
            /* In This Case We Are Agent, And We Don't Need To Check For Admin Permissions */
            hasPermissions = true;
        }

        hasPermissions ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
    }
}
