import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ICommission,
    ICommissionInfo,
    ICommissionPlan,
    ICommonSelectItem
} from '@core/interfaces';
import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import { HttpService } from '@core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {

  constructor(
      private http: HttpService,
  ) { }

    public getCommissionPeriods(): Observable<ICommonSelectItem[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_COMMISSION_PERIODS);
    }

    public getCommissionTypes(params = null): Observable<ICommonSelectItem[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_COMMISSION_TYPES,
            null,
            false,
            { params });
    }

    public getProductCategory(params): Observable<ICommonSelectItem[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PRODUCT_CATEGORY,
            null,
            false,
            { params });
    }

    public getCommission(params): Observable<ICommissionInfo> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_AGENT_COMMISSION,
            null,
            false,
            { params } );
    }

    public addCommissionPlan(data: ICommissionPlan): Observable<ICommissionPlan> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_COMMISSION_PLAN, data);
    }

    public addCommission(data: ICommission): Observable<ICommission> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_COMMISSION, data);
    }

    public editCommission(data: ICommission): Observable<ICommission> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.Edit_COMMISSION, data);
    }

    public getCommissionHistory(commissionPlanId: number): Observable<ICommission[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_COMMISSION_HISTORY,
            null,
            false,
            { params: { commissionPlanId } });
    }
}
