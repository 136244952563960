import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { debounceTime, skip, Subject, BehaviorSubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AgentsService, AuthService, CommonService, PartnersService, ToastService } from '@core/services';

import { passwordsMatch, WhitespaceValidator } from '@core/validators';
import { ICreateAgentForm, IParent, IEnum, IRole } from '@core/interfaces';

@Component({
    selector: 'app-create-agent',
    templateUrl: './create-agent.component.html',
    styleUrls: ['./create-agent.component.scss']
})

export class CreateAgentComponent implements OnInit, OnDestroy {

    public createAgentForm = new FormGroup<ICreateAgentForm>({
            RoleId: new FormControl(null, [Validators.required]),
            ParentId: new FormControl(null),
            UserName: new FormControl('', [
                Validators.required,
                Validators.maxLength(50),
                WhitespaceValidator(),
            ]),
            FirstName: new FormControl('', [
                Validators.maxLength(50)
            ]),
            LastName: new FormControl('', [
                Validators.maxLength(50)
            ]),
            Email: new FormControl(null, [
                Validators.maxLength(50)
            ]),
            Password: new FormControl('', [
                Validators.required,
                Validators.maxLength(50)
            ]),
            ConfirmPassword: new FormControl('', [
                Validators.required,
                Validators.maxLength(50)
            ]),
            PhoneNumber: new FormControl(null, [
                Validators.maxLength(50)
            ]),
            TimeZone: new FormControl<Date>(null)

        },
        { validators: passwordsMatch('Password', 'ConfirmPassword') });
    public pending: boolean;
    public currencies: Array<{ Name: string }> = [];
    public roles: IRole[];
    public partners: IEnum[] = [];
    public parents: IParent[] = [];
    public isAdmin: boolean = environment.role === 'admin';
    public timeZoneOffset: number = new Date().getTimezoneOffset() / -60;

    public onParentFilterChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private ref: DynamicDialogRef,
        private router: Router,
        private agentService: AgentsService,
        private partnersService: PartnersService,
        private authService: AuthService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private commonService: CommonService,
    ) {
    }

    ngOnInit(): void {
        this.subscribeToRouterEvents();
        this.subscribeToParentChanges();
        this.addControlToFormGroupDependingOnRole();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public addControlToFormGroupDependingOnRole(): void {
        if (this.isAdmin) {
            this.getPartners();
            this.commonService.loadCurrencyEnum();
            this.getCurrencies();

            this.createAgentForm.addControl('PartnerId', new FormControl(null, [Validators.required]));
            this.createAgentForm.addControl('Currency', new FormControl(null, [Validators.required]));
        } else {
            this.getAgentRoles(this.authService.userGetter().Id);
        }
    }

    private getCurrencies(): void {
        this.commonService.currencies$
            .pipe(takeUntil(this.unsubscribe$),
                filter(r => !!r.length))
            .subscribe(res => {
                this.currencies = res;
            });
    }

    private getPartners(): void {
        this.partnersService.getPartnerEnum()
            .pipe(take(1))
            .subscribe(
                response => {
                    this.partners = response;
                }, (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                });
    }

    public getParents(UserName: string): void {
        const payload: any = { UserName };

        this.isAdmin
            ? payload.PartnerId = this.createAgentForm.controls.PartnerId.value
            : payload.ParentId = this.authService.userGetter().Id;

        this.agentService.getAgentByUserName(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res) => {
                    this.parents = res;
                },
                error: (err: HttpErrorResponse) => {
                    this.roles = [];
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public onCancel(): void {
        this.ref.close();
    }

    public onCreateAgent(): void {
        if (this.createAgentForm.valid) {
            this.pending = true;
            this.agentService.createAgent(this.createAgentForm.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                    this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'Agent');
                    this.ref.close(res);
                    this.pending = false;
                }, (err: HttpErrorResponse) => {
                    this.pending = false;
                    this.toastService.showToastMsg('error', err?.error?.message);
                });
        } else {
            this.createAgentForm.markAllAsTouched();
        }
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }

    private subscribeToParentChanges(): void {
        this.onParentFilterChange$
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(700),
                skip(1))
            .subscribe({
                next: value => {
                    if (value) {
                        this.getParents(value);
                    } else {
                        this.parents = [];
                        this.isAdmin
                            ? this.getRoles(this.createAgentForm.controls.PartnerId.value)
                            : this.getAgentRoles(this.authService.userGetter().Id);
                    }
                }
            });
    }

    public partnerSelected(event): void {
        this.getRoles(event.value);
        const parentFilterValue = this.onParentFilterChange$.getValue();
        if (parentFilterValue) {
            this.getParents(parentFilterValue);
        }
    }

    public parentSelected(event): void {
        if (event.value) {
            if (this.isAdmin) {
                this.getRoles(this.createAgentForm.controls.PartnerId.value, event.value.Id);
                this.createAgentForm.controls.Currency.setValue(event.value.Currency);
            } else {
                this.getAgentRoles(event.value.Id);
            }

            this.createAgentForm.controls.ParentId.setValue(event.value.Id,
                {emitEvent: false, emitModelToViewChange: false});
        } else {
            this.isAdmin
                ? this.getRoles(this.createAgentForm.controls.PartnerId.value)
                : this.getAgentRoles(this.authService.userGetter().Id);
        }
    }

    private getRoles(partnerId: number, agentId?: number): void {
        this.partnersService.getPermissionTemplateByPartnerId(partnerId, agentId)
            .pipe(take(1))
            .subscribe(res => {
                this.roles = res;
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private getAgentRoles(agentId: number): void {
        this.agentService.getRoles(agentId)
            .pipe(take(1))
            .subscribe(res => {
                this.roles = res;
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public onUpdateTimeZone(event): void {
        this.createAgentForm.controls.TimeZone.setValue(event);
    }
}
