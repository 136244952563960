import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ICommission, ICommissionForm, ICommonSelectItem } from '@core/interfaces';
import { CommissionService, ToastService } from '@core/services';

@Component({
    selector: 'app-add-edit-commission',
    templateUrl: './add-edit-commission.component.html',
    styleUrls: ['./add-edit-commission.component.scss']
})
export class AddEditCommissionComponent implements OnInit {
    public commissionForm: FormGroup<ICommissionForm> = new FormGroup<ICommissionForm>({
        ProductCategoryId: new FormControl(null, [Validators.required]),
        Percent: new FormControl(0),
        Type: new FormControl(null, [Validators.required]),
    });
    public pending: boolean;
    public productCategories: ICommonSelectItem[] = this.config.data.productCategories;
    public types: ICommonSelectItem[] = [];

    constructor(
        public ref: DynamicDialogRef,
        private router: Router,
        public config: DynamicDialogConfig,
        private toastService: ToastService,
        private translateService: TranslateService,
        private commissionService: CommissionService,
    ) {

        this.commissionForm.addControl(
            this.config.data.Key,
            new FormControl(this.config.data[this.config.data.Key],
                [Validators.required])
        );
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
        if (this.config.data.commission) {
            this.setFormData();
            this.getCommissionTypes(this.config.data.commission.ProductCategoryId);
        }
    }

    private setFormData(): void {
        this.commissionForm.patchValue(this.config.data.commission);
        this.commissionForm.addControl('Id', new FormControl(this.config.data.commission?.Id));
    }

    public addEditCommission(): void {
        this.commissionForm.controls.Percent.setValue(Number(this.commissionForm.value.Percent));
        if (this.commissionForm.valid) {
            this.pending = true;
            if (this.commissionForm.value.Id) {
                this.editCommission();
            } else {
                this.addCommission();
            }
        } else {
            this.commissionForm.markAllAsTouched();
        }
    }

    private addCommission(): void {
        this.commissionService.addCommission(this.commissionForm.value as ICommission)
            .pipe(take(1))
            .subscribe(res => {
                this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'Commission');
                this.ref.close(res);
                this.pending = false;
            }, (err) => {
                this.pending = false;
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private editCommission(): void {
        this.commissionForm.removeControl(this.config.data.Key);
        const obj = this.commissionForm.getRawValue() as ICommission;
        obj.Percent = +obj.Percent;
        this.commissionService.editCommission(obj)
            .pipe(take(1))
            .subscribe(() => {
                this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Commission');
                this.ref.close(this.commissionForm.value);
                this.pending = false;
            }, (err) => {
                this.pending = false;
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public onCancel(): void {
        this.ref.close();
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }

    public updateNumber(event: number): void {
        this.commissionForm.controls.Percent.setValue(event);
        this.commissionForm.controls.Percent.markAsDirty();
    }

    public getCommissionTypes(categoryId: number): void {
        const obj = {
            [this.config.data.Key]: this.config.data[this.config.data.Key],
            categoryId
        };
        this.commissionService.getCommissionTypes(obj)
            .pipe(take(1))
            .subscribe(res => {
                this.types = res;
                if (this.config.data.commission) {
                    this.setPercentMaxValue(this.config.data.commission.Type);
                } else if (this.commissionForm.value.Type) {
                    this.commissionForm.controls.Percent.setValue(0);
                    this.commissionForm.controls.Type.setValue(null);
                }
            });
    }

    public setPercentMaxValue(type: number): void {
        this.commissionForm.controls.Percent.setValidators([Validators.required,
            Validators.min(0), Validators.max(this.types.find(e => e.Value === type).Percent)]);
        this.commissionForm.controls.Percent.updateValueAndValidity();
    }
}
