<app-ag-table
    class="radiused"
    [columnDefs]="columnDefs"
    [cacheBlockSize]="cacheBlockSize"
    [dataSource]="dataSource"
    [defaultColDef]="defaultColDef"
    [serverSideStoreType]="serverSideStoreType"
    [rowModelType]="rowModelType"
    [masterDetail]="true"
    [sideBar]="null"
    [headerHeight]="headerHeight"
    [detailCellRenderer]="detailCellRenderer"
    [detailCellRendererParams]="detailCellRendererParams"
    [getRowHeight]="getRowHeight"
></app-ag-table>

<div class="p-dialog-footer">
    <button type="button"
            pButton
            pRipple
            [label]="'Cancel' | translate"
            (click)="onCancel()"
            icon="pi pi-times"
            class="p-button-text p-button-secondary">
    </button>
</div>
