import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IAddEditAgentCommentForm } from '@core/interfaces';
import { AgentsService, ToastService } from '@core/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-agent-comment-dialog',
  templateUrl: './add-edit-agent-comment-dialog.component.html',
  styleUrls: ['./add-edit-agent-comment-dialog.component.scss']
})
export class AddEditAgentCommentDialogComponent {
    public noteForm: FormGroup = new FormGroup<IAddEditAgentCommentForm>({
        Comment: new FormControl<string>(this.config.data.Comment, [Validators.required])
    });


    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private agentService: AgentsService,
        private toastService: ToastService
    ) {}

    public onCancel(): void {
        this.ref.close();
    }

    public saveComment(): void {
        if (this.noteForm.valid) {
            const payload: any = {
                AgentId: this.config.data.AgentID,
                Comment: this.noteForm.value.Comment
            };

            if (!this.config.data.HasComment) {
                this.agentService.addCommentToAgent(payload)
                    .subscribe({
                        next: () => {
                            this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'Comment');
                            this.ref.close(true);
                        },
                        error: (err: HttpErrorResponse) => {
                            this.toastService.showToastMsg('error', err?.error?.message);
                            this.ref.close(false);
                        }
                    });
            } else {
                this.agentService.updateAgentComment(payload)
                    .subscribe({
                        next: () => {
                            this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Comment');
                            this.ref.close(true);
                        },
                        error: (err: HttpErrorResponse) => {
                            this.toastService.showToastMsg('error', err?.error?.message);
                            this.ref.close(false);
                        }
                    });
            }
        } else {
            this.noteForm.markAllAsTouched();
        }
    }
}
