<div class="inner-page-main-section">

    <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">

        <div class="p-fluid p-formgrid grid form">

            <div class="field col-12">
                <label class="field-label-name" for="Password">{{ 'NewPassword' | translate }} *</label>
                <input pInputText
                       id="Password"
                       type="password"
                       formControlName="Password"
                       [ngClass]="{'ng-dirty ng-invalid' : changePasswordForm.controls.Password.dirty
                            && changePasswordForm.controls.Password.invalid}"/>
                <small *ngIf="changePasswordForm.controls.Password.dirty
                       && changePasswordForm.controls.Password.hasError('required')"
                       class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                </small>
                <small
                    *ngIf="changePasswordForm.controls.Password.touched
                            && changePasswordForm.controls.Password.hasError('maxlength')"
                    class="p-error">
                    {{ 'MaxLengthValidationMessage' | translate: { length: 30 } }}
                </small>
            </div>

            <div class="field col-12">
                <label class="field-label-name" for="ConfirmPassword">{{ 'ConfirmPassword' | translate }} *</label>
                <input pInputText
                       id="ConfirmPassword"
                       type="password"
                       formControlName="ConfirmPassword"
                       [ngClass]="{'ng-dirty ng-invalid' : changePasswordForm.controls.ConfirmPassword.dirty
                            && changePasswordForm.controls.ConfirmPassword.invalid}"/>
                <small *ngIf="changePasswordForm.controls.ConfirmPassword.dirty
                       && changePasswordForm.controls.ConfirmPassword.hasError('required')"
                       class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
                </small>
                <small *ngIf="changePasswordForm.controls.ConfirmPassword.dirty &&
                       !changePasswordForm.controls.ConfirmPassword.hasError('required')
                       && changePasswordForm.hasError('passwordMatch')"
                       class="p-error">{{ 'PasswordsMatchError' | translate }}
                </small>
            </div>
<!--            <div class="col-12 field">-->
<!--                <div class="field-radiobutton">-->
<!--                    <p-radioButton name="EmailOrMobile"-->
<!--                                   [value]="emailOrMobile.Email"-->
<!--                                   formControlName="EmailOrMobile"-->
<!--                                   id="email"-->
<!--                    ></p-radioButton>-->
<!--                    <label for="email"-->
<!--                           (click)="changePasswordForm.controls.EmailOrMobile.setValue(emailOrMobile.Email)">-->
<!--                        {{ 'SendViaEmail' | translate }}-->
<!--                    </label>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12 field">-->
<!--                <div class="field-radiobutton">-->
<!--                    <p-radioButton name="EmailOrMobile"-->
<!--                                   [value]="emailOrMobile.Sms"-->
<!--                                   formControlName="EmailOrMobile"-->
<!--                                   id="mobile"-->
<!--                    ></p-radioButton>-->
<!--                    <label for="mobile"-->
<!--                           (click)="changePasswordForm.controls.EmailOrMobile.setValue(emailOrMobile.Sms)">-->
<!--                        {{ 'SendViaMobile' | translate }}-->
<!--                    </label>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="p-dialog-footer">
            <button
                type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary"
            ></button>
            <button
                type="submit"
                pButton
                pRipple
                [loading]="pending"
                [label]="'Save' | translate"
                icon="pi pi-check"
                class="p-button-text"
            ></button>
        </div>

    </form>
</div>
