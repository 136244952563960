import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AuthService } from '@core/services';

@Component({
    selector: 'app-date-cell-renderer',
    template: `
        <ng-container *ngIf="!isTotal">
            <div class="two-line-cell-renderer" *ngIf="date; else noDate"
                 style="padding-top: 8px">
                <div>
                    <div class="two-line-cell-renderer" style="line-height: 8px">
                        {{date | date: 'dd.MM.yyyy': timeZone}}
                    </div>
                    <div class="two-line-cell-renderer two-line-cell-renderer-bottom time"
                         style="font-size: 10px">
                        {{date | date: 'HH:mm:ss': timeZone}}
                    </div>
                </div>
            </div>
            <ng-template #noDate>
                <div>{{'-'}}</div>
            </ng-template>
        </ng-container>
    `,
})

export class DateCellRenderer implements ICellRendererAngularComp {

    public date: Date;
    public timeZone: string;
    public isTotal: boolean;

    constructor(
        private authService: AuthService
    ) {
        this.timeZone = this.authService.timezoneGetter;
    }

    agInit(params: ICellRendererParams): void {
        this.isTotal = params.data.isTotal;
        if (params.value) {
            this.date = new Date(params.value);
        }
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
