import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { ToastService } from '@core/services/toast.service';
import { HttpService } from '@core/services/http.service';
import { environment } from '@env/environment';
import { encryptData } from '@core/utils';
import { timeZoneFormatter } from '@core/utilities';
import { IAgentDetail, IDeviceDetection, ILoginForm, IUser } from '@core/interfaces';
import { Methods } from '@core/enums';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public userData$: BehaviorSubject<IUser | IAgentDetail | null> = new BehaviorSubject<IUser | IAgentDetail | null>(null);
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!this.tokenGetter());
    public checkTokenSubscription$: Subscription;

    constructor(
        private http: HttpService,
        private cookieService: CookieService,
        private router: Router,
        private toastService: ToastService,
    ) {
        if (this.cookieService.get('token')) {
            this.isAuthenticated$.next(!!this.cookieService.get('token'));
        }

        if (this.cookieService.get('user')) {
            this.userData$.next(JSON.parse(this.cookieService.get('user')) ? JSON.parse(this.cookieService.get('user')) : null);
        }
    }

    public tokenGetter(): string | null {
        return this.cookieService.get('token');
    }

    public userGetter(): IUser | null {
        return (this.cookieService.get('user') && JSON.parse(this.cookieService.get('user'))) || null;
    }

    public get timezoneGetter(): string {
        const user = JSON.parse(this.cookieService.get('user'));
        return timeZoneFormatter(user.TimeZone);
    }

    public isAuthenticated(): boolean {
        return !!this.tokenGetter() && !!this.userGetter();
    }

    public updateUserDetail(detail: IUser): Observable<any> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_USER_DETAIL, detail);
    }

    public login(params: ILoginForm, reCaptcha?: string, DeviceDetectionApiData?: IDeviceDetection): Observable<any> {
        const loginData = {
            data: encryptData(params),
            reCaptcha,
            ...DeviceDetectionApiData
        };
        return this.http.request('post', environment.ApiUrl + Methods.LOGIN_USER, loginData);
    }

    public logout(): Observable<void> {
        return this.http.request('put', environment.ApiUrl + Methods.LOGOUT)
            .pipe(
                finalize(
                    () => this.unauthenticated(),
                )
            );
    }

    public unauthenticated(): void {
        this.cookieService.delete('token');
        this.cookieService.delete('user');
        this.cookieService.delete('permissions');
        this.userData$.next(null);
        this.router.navigate(['/login']);
        this.isAuthenticated$.next(false);
        this.checkTokenSubscription$.unsubscribe();
        this.checkTokenSubscription$ = null;
    }

    public healthCheck(): Observable<any> {
        return this.http.request('get', environment.ApiUrl + Methods.HEALTH_CHECK);
    }

    public getCurrentProfile(): void {
        this.http.request(
            'get',
            environment.ApiUrl + (environment.role === 'admin' ? Methods.GET_CURRENT_USER : Methods.GET_CURRENT_AGENT)
        )
            .pipe(
                tap(user => {
                    // TODO make logic global
                    const now = new Date();
                    const time = now.getTime();
                    const expireTime = time + 1000 * 86400;
                    now.setTime(expireTime);
                    this.userData$.next(user);
                    this.cookieService.set('user', JSON.stringify(user), now, '/');

                }),
                catchError((e: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', e?.error?.message);
                    return of(e);
                })
            )
            .subscribe();
    }

    public checkToken(): void {
        this.checkTokenSubscription$ = interval(1000 * 60)
            .pipe(switchMap(() => {
                return this.http.request('get', environment.ApiUrl + Methods.CHECK_TOKEN);
            })).subscribe();
    }

}
