import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IAgentPermissions, IAgentPermissionsInfo } from '@core/interfaces';
import { environment } from '@env/environment';

@Component({
  selector: 'app-permission-info',
  templateUrl: './permission-info.component.html',
  styleUrls: ['./permission-info.component.scss']
})
export class PermissionInfoComponent {
    @Input() agentPermissions: string[];
    @Input() agentPermissionsInfo: IAgentPermissionsInfo | IAgentPermissions;
    @Input() isDesktopView: boolean;

    public isAdmin: boolean = environment.role === 'admin';

    public isInProfilePage: boolean;

    constructor(private router: Router) {
        this.isInProfilePage = this.router.url.includes('my-profile');
    }
}
