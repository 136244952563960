import {Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, pairwise, takeUntil } from 'rxjs/operators';

import { AuthService, ConfigService, LanguageService, ThemeService } from '@core/services';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '@env/environment';
import { Router, RoutesRecognized } from '@angular/router';

// export const URL = {
//     previous: '',
//     current: ''
// };

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public isAuthenticated = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private authService: AuthService,
        private primengConfig: PrimeNGConfig,
        public languageService: LanguageService,
        private titleService: Title,
        private router: Router,
    ) {
        this.languageService.init();
        this.setTitle();
        // this.setUrls();
    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.subscribeToAuthChanges();
    }

    private subscribeToAuthChanges(): void {
        this.authService.isAuthenticated$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.isAuthenticated = val;
                if (this.isAuthenticated && !this.authService.checkTokenSubscription$) {
                    this.authService.checkToken();
                }
        });
    }

    private setTitle(): void {
        this.titleService.setTitle(environment.role === 'admin' ? 'Agent Management' : 'Agent System');
    }

    // private setUrls(): void {
    //     this.router.events
    //         .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    //         .subscribe((events: RoutesRecognized[]) => {
    //             URL.previous = events[0].urlAfterRedirects;
    //             URL.current = events[1].urlAfterRedirects;
    //         });
    // }
}
