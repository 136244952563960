<div *ngIf="agentDetail"
     class="inner-page-main-section"
     [ngClass]="{'m-inner-page-main-section' : !isDesktopView}">
    <form [formGroup]="agentDetailForm">
                <div class="p-fluid p-formgrid grid form">
                    <div class="col-12 md:col-6 field-section">
                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name" for="FirstName">{{ 'FirstName' | translate }}</label>
                                <div class="input-error-section">
                                    <input pInputText id="FirstName"
                                           type="text"
                                           formControlName="FirstName"
                                           [ngClass]="{'ng-touched ng-invalid' :
                                           (agentDetailForm.controls.FirstName.touched)
                                           && agentDetailForm.controls.FirstName.invalid}"
                                    />
                                    <small *ngIf="agentDetailForm.controls.FirstName.touched
                                    && agentDetailForm.controls.FirstName.hasError('maxlength')"
                                           class="p-error">
                                        {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name" for="LastName">{{ 'LastName' | translate }}</label>
                                <div class="input-error-section">
                                    <input pInputText id="LastName"
                                           type="text"
                                           formControlName="LastName"
                                           [ngClass]="{'ng-touched ng-invalid' :
                                           agentDetailForm.controls.LastName.touched
                                            && agentDetailForm.controls.LastName.invalid}"/>
                                    <small *ngIf="agentDetailForm.controls.LastName.touched
                                    && agentDetailForm.controls.LastName.hasError('maxlength')"
                                           class="p-error">
                                        {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name" for="PhoneNumber">
                                    {{ 'PhoneNumber' | translate }}
                                </label>
                                <div class="input-error-section">
                                    <input pInputText
                                           id="PhoneNumber"
                                           type="tel"
                                           formControlName="PhoneNumber"
                                           [ngClass]="{'ng-touched ng-invalid' :
                                           agentDetailForm.controls.PhoneNumber.touched
                                            && agentDetailForm.controls.PhoneNumber.invalid}"/>
                                    <small *ngIf="agentDetailForm.controls.PhoneNumber.touched
                                    && agentDetailForm.controls.PhoneNumber.hasError('maxlength')"
                                           class="p-error">
                                        {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name" for="BankName">{{ 'BankName' | translate }}</label>
                                <div class="input-error-section">
                                    <input pInputText id="BankName"
                                           type="text"
                                           formControlName="BankName"
                                           [ngClass]="{'ng-touched ng-invalid' :
                                           agentDetailForm.controls.BankName.touched
                                            && agentDetailForm.controls.BankName.invalid}"/>
                                    <small *ngIf="agentDetailForm.controls.BankName.touched
                                    && agentDetailForm.controls.BankName.hasError('maxlength')"
                                           class="p-error">
                                        {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name" for="BankAccountNumber">
                                    {{ 'BankAccountNumber' | translate }}
                                </label>
                                <div class="input-error-section">
                                    <input pInputText id="BankAccountNumber"
                                           type="text"
                                           formControlName="BankAccountNumber"
                                           [ngClass]="{'ng-touched ng-invalid' :
                                           agentDetailForm.controls.BankAccountNumber.touched
                                            && agentDetailForm.controls.BankAccountNumber.invalid}"/>
                                    <small *ngIf="agentDetailForm.controls.BankAccountNumber.touched
                                    && agentDetailForm.controls.BankAccountNumber.hasError('maxlength')"
                                           class="p-error">
                                        {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 md:col-6 field-section">
                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name">{{ 'Status' | translate }}</label>
                                <app-status-view [statusDetail]="agentDetail.StatusDetail"
                                                 [translationPrefix]="'EnumAgentState'">
                                </app-status-view>
                            </div>
                        </div>

                        <div>
                            <div class="field">
                                <label class="field-label-name">{{ 'ParentID' | translate }}</label>
                                <div class="field-value">
                                    {{ agentDetail.ParentId ? agentDetail.ParentId : '-' }}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field">
                                <label class="field-label-name">
                                    {{ 'ParentUsername' | translate }}
                                </label>
                                <div class="field-value">
                                    {{ agentDetail.ParentUserName ? agentDetail.ParentUserName : '-' }}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <div class="field-label-name">{{ 'Role' | translate }}</div>

                                <div class="field-value">{{ agentDetail.Role ?
                                    agentDetail.Role : '-' }}</div>
                            </div>
                        </div>

                        <div>
                            <div class="field editable-field">
                                <label class="field-label-name">{{ 'TimeZone' | translate }} </label>
                                <div class="input-error-section">
                                    <app-time-zone-container
                                        [timeZoneOffset]="agentDetailForm.controls.TimeZone.value"
                                        (updateTimeZone)="onUpdateTimeZone($event)">
                                    </app-time-zone-container>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    </form>
</div>
