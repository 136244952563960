import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-partner-name-component',
    template: `
        <span *ngIf="!params.data.IsTotal">{{ params.data.PartnerName }}</span>
    `
})
export class PartnerNameRendererComponent implements ICellRendererAngularComp {

    public params: ICellRendererParams;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.getCellParams(params);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    private getCellParams(params: ICellRendererParams): void {
        params.data.partners
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                    this.params.data.PartnerName = res.find(item => item.Value === params.data.PartnerId)?.Name;
            });
    }
}
