import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import '@angular/compiler';
import { environment } from '@env/environment';
import { LicenseManager } from 'ag-grid-enterprise';

if (environment.production) {
  enableProdMode();
}
LicenseManager.setLicenseKey(environment.AGGridLicence);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
