import { AbstractControl, ValidatorFn } from '@angular/forms';

export function OnlyWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value && control.value.trim() === '' ?
            { onlyWhitespace: true } : null;
}

export function WhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        (control.value as string).indexOf(' ') >= 0 ?
            { Whitespace: true } : null;
}
