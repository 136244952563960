import { Component } from '@angular/core';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styles: [
        `.exception-body {
            min-height: calc(100vh - 69px);
        }
        `
    ]
})
export class AccessDeniedComponent {

    constructor() {
    }

}
