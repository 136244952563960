<div class="table-wrapper table-wrapper-full-page-height">
    <app-filters
        (selectedFilters)="getSelectedFilters($event)"
        [showStates]="false"
        [showCurrency]="false"
        [showPartner]="false"
        [showPeriod]="true">
    </app-filters>
    <app-ag-table
        [name]="tableName"
        [columnDefs]="columnDefs"
        [domLayout]="'normal'"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [dataSource]="dataSource"
        [serverSideStoreType]="serverSideStoreType"
        [rowModelType]="rowModelType"
        [pinnedBottomRowData]="pinnedBottomRowData"
    ></app-ag-table>
</div>
