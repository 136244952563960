import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartialObserver, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import * as _ from 'lodash';

import {
    AgentsService,
    AuthService,
    PermissionsService,
    ToastService,
} from '@core/services';
import { IAgentPermissionsForm, IAgentPermissionsInfo } from '@core/interfaces';
import { environment } from '@env/environment';
import { AdminUsersService } from '@pages/admin-users';


@Component({
    selector: 'app-permission-management',
    templateUrl: './permission-management.component.html',
    styleUrls: ['./permission-management.component.scss']
})
export class PermissionManagementComponent implements OnInit {
    public isAdmin: boolean = environment.role === 'admin';
    public isDesktopView: boolean = window.innerWidth > 620;
    public isEditMode: boolean = false;
    public agentId: number;
    public agentPermissionsInfo: IAgentPermissionsInfo;
    public agentPermissions: string[];
    public agentPermissionsOldValue: IAgentPermissionsInfo;
    public permissionManagementForm: FormGroup = new FormGroup<Partial<IAgentPermissionsForm>>({
        AgentAdd: new FormControl(false),
        AgentBlock: new FormControl(false),
        AgentChangePassword: new FormControl(false),
        DepositToAgent: new FormControl(false),
        DepositToPlayer: new FormControl(false),
        PlayerAdd: new FormControl(false),
        PlayerBlock: new FormControl(false),
        PlayerChangePassword: new FormControl(false),
        WithdrawalFromAgent: new FormControl(false),
        WithdrawalFromPlayer: new FormControl(false),
        AgentIndirectTransfer: new FormControl(false),
        PlayerIndirectTransfer: new FormControl(false),
    });
    private isPermissionManagementSame: boolean = false;

    public pending: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private agentsService: AgentsService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private authService: AuthService,
        private adminUsersService: AdminUsersService,
        public permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.getTemplatesByAgentId();
        this.followFormValueChanges();
    }

    private getTemplatesByAgentId(): void {
        this.agentId = this.route.parent.snapshot.params.id;
        this.agentsService.getPermissionsByAgentId(this.agentId ? this.agentId : this.authService.userData$.getValue().Id, this.isAdmin)
            .pipe(take(1))
            .subscribe({
                next: (res: IAgentPermissionsInfo) => {
                    this.agentPermissionsInfo = res;
                    /** @desc Keeping permission names in an array to use them for differing permissions after.  */
                    if (!this.isAdmin) {
                        this.agentPermissions = Object.keys(res.Permissions);
                    }
                },
                error: (err) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public enableEditMode(): void {
        this.isEditMode = true;
        this.permissionManagementForm.reset();
        this.permissionManagementForm.patchValue({ ...this.agentPermissionsInfo, ...this.agentPermissionsInfo.Permissions });
    }

    public onCancel(): void {
        this.isEditMode = false;
    }

    public onSaveChanges(): void {
        if (this.permissionManagementForm.valid) {
            if (!this.isPermissionManagementSame) {
                this.editAgentPermissions();
            } else {
                this.isEditMode = false;
            }
        } else {
            this.permissionManagementForm.markAllAsTouched();
        }
    }

    private editAgentPermissions(): void {
        let payload: any;

        if (!this.isAdmin) {
            payload = {
                Id: this.agentPermissionsInfo.Id,
                AgentId: this.agentPermissionsInfo.AgentId,
                PermissionTemplateId: this.agentPermissionsInfo.PermissionTemplateId,
                Permissions: this.permissionManagementForm.value
            };
        } else {
            payload = {
                Id: this.agentPermissionsInfo.Id,
                AgentId: this.agentPermissionsInfo.AgentId,
                PermissionTemplateId: this.agentPermissionsInfo.PermissionTemplateId,
                ...this.permissionManagementForm.value
            };
        }

        this.pending = true;

        if (this.isAdmin) {
            this.adminUsersService.editAgentPermissions(payload)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => {
                        this.pending = false;
                        this.getTemplatesByAgentId();
                    })
                )
                .subscribe(this.insertEditAgentPermissionsObserver());
        } else {
            this.agentsService.editAgentPermissions(payload)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => {
                        this.pending = false;
                        this.getTemplatesByAgentId();
                    })
                )
                .subscribe(this.insertEditAgentPermissionsObserver());
        }
    }

    private insertEditAgentPermissionsObserver(): PartialObserver<IAgentPermissionsInfo> {
        return {
            next: () => {
                this.agentPermissionsInfo = { ...this.agentPermissionsInfo, ...this.permissionManagementForm.value };
                this.agentPermissionsOldValue = this.permissionManagementForm.value;

                this.isEditMode = false;
                this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'AgentPermissionList');
            },
            error: (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            }
        };
    }


    private followFormValueChanges(): void {
        this.permissionManagementForm.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res && this.permissionManagementForm.value) {
                    if (!this.agentPermissionsOldValue) {
                        this.agentPermissionsOldValue = { ...this.permissionManagementForm.value };
                    }
                    this.isPermissionManagementSame = _.isEqual(this.agentPermissionsOldValue, res);
                }
            });
    }
}
