import { Component } from '@angular/core';
import { AppMainComponent } from '@core/components';

@Component({
    selector: 'app-rightmenu',
    styleUrls: ['./app.rightmenu.component.scss'],
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightmenuComponent {

    constructor(public appMain: AppMainComponent) {

    }
}
