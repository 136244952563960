import { Component, Input, OnInit } from '@angular/core';

import { RolesService } from '@core/services';
import { IAdminUser } from '@core/interfaces';

@Component({
    selector: 'app-admin-user-info',
    templateUrl: './admin-user-info.component.html',
    styleUrls: ['./admin-user-info.component.scss']
})
export class AdminUserInfoComponent implements OnInit {
  
    @Input() adminUserDetail: IAdminUser;
    @Input() isDesktopView: boolean;

    constructor(
        public rolesService: RolesService,
    ) {
    }

    ngOnInit(): void {
    }

}
