import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-decimal-up-down-container',
    templateUrl: './decimal-up-down-container.component.html',
    styleUrls: ['./decimal-up-down-container.component.scss']
})
export class DecimalUpDownContainerComponent implements OnInit {

    @Input() number: number | string;
    @Output() updateNumber: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    public plusMinus(type: string): void {
        if (type === 'plus') {
            if (+this.number <= 99) {
                this.number = +this.number + 1;
            } else {
                return;
            }
        } else {
            if (Math.floor(+this.number) !== 0) {
                this.number = +this.number - 1;
            } else {
                return;
            }
        }
        this.updateNumber.emit(+(this.number.toFixed(2)));
    }

}
