import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public translationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private translateService: TranslateService,
    ) {
    }

    init(): void {
        const lang = localStorage.getItem('lang') || 'en';
        this.translateService.setDefaultLang(lang);
        this.setLanguage(lang);
    }

    public setLanguage(language: string): void {
        this.translateService.setDefaultLang(language);
        this.translateService.use(language)
            .subscribe(() => this.translationLoaded$.next(true));
        localStorage.setItem('lang', language);
    }

    public localizeTableHeader(translationKey: string): string {
        return this.translateService.instant(translationKey);
    }
}
