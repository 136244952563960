import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    CanActivateFn,
} from '@angular/router';

import { AuthService } from '@core/services';

const unAuthGuardHandler = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean => {
    const router = inject(Router);
    const authService = inject(AuthService);

    if (!authService.isAuthenticated$.getValue() || !authService.userData$.getValue()) {
        return true;
    }
    router.navigate(['/']);
    return false;
};

export const canActivateUnAuthGuard: CanActivateFn = unAuthGuardHandler;
