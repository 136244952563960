import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
    CellFocusedEvent,
    CellValueChangedEvent,
    ColDef,
    ColumnApi,
    FilterModifiedEvent,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams,
    GetDataPath,
    GetRowIdFunc,
    GetServerSideGroupKey,
    GridApi,
    GridReadyEvent,
    IDetailCellRendererParams,
    IServerSideDatasource,
    IsRowMaster,
    IsRowSelectable,
    IsServerSideGroup,
    MenuItemDef,
    MenuItemLeafDef,
    PaginationChangedEvent,
    RowClickedEvent,
    RowGroupOpenedEvent,
    SelectionChangedEvent,
    SideBarDef,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService, ThemeService } from '@core/services';

@Component({
    selector: 'app-ag-table',
    templateUrl: './ag-table.component.html',
    styleUrls: ['./ag-table.component.scss']
})
export class AgTableComponent implements OnDestroy {

    private gridColumnApi!: ColumnApi;
    private gridApi!: GridApi;

    /**
     *  Theme at this moment supports only 'alpine' and 'balham'
     */
    @Input() theme: string = 'alpine';
    @Input() name;
    @Input() detailCellRenderer;
    @Input() detailCellRendererParams: IDetailCellRendererParams;
    @Input() getRowHeight: any;
    @Input() getRowClass: any;
    @Input() getContextMenuItems: (params: GetContextMenuItemsParams) => (string | MenuItemLeafDef)[];
    @Input() getDetailCellContextMenuItems: (params: GetContextMenuItemsParams) => (string | MenuItemLeafDef)[];
    @Input() rowHeight: number = 40;
    @Input() rowSelection: 'single' | 'multiple' = null;
    @Input() masterDetail: boolean = false;
    @Input() detailRowAutoHeight: boolean = false;
    @Input() columnDefs: ColDef[];
    @Input() pinnedBottomRowData: any;
    @Input() suppressRowClickSelection: boolean = false;
    @Input() components: any;
    @Input() defaultColDef: ColDef = {
        flex: 1,
        floatingFilter: true,
        resizable: true,
    };
    @Input() rowData!: any[];
    @Input() pagination: boolean = false;
    @Input() paginationPageSize: number = 0;
    @Input() headerHeight: number;
    @Input() cacheBlockSize: number = 0;
    @Input() maxBlocksInCache: number | undefined = undefined;
    @Input() rowModelType: string;
    @Input() serverSideStoreType;
    @Input() dataSource: IServerSideDatasource;
    @Input() noRowsTemplate =
        ` <div class="flex justify-content-center align-items-center no-data-found">
             <i class="icon-no-data"></i> ${this.translateService.instant('NoDataFound')}
          </div>`;
    @Input() sideBar: SideBarDef | string | boolean | null = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 225,
                width: 225,
                maxWidth: 225,
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                }
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                minWidth: 225,
                width: 225,
                maxWidth: 225,
            },
        ],
        position: 'right',
        defaultToolPanel: null,
    };
    @Input() domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
    @Input() autoGroupColumnDef: ColDef;
    @Input() treeData: boolean;
    @Input() groupDefaultExpanded: number | string;
    @Input() getDataPath: GetDataPath | undefined;
    @Input() isServerSideGroup: IsServerSideGroup | undefined;
    @Input() getServerSideGroupKey: GetServerSideGroupKey | undefined;
    @Input() getRowId: GetRowIdFunc | undefined;
    @Input() isRowMaster: IsRowMaster | undefined;
    @Input() isRowSelectable: IsRowSelectable | undefined;
    @Input() suppressRowVirtualisation: boolean = false;

    @Output() selectionChanged: EventEmitter<GridApi> = new EventEmitter<GridApi>();
    @Output() rowClicked: EventEmitter<RowClickedEvent> = new EventEmitter<RowClickedEvent>();
    @Output() gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();
    @Output() paginationChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowGroupOpened: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellFocused: EventEmitter<CellFocusedEvent> = new EventEmitter<CellFocusedEvent>();
    @Output() firstDataRendered: EventEmitter<FirstDataRenderedEvent> = new EventEmitter<FirstDataRenderedEvent>();


    @ViewChild('agGrid', { static: false }) agGrid!: AgGridAngular;

    public localeTranslations: {
        [key: string]: string;
    };
    public translationsLoaded: boolean = false;
    private isFirstDataRendered: boolean = false;
    private timeout;
    private observer;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public configService: ConfigService,
        public themeService: ThemeService,
        private translateService: TranslateService,
    ) {
        this.getTranslationsForCurrentLanguage();
    }

    static isiIos(): boolean {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform);
    }

    ngOnDestroy(): void {
        if (this.observer) {
            this.observer.unobserve(document.getElementById('ag-table-wrapper'));
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private onObserveWidthChanges(): void {
        this.observer = new ResizeObserver(entries => {
            entries.forEach((entry) => {
                entry.contentRect.width && this.gridApi.sizeColumnsToFit();
            });
        });
        this.observer.observe(document.getElementById('ag-table-wrapper'));
    }

    onFirstDataRendered(params: FirstDataRenderedEvent): void {
        this.isFirstDataRendered = true;
        this.firstDataRendered.emit(params);
        // arbitrarily expand a row for presentational purposes
        // setTimeout( () => {
        //     // tslint:disable-next-line:no-non-null-assertion
        //     params.api.getDisplayedRowAtIndex(1)!.setExpanded(true);
        // }, 0);
    }

    onGridReady(params: GridReadyEvent): void {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        if (this.dataSource) {
            // tslint:disable-next-line:no-non-null-assertion
            params.api!.setServerSideDatasource(this.dataSource);
        }
        // console.log('TABLE this.gridApi', this.gridApi);
        this.subscribeToLangChanges(params);
        this.gridReady.emit(params);

        this.onObserveWidthChanges();

        // Set default context menu for expended table
        if (this.detailCellRendererParams && this.detailCellRendererParams.detailGridOptions) {
            this.detailCellRendererParams.detailGridOptions.getContextMenuItems = (contextMenuParams: GetContextMenuItemsParams) => {
                return this.getDetailCellContextMenuItems
                    ? [ ...this.getDetailCellContextMenuItems(contextMenuParams), 'separator', 'copy', 'export' ]
                    : [ 'copy', 'export' ];
            };
        }
    }

    onCellClicked(event): void {
        // console.log('onCellClicked---', event);
    }

    onRowClicked(event: RowClickedEvent): void {
        this.rowClicked.emit(event);
    }

    onGridSizeChanged(params): void {
        // for fit 100%
        // params.api.sizeColumnsToFit();
    }

    public saveColumnDefs(): void {
        if (this.name && this.gridColumnApi && this.isFirstDataRendered) {
            localStorage.setItem(`column-state_${this.name}`, JSON.stringify(this.gridColumnApi?.getColumnState()));
        }
    }

    private subscribeToLangChanges(params): void {
        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                params.api.refreshHeader();
                this.translationsLoaded = false;
                this.getTranslationsForCurrentLanguage();
            });
    }

    private getTranslationsForCurrentLanguage(): void {
        this.translateService.getTranslation(this.translateService.getDefaultLang())
            .pipe(take(1))
            .subscribe(res => {
                this.localeTranslations = res.AgGridLocale;
                this.translationsLoaded = true;
            });
    }

    onSelectionChanged($event: SelectionChangedEvent): void {
        this.selectionChanged.emit(this.gridApi);
    }

    onPaginationChanged($event: PaginationChangedEvent): void {
        /**
         * for client side pagination IOS issue
         */
        if (AgTableComponent.isiIos() && this.isFirstDataRendered) {
            clearTimeout(this.timeout);
            (document.querySelector('.ag-body-viewport') as HTMLElement).style.overflowY = 'hidden';
            this.timeout = setTimeout(() => {
                (document.querySelector('.ag-body-viewport') as HTMLElement).style.overflowY = 'auto';
                document.querySelector('.ag-body-viewport').scroll(0, 0);
            }, 0);
        }

        this.paginationChanged.emit($event);
    }

    public onCellValueChanged($event: CellValueChangedEvent): void {
        this.cellValueChanged.emit($event);
    }

    public onRowGroupOpened($event: RowGroupOpenedEvent): void {
        this.rowGroupOpened.emit($event);
    }

    public onCellFocused($event: CellFocusedEvent): void {
        this.cellFocused.emit($event);
    }

    public getContextMenuItemsFunction = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
        return this.getContextMenuItems && this.getContextMenuItems(params).length
            ? [ ...this.getContextMenuItems(params), 'separator', 'copy', 'export' ]
            : [ 'copy', 'export' ];
    }

    public onFilterModified($event: FilterModifiedEvent): void {
        const filterModel = $event.filterInstance.getModel();

        if (filterModel?.filterType === 'number' && isNaN(filterModel?.filter)) {
            $event.filterInstance.setModel(null);
            $event.api.onFilterChanged();
        }
    }
}
