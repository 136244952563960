<form [formGroup]="createAgentForm">

    <div class="p-fluid p-formgrid grid form mt-0">

        <div class="field col-12 md:col-6" *ngIf="isAdmin">
            <label for="partnerId">{{ 'Partner' | translate }} *</label>
            <p-dropdown [options]="partners"
                        [panelStyleClass]="'dialog-dropdown'"
                        appendTo="body"
                        formControlName="PartnerId"
                        optionLabel="Name"
                        optionValue="Value"
                        id="partnerId"
                        (onChange)="partnerSelected($event)"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.PartnerId.touched
                                    && createAgentForm.controls.PartnerId.invalid}">
            </p-dropdown>
            <small
                *ngIf="createAgentForm.controls.PartnerId.touched
                               && createAgentForm.controls.PartnerId.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6" *ngIf="isAdmin">
            <label for="currency">{{ 'Currency' | translate }} *</label>
            <p-dropdown [options]="currencies"
                        [panelStyleClass]="'dialog-dropdown'"
                        appendTo="body"
                        formControlName="Currency"
                        optionLabel="Name"
                        optionValue="Name"
                        id="currency"
                        [readonly]="createAgentForm.controls.ParentId.value"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.Currency.touched
                                    && createAgentForm.controls.Currency.invalid,
                                    'readonly' : createAgentForm.controls.ParentId.value}">
            </p-dropdown>
            <small
                *ngIf="createAgentForm.controls.Currency.touched
                               && createAgentForm.controls.Currency.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="parentId">{{ 'Parent' | translate }}</label>
            <p-dropdown formControlName="ParentId"
                        [panelStyleClass]="'dialog-dropdown'"
                        [options]="parents"
                        (onFilter)="onParentFilterChange$.next($event.filter)"
                        (onChange)="parentSelected($event)"
                        [showClear]="true"
                        [placeholder]="'Select' | translate"
                        optionLabel="UserName"
                        [filter]="true"
                        filterBy="UserName"
                        appendTo="body"
                        id="parentId"
                        [readonly]="!createAgentForm.controls?.PartnerId?.value && isAdmin"
                        [ngClass]="{'readonly' : !createAgentForm.controls?.PartnerId?.value && isAdmin}">
                <ng-template let-selectedParent pTemplate="selectedItem">
                    <span>{{ selectedParent.UserName }} ( {{ selectedParent.Role }} )</span>
                </ng-template>
                <ng-template let-parent pTemplate="item">
                    <span>{{ parent.UserName }} ( {{ parent.Role }} )</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12 md:col-6">
            <label for="username">{{ 'Username' | translate }} *</label>
            <input pInputText id="username" type="text" formControlName="UserName" autocomplete="off"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.UserName.touched
                            && createAgentForm.controls.UserName.invalid}"/>
            <small *ngIf="createAgentForm.controls.UserName.touched
                       && createAgentForm.controls.UserName.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small *ngIf="createAgentForm.controls.UserName.touched
                       && createAgentForm.controls.UserName.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
            <small *ngIf="createAgentForm.controls.UserName.touched
                                    && createAgentForm.controls.UserName.hasError('Whitespace')"
                   class="p-error">{{ 'WhitespaceErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="role">{{ 'Role' | translate }} *</label>
            <p-dropdown [options]="roles"
                        [panelStyleClass]="'dialog-dropdown'"
                        appendTo="body"
                        formControlName="RoleId"
                        optionLabel="TemplateName"
                        optionValue="Id"
                        id="role"
                        [placeholder]="'Select' | translate"
                        [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.RoleId.touched
                                    && createAgentForm.controls.RoleId.invalid}">
            </p-dropdown>
            <small
                *ngIf="createAgentForm.controls.RoleId.touched
                               && createAgentForm.controls.RoleId.hasError('required')"
                class="p-error">
                {{ 'FieldRequiredErrorMessage' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="email">{{ 'Email' | translate }}</label>
            <input pInputText id="email" type="text" formControlName="Email"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.Email.touched
                            && createAgentForm.controls.Email.invalid}"/>
            <small *ngIf="createAgentForm.controls.Email.touched
                       && createAgentForm.controls.Email.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="password">{{ 'Password' | translate }} *</label>
            <input pInputText id="password" type="password" formControlName="Password" autocomplete="new-password"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.Password.touched
                            && createAgentForm.controls.Password.invalid}"/>
            <small *ngIf="createAgentForm.controls.Password.touched
                       && createAgentForm.controls.Password.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small *ngIf="createAgentForm.controls.Password.touched
                       && createAgentForm.controls.Password.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="confirmPassword">{{ 'ConfirmPassword' | translate }} *</label>
            <input pInputText id="confirmPassword" type="password" formControlName="ConfirmPassword" autocomplete="off"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.ConfirmPassword.touched
                            && createAgentForm.controls.ConfirmPassword.invalid}"/>
            <small *ngIf="createAgentForm.controls.ConfirmPassword.touched
                       && createAgentForm.controls.ConfirmPassword.hasError('required')"
                   class="p-error">{{ 'FieldRequiredErrorMessage' | translate }}
            </small>
            <small *ngIf="createAgentForm.controls.ConfirmPassword.touched
                       && createAgentForm.controls.ConfirmPassword.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
            <small *ngIf="createAgentForm.controls.ConfirmPassword.touched &&
                       !createAgentForm.controls.ConfirmPassword.hasError('required')
                       && createAgentForm.hasError('passwordMatch')"
                   class="p-error">{{ 'PasswordsMatchError' | translate }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="name">{{ 'FirstName' | translate }}</label>
            <input pInputText id="name" type="text" formControlName="FirstName"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.FirstName.touched
                            && createAgentForm.controls.FirstName.invalid}"/>
            <small *ngIf="createAgentForm.controls.FirstName.touched
                       && createAgentForm.controls.FirstName.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="lastname">{{ 'LastName' | translate }}</label>
            <input pInputText id="lastname" type="text" formControlName="LastName"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.LastName.touched
                            && createAgentForm.controls.LastName.invalid}"/>
            <small *ngIf="createAgentForm.controls.LastName.touched
                       && createAgentForm.controls.LastName.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label for="phoneNumber">{{ 'PhoneNumber' | translate }}</label>
            <input pInputText id="phoneNumber" type="text" formControlName="PhoneNumber"
                   [ngClass]="{'ng-touched ng-invalid' : createAgentForm.controls.PhoneNumber.touched
                            && createAgentForm.controls.PhoneNumber.invalid}"/>
            <small *ngIf="createAgentForm.controls.PhoneNumber.touched
                       && createAgentForm.controls.PhoneNumber.hasError('maxlength')"
                   class="p-error">
                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
            </small>
        </div>

        <div class="field col-12 md:col-6">
            <label class="field-label-name">{{ 'TimeZone' | translate }} </label>
            <div class="input-error-section">
                <app-time-zone-container
                    [timeZoneOffset]="timeZoneOffset"
                    (updateTimeZone)="onUpdateTimeZone($event)">
                </app-time-zone-container>
            </div>
        </div>

    </div>

    <div class="p-dialog-footer">
        <button
            type="button"
            pButton
            pRipple
            [label]="'Cancel' | translate"
            (click)="onCancel()"
            icon="pi pi-times"
            class="p-button-text p-button-secondary"
        ></button>
        <button
            type="button"
            pButton
            pRipple
            [loading]="pending"
            [label]="'Save' | translate"
            (click)="onCreateAgent()"
            icon="pi pi-check"
            class="p-button-text"
        ></button>
    </div>

</form>
