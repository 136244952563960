import { TranslateService } from '@ngx-translate/core';

export function dateFormatter(params): string {
    const date = params.value ? new Date(params.value) : new Date(params);

    if (params.value === undefined) {
        return '';
    }

    return (
        (params.value !== null) ?
            ([
            String(date.getDate()).padStart(2, '0'),
            String(date.getMonth() + 1).padStart(2, '0'),
            date.getFullYear(),
        ].join('.') +
        ' ' +
        [
            String(date.getHours()).padStart(2, '0'),
            String(date.getMinutes()).padStart(2, '0'),
            String(date.getSeconds()).padStart(2, '0'),
        ].join(':')) : '-'
    );
}

export function dataFormatter(data, params): string {
    return data.getValue().find(st => st.Id === +params.value)?.Name;
}

export function dataFormatterByValue(data, params): string {
    return data.getValue().find(st => st.Value === +params.value)?.Name;
}

export function languageFormatter(data, params): string {
    return data.getValue().languages.find(st => st.Id === params.value)?.Name;
}

export function transformFilter(translateService: TranslateService, params): string {
    return translateService.instant(params.value);
}

export function decimalFormatter(points, params): string {
    if ( isNaN(params.value) ) {
        return null;
    }
    const decimalVal = new Intl.NumberFormat('en', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: points,
        maximumFractionDigits: 20
    }).format(params.value);

    const strVal = decimalVal.toString();
    if (strVal.indexOf('.') === -1) {
        return decimalVal;
    }
    return strVal.slice(0, (strVal.indexOf('.')) + (points ? (points + 1) : 0));
}

export function decimalFormatterByPoints(points, value): string {
    if ( isNaN(value) ) {
        return null;
    }
    const decimalVal = new Intl.NumberFormat('en', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: points,
        maximumFractionDigits: 20
    }).format(value);

    const strVal = decimalVal.toString();
    if (strVal.indexOf('.') === -1) {
        return decimalVal;
    }
    return strVal.slice(0, (strVal.indexOf('.')) + (points ? (points + 1) : 0));
}
