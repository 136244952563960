<div class="time-zone-container" *ngIf="!onlyTimeZoneView; else showOnlyTimeZone">
    <div class="time-zone-text">
        <span>{{ now | date:'HH:mm:ss' : formattedTimeZoneOffset }}</span>
        <span>{{ ' ' + formattedTimeZoneOffset }}</span>
    </div>
    <div class="up-down-buttons-container">
        <div class="arrow" (click)="GMTPlusMinus('plus')">
            <i class="pi icon icon-arrow_up_filled icon-size-md cursor-pointer"></i>
        </div>
        <div class="arrow" (click)="GMTPlusMinus('minus')">
            <i class="pi icon icon-arrow_down_filled icon-size-md cursor-pointer"></i>
        </div>
    </div>
</div>

<ng-template #showOnlyTimeZone>
    <span>{{ 'GMT ' + formattedTimeZoneOffset }}</span>
</ng-template>
