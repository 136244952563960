import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService, UpdateStorageService } from '@core/services';
import { AdminUserStatesEnum, Methods } from '@core/enums';
import { environment } from '@env/environment';
import {
    IAdminUser,
    IEnum,
    IAdminUserPermission,
    IAdminUserPartner,
    IResponseWithCount,
    IAgentPermissionsInfo,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class AdminUsersService {

    public adminUserStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public adminUserInfo$: BehaviorSubject<IAdminUser> = new BehaviorSubject<IAdminUser>(null);

    constructor(
        private http: HttpService,
        private updateStorageService: UpdateStorageService,
    ) {
        this.subscribeToConfigVersionChanges();
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .subscribe({
                next: () => this.loadAdminUserState()
            });
    }

    public loadAdminUserState(): void {
        if (localStorage.getItem('enum_admin-user-states')) {
            this.adminUserStates$.next(JSON.parse(localStorage.getItem('enum_admin-user-states')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_USER_STATES_ENUM)
                .subscribe({
                    next: (res: IEnum[]) => {
                        res.forEach((status: IEnum) => {
                            status.Background = status.Value === AdminUserStatesEnum.Active ? '#79F2B8' :
                                status.Value === AdminUserStatesEnum.Inactive ? '#EE6464' : '#E3E3E8';
                            status.Color = (status.Value === AdminUserStatesEnum.Active ||
                                status.Value === AdminUserStatesEnum.Inactive) ? '#3F3F3F' : '#B3B3BC';
                        });
                        this.adminUserStates$.next(res);
                        localStorage.setItem('enum_admin-user-states', JSON.stringify(res));
                    }
                });
        }
    }

    public getAdminUsers(payload: any): Observable<IResponseWithCount<IAdminUser>> {
        return this.http.request('post', environment.ApiUrl + Methods.GET_ADMIN_USERS, payload);
    }

    public createAdminUser(payload: any): Observable<IAdminUser> {
        return this.http.request('post', environment.ApiUrl + Methods.CREATE_ADMIN_USER, payload);
    }

    public changeStatus(payload: { [key: string]: string | number }): Observable<void> {
        return this.http.request('put', environment.ApiUrl + Methods.ADMIN_USER_CHANGE_STATUS, payload);
    }

    public getAdminUserById(userId: number): void {
        this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_ADMIN_USER_BY_ID,
            null,
            false,
            { params: { userId } }
        )
            .pipe(map(res => this.adminUserInfo$.next(res)))
            .subscribe();
    }

    public changePassword(payload: any): Observable<void> {
        return this.http.request('put', environment.ApiUrl + Methods.ADMIN_USER_CHANGE_PASSWORD, payload);
    }

    public changeAdminUserDetails(payload: any): Observable<void> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_ADMIN_USER_DETAIL, payload);
    }

    public getPermissions(params: any): Observable<IAdminUserPermission[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PERMISSIONS_BY_ADMIN_USER_ID,
            null,
            false,
            { params }
        );
    }

    public getPartnersByUserId(params: any): Observable<IAdminUserPartner[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PARTNERS_BY_ADMIN_USER_ID,
            null,
            false,
            { params }
        );
    }

    public addPartnerToUser(payload: any): Observable<IAdminUserPartner[]> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_PARTNER_TO_ADMIN_USER,
            payload,
            false,
            null,
        );
    }

    public removePartnerFromUser(payload: any): Observable<IAdminUserPartner[]> {
        return this.http.request(
            'delete',
            environment.ApiUrl + Methods.REMOVE_PARTNER_FROM_ADMIN_USER,
            null,
            false,
            { body: payload }
        );
    }

    public editAgentPermissions(permissionList: IAgentPermissionsInfo): Observable<IAgentPermissionsInfo> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_ADMIN_PERMISSION, permissionList);
    }

    /**
     * This function helps us to disable rows of the table. It makes two actions, when 'checkForDuplicates' param is true, it takes arr1,
     * checks it for available 'searchKey' in arr2 and disables them in its own data, when 'checkForDuplicates' param is false it only
     * disables 'searchKey'-s which aren't available at arr2.
     * In arr1 = [...arr1] we used 'spread' operator, because in some cases we have function/Observable as a value, and it causes an error
     * when we use structuredClone()
     */
    public disableSelectableRows(arr1: any[], arr2: any[], searchKey: string, checkForDuplicates: boolean): any[] {
        arr1 = [...arr1];
        arr1.forEach(elem => {
            const foundPartner = arr2.find(item => item[searchKey] === elem[searchKey]);
            elem.Disabled = checkForDuplicates ? !!foundPartner : !foundPartner;
        });
        return arr1;
    }

}
