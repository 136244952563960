import { Component } from '@angular/core';

import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-link-cell-component',
    template: `
        <ng-container *ngIf="!params.data.isTotal; else total">
            <ng-container *ngIf="externalUrl; else routerLinkNavigation">
                <a [href]="link" class="link" [target]="target" rel="noopener">{{ text }}</a>
            </ng-container>
            <ng-template #routerLinkNavigation>
                <a [routerLink]="link" class="link" [target]="target">{{ text }}</a>
            </ng-template>
        </ng-container>
        <ng-template #total>
            <div>{{ params.data[params.colDef.field] ? params.data[params.colDef.field] : '' }}</div>
        </ng-template>
    `
})
export class LinkCellComponent implements ICellRendererAngularComp {

    public link: string;
    public text: string;
    public target: string;
    public params: ICellRendererParams;
    public externalUrl: boolean;

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        this.refresh(params);
    }

    refresh(params: ICellRendererParams): boolean {
        const dataParams = params.colDef.cellRendererParams;
        this.params = params;
        this.link = _.isFunction(dataParams.link) ? dataParams.link(params) : dataParams.link;
        this.text = _.isFunction(dataParams.text) ? dataParams.text(params) : dataParams.text;
        this.target = dataParams.target ? dataParams.target : '_self';
        this.externalUrl = dataParams.externalUrl;
        return false;
    }
}
