import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, retry, Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';

import { AuthService } from '@core/services/auth.service';

import { IWssPayload, IWssResponse } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})

export class WssService {

    private webSocket$: WebSocketSubject<any>;
    private retryCount: number = 5;
    private retryMillisecond: number = 1000;

    public wssIsAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public activeSocketsStorage: any[] = [];
    public taskNotifications$: Subject<IWssResponse> = new Subject();
    private intervalId: number = undefined;

    constructor(
        private authService: AuthService,
    ) {
    }

    public get ridGeneratorGetter(): string {
        return crypto.randomUUID();
    }

    public initWebSocket(): void {
        const webSocketOpened$: Subject<any> = new Subject();
        const webSocketClosed$: Subject<any> = new Subject();
        this.webSocket$ = webSocket<string>({
            url: 'wss:///api.blue-version.com/api/agentnotifications/notifications/get?systemid=1',
            openObserver: webSocketOpened$,
            closeObserver: webSocketClosed$,
        });
        webSocketOpened$
            .subscribe({
                next: () => {
                    console.log('wss connection was established ...!!!');
                    const socketAuthData: any = {
                        cmd: 'CheckToken',
                        rId: this.ridGeneratorGetter,
                        token: this.authService.tokenGetter(),
                    };
                    this.activeSocketsStorage.push(socketAuthData);
                    this.webSocket$.next(socketAuthData);
                }
            });
        webSocketClosed$.subscribe({
            next: () => {
                this.activeSocketsStorage.shift();
                console.log('wss connection was closed ...!!!');
            }
        });
        this.wssSubscribe();
    }

    private wssSubscribe(): void {
        this.webSocket$
            .pipe(
                retry({
                    count: this.wssIsAuth$.getValue() ? this.retryCount : 0,
                    delay: this.retryMillisecond,
                    resetOnSuccess: true
                }),
            )
            .subscribe({
                next: (res: IWssResponse) => {
                    if (res.data?.hasOwnProperty('PingTimeoutInterval')) {
                        this.intervalId = this.pingTimeoutInterval(res.data.PingTimeoutInterval * 1000);
                    }
                    /** @desc Condition handling the authorization */
                    if (this.activeSocketsStorage[0].rId === res.rId) {
                        if (res.success) {
                            this.wssIsAuth$.next(true);
                        }
                    }
                    /** @desc Condition for handling responses */
                    else if (res.sId) {
                        const sIdIndex: number = this.activeSocketsStorage.findIndex(elem => elem.sId === res.sId);
                        if (sIdIndex >= 0) {
                            this.activeSocketsStorage[sIdIndex].subject.next(res);
                        } else {
                            const rIdIndex: number = this.activeSocketsStorage.findIndex(elem => elem.rId === res.rId);
                            this.activeSocketsStorage[rIdIndex].sId = res.sId;
                            this.activeSocketsStorage[rIdIndex].subject.next(res);
                        }
                    }
                },
                error: (err: HttpErrorResponse) => {
                    console.log(err);
                }
            });
    }

    private pingTimeoutInterval(pingTimeoutInterval: number): number {
        const payload: IWssPayload = {
            cmd: 'Ping'
        };
        return setInterval(() => {
            this.webSocket$.next(payload);
        }, pingTimeoutInterval);
    }

    public send(rId: string, subType: string, subject: Subject<any>): void {
        const payload: IWssPayload = {
            cmd: 'Get',
            rId,
            subType
        };
        this.webSocket$.next(payload);
        const activeSocket: any = {
            rId,
            sId: undefined,
            subject
        };
        this.activeSocketsStorage.push(activeSocket);
    }


    public drop(rIds: string[]): void {
        const payload: any = {
            cmd: 'Drop',
            rId: undefined,
        };
        rIds.forEach((rId: string) => {
            payload.rId = rId;
            this.webSocket$.next(payload);
            const ridIndex: number = this.activeSocketsStorage.findIndex(elem => elem.rId === rId);
            this.activeSocketsStorage.splice(ridIndex, 1);
        });
    }

    public closeConnection(): void {
        clearInterval(this.intervalId);
        this.webSocket$.complete();
        this.wssIsAuth$.next(false);
    }

}
