import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, iif, of, skip, Subject } from 'rxjs';

import moment from 'moment';

import { AgentsService, AuthService, PartnersService, ToastService } from '@core/services';
import { AppMainComponent } from '@core/components';
import { IEnum, IFilter, IParent } from '@core/interfaces';
import { environment } from '@env/environment';

@Component({
    selector: 'app-filters',
    styleUrls: ['./app.filters.component.scss'],
    templateUrl: './app.filters.component.html'
})
export class AppFiltersComponent implements OnChanges, OnInit, OnDestroy {

    public isDesktop: boolean = window.innerWidth > 1092;
    public filtersSidebarActive: boolean = false;
    public filtersSidebarClick: boolean;

    @Input() showCurrency: boolean = true;
    @Input() showPartner: boolean = true;
    @Input() showPeriod: boolean = true;
    @Input() showPeriodLastYearAllowLongPeriod: boolean = false;
    @Input() showPlayerId: boolean = false;
    @Input() showStates: boolean = false;
    @Input() showBetDate: boolean = false;
    @Input() showParent: boolean = false;
    @Input() resetFilters: boolean = false;

    @Output() selectedFilters: EventEmitter<IFilter> = new EventEmitter<IFilter>();

    @ViewChild('op', { static: false }) op;

    @Input() selectedDateOption: string = 'Today';
    public valRadio: UntypedFormControl = new UntypedFormControl(this.selectedDateOption);
    public dates: UntypedFormControl = new UntypedFormControl([]);
    public from: Date = new Date();
    public to: Date = new Date();
    public max: Date;
    public tomorrow: Date = new Date();

    @Input() selectedPartnerOption: IEnum[] = [];
    public partners: IEnum[];

    @Input() selectedCurrencyOption: string = '';
    public currencies: any[] = [];

    @Input() selectedClientId: string = '';

    public states: Partial<IEnum>[];
    @Input() selectedStateOption: Partial<IEnum>;

    @Input() betDateStates: IEnum[];
    public selectedBetDateStateOption: number;

    @Input() selectedParentOption: number = null;
    public parents: IParent[];
    public onParentFilterChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public parentIdControl: UntypedFormControl = new UntypedFormControl(null);

    private selectedMonth: string;
    private selectedYear: string;
    public isSelectedMonthView: boolean = false;
    public isSelectedYearView: boolean = false;

    private dateTime;

    public dropdownToggle: boolean = false;
    /** Ass all the dropdowns use dropdownToggle, activeDropdown variable helps us to define,
     *  which dropdown is active and helps us for setting CSS styles for it
     */
    public activeDropdown: string = '';


    @Input() set statesFromParent(value) {
        this.states = value;
    }

    public filterForm: UntypedFormGroup;
    public overlayPanelToggle: boolean = false;
    public isAdmin: boolean = environment.role === 'admin';

    private unsubscribe$: Subject<void> = new Subject<void>();

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.isDesktop = event.target.innerWidth > 1092;
    }

    constructor(
        public appMain: AppMainComponent,
        private partnerService: PartnersService,
        private authService: AuthService,
        private toastService: ToastService,
        private agentService: AgentsService,
    ) {
        this.from.setHours(0, 0, 0);
        this.to.setHours(0, 0, 0);
        this.to.setDate(this.to.getDate() + 1);

        this.currencies = [
            { label: 'USD', value: 'us' },
            { label: 'IRT', value: 'ir' }
        ];
    }

    ngOnInit(): void {
        this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initForm();

        if (changes.showParent && changes.showParent.firstChange && this.showParent) {
            this.subscribeToParentChanges();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public applyOrCancelDateRange(isDateRangeSelected: boolean): void {
        if (isDateRangeSelected) {
            this.filterForm.controls.From.setValue(this.dates.value[0]);
            this.filterForm.controls.To.setValue(this.dates.value[1]);
            this.valRadio.setValue('Calendar');
        } else {
            this.dates.patchValue([this.filterForm.controls.From.value, this.filterForm.controls.To.value]);
        }
    }

    public onFilterMenuClick(): void {
        this.filtersSidebarClick = true;
        this.filtersSidebarActive = true;
    }

    public initForm(): void {
        this.filterForm = new UntypedFormGroup({});
        this.showCurrency && this.filterForm
            .addControl('Currency', new UntypedFormControl(this.selectedCurrencyOption));

        if (this.showPeriod) {
            this.filterForm.addControl('From', new UntypedFormControl(null));
            this.filterForm.addControl('To', new UntypedFormControl(null));
            this.formulateFilterBySelectedTimePeriod(this.selectedDateOption);
        }

        if (this.showPlayerId) {
            this.filterForm.addControl('ClientId', new UntypedFormControl(''));
        }

        if (this.showStates && this.selectedStateOption) {
            this.filterForm.addControl('Status', new UntypedFormControl(this.selectedStateOption.Value));
        }

        if (this.showBetDate && this.betDateStates) {
            this.filterForm.addControl('DateTimeFilterTypeId', new UntypedFormControl(this.betDateStates[0].Value));
            this.selectedBetDateStateOption = this.betDateStates[0].Value;
        }

        if (this.showParent) {
            this.filterForm.addControl('ParentId', new UntypedFormControl(this.selectedParentOption));
            this.filterForm.addControl('Id', new UntypedFormControl(null));
            this.onParentFilterChange$.next('');
        }

        if (this.showPartner) {
            this.partnerService.partners$
                .pipe(
                    // TODO Please check this part more detailed
                    // filter(p => !!p.length),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe((partners: IEnum[]) => {
                    this.partners = partners;
                    this.filterForm
                        .addControl('PartnerId', new UntypedFormControl(null));

                    if (this.partners.length === 1) {
                        this.filterForm.controls.PartnerId.setValue(this.partners[0].Value);
                    }
                });
        }

        this.apply();
    }

    public formulateFilterBySelectedTimePeriod(period): void {
        this.valRadio.setValue(period);

        const date = new Date();
        date.setHours(0, 0, 0);

        const previousTo = new Date();
        previousTo.setHours(0, 0, 0);
        previousTo.setDate(this.to.getDate() - this.from.getDate());

        const previousFrom = new Date();
        previousFrom.setHours(0, 0, 0);
        previousFrom.setDate(this.to.getDate() - this.from.getDate());

        switch (period) {

            case 'PreviousMonth':
                previousFrom.setMonth(previousFrom.getMonth() - 1);
                this.filterForm.controls.From.setValue(previousFrom);
                this.filterForm.controls.To.setValue(previousTo);
                break;
            case 'Last30Days':
                date.setDate(date.getDate() - 29);
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.to);
                break;
            case 'Last7Days':
                date.setDate(date.getDate() - 6);
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.to);
                break;
            case 'Yesterday':
                date.setDate(date.getDate() - 1);
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.from);
                break;
            case 'Today':
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.to);
                break;
            case 'CurrentMonth':
                date.setDate(1);
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.to);
                break;
            case 'Calendar':
                date.setDate(1);
                this.filterForm.controls.From.setValue(date);
                this.filterForm.controls.To.setValue(this.to);
                break;
        }

        this.dates.patchValue([this.filterForm.controls.From.value, this.filterForm.controls.To.value]);
    }

    /** Current function helps us to wait for partners in 'admin' role and only then to apply filter */
    private applyFilterBasedOnEnvironment(): void {
        if (!this.isAdmin) {
            this.apply();
        } else if (
            !this.showPartner ||
            (this.showPartner && this.filterForm.controls.PartnerId?.value)
        ) {
            this.apply();
        }
    }

    public apply(): void {
        if (this.showPeriod) {
            const From = moment(this.filterForm.value.From).clone().utcOffset(this.authService.timezoneGetter, true);
            const To = moment(this.filterForm.value.To).clone().utcOffset(this.authService.timezoneGetter, true);

            this.selectedFilters.emit({
                ...this.filterForm.value,
                From,
                To
            });
        } else {
            this.selectedFilters.emit(this.filterForm.value);
        }
    }

    public onCloseDateTimePicker(): void {
        document.querySelector('owl-date-time-container')
            .removeEventListener('click', event => event.stopPropagation());
        this.isSelectedYearView = false;
        this.isSelectedMonthView = false;
    }

    public setMaxDate(event): void {
        const oneDay = 24 * 60 * 60 * 1000;
        this.max = new Date(event.toDate().getTime() + (31 * oneDay));

        this.max = this.max >= this.tomorrow ? this.tomorrow : this.max;
    }

    public getParents(UserName: string): void {
        const payload: any = { UserName };

        this.isAdmin
            ? payload.PartnerId = this.filterForm.controls.PartnerId.value
            : payload.ParentId = this.authService.userGetter().Id;

        this.agentService.getAgentByUserName(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res) => {
                    this.parents = res;
                    const parentId = this.filterForm.controls.ParentId;
                    const id = this.filterForm.controls.Id;
                    if (!res.length || ((parentId.value || id.value) && !res.find(el => el.Id === id.value))) {
                        parentId.setValue(this.selectedParentOption);
                        id.setValue(null);
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    private subscribeToParentChanges(): void {
        this.onParentFilterChange$
            .pipe(
                switchMap(val => iif(() => !!val,
                    of(val).pipe(delay(700)),
                    of(val))
                ),
                takeUntil(this.unsubscribe$),
                skip(1))
            .subscribe({
                next: value => {
                    if (value) {
                        this.getParents(value);
                    } else {
                        this.resetParent();
                    }
                }
            });
    }

    public onParentChange(event): void {
        if (event.value) {
            this.filterForm.controls.ParentId.setValue(event.value.ParentId,
                {emitEvent: false, emitModelToViewChange: false});
            this.filterForm.controls.Id.setValue(event.value.Id);
        }
    }

    public onPartnerChange(event): void {
        const parentFilterValue = this.onParentFilterChange$.getValue();
        if (parentFilterValue) {
            this.getParents(parentFilterValue);
        }
    }

    public resetParent(): void {
        this.parents = [];
        this.filterForm.controls.ParentId.setValue(this.selectedParentOption);
        this.filterForm.controls.Id.setValue(null);
    }

    public getDateTimeComponent($event: any): void {
        this.dateTime = $event;
    }

    public makeCustomMonthYearButtonsFunctionality(): void {
        const parentDiv: Element = document.querySelector('.owl-dt-calendar-control-content');
        const monthYearBtn: HTMLElement = document.querySelector('.owl-dt-control-period-button') as HTMLElement;
        const monthYearBtnLabel: Element = monthYearBtn.querySelector('.owl-dt-control-content');
        const monthButton = this.createHTMLElement(
            'button',
            ['owl-dt-control', 'owl-dt-control-button', 'owl-dt-control-period-button', 'custom-month-button']
        );

        const monthButtonSpan: HTMLSpanElement = this.createHTMLElement(
            'span',
            ['owl-dt-control-content', 'owl-dt-control-button-content'],
            monthYearBtnLabel.textContent.substring(0, 5)
        );
        monthButtonSpan.style.paddingRight = '.25em';

        const yearButton: HTMLButtonElement = this.createHTMLElement(
            'button',
            ['owl-dt-control', 'owl-dt-control-button', 'owl-dt-control-period-button', 'custom-year-button']
        );

        const yearButtonSpan: HTMLSpanElement = this.createHTMLElement(
            'span',
            ['owl-dt-control-content', 'owl-dt-control-button-content'],
            monthYearBtnLabel.textContent.substring(5)
        );
        yearButtonSpan.style.paddingLeft = '0';

        const arrowSVG: Node = monthYearBtn.querySelector('.owl-dt-control-button-arrow').cloneNode(true);

        this.selectedMonth = monthButtonSpan.textContent;
        this.selectedYear = yearButtonSpan.textContent;

        monthButton.appendChild(arrowSVG.cloneNode(true));
        monthButton.appendChild(monthButtonSpan.cloneNode(true));
        yearButton.appendChild(arrowSVG.cloneNode(true));
        yearButton.appendChild(yearButtonSpan.cloneNode(true));
        parentDiv.insertBefore(monthButton, monthYearBtn);
        parentDiv.insertBefore(yearButton, monthYearBtn);
        monthYearBtn.style.display = 'none';

        this.followMonthYearBtnTextContentChanges(monthButtonSpan, yearButtonSpan, monthYearBtn);

        const clickEvent: MouseEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });

        monthButton.addEventListener('click', (): void => {
            this.isSelectedMonthView = true;
            monthYearBtn.dispatchEvent(clickEvent);
            if (this.isSelectedYearView) {
                monthYearBtn.dispatchEvent(clickEvent);
                this.isSelectedYearView = false;
            }
            setTimeout((): void => {
                const selectedDates = [...this.dateTime.selecteds];
                const selectedYear: Element = document.querySelector('.owl-dt-calendar-cell-active');
                selectedYear.dispatchEvent(clickEvent);
                this.dateTime.selecteds = [...selectedDates];
            });
        });

        yearButton.addEventListener('click', (): void => {
            this.isSelectedYearView = !this.isSelectedYearView;
            monthYearBtn.dispatchEvent(clickEvent);
            if (this.isSelectedMonthView) {
                monthYearBtn.dispatchEvent(clickEvent);
                this.isSelectedMonthView = false;
            }
        });
    }


    private createHTMLElement(elementType: string, classes: Array<string>, textContent?: string): any {
        const el: HTMLElement = document.createElement(elementType);
        classes.forEach((className: string): void => {
            el.classList.add(className);
        });
        if (textContent) {
            el.textContent = textContent;
        }
        return el;
    }

    public onMonthClicked(selectedMonth: moment.Moment): void {
        this.isSelectedMonthView = false;
        if (this.selectedMonth.trim() !== selectedMonth.format('MMM')) {
            this.selectedMonth = selectedMonth.format('MMM');
        }
    }

    public onYearClicked(selectedYear: moment.Moment): void {
        this.isSelectedMonthView = true;
        this.isSelectedYearView = false;
        if (this.selectedYear.trim() !== selectedYear.year().toString()) {
            this.selectedYear = selectedYear.year().toString();
        }
    }

    private followMonthYearBtnTextContentChanges(
        monthButtonSpan: HTMLSpanElement,
        yearButtonSpan: HTMLSpanElement,
        monthYearBtn: HTMLElement
    ): void {
        const observer: MutationObserver = new MutationObserver((mutationsList: MutationRecord[]): void => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'characterData') {
                    const newValue: string = mutation.target.textContent;

                    const yearButton: HTMLElement = document.querySelectorAll('.owl-dt-control-period-button')[1] as HTMLElement;
                    const yearButtonLabel: Element = yearButton.querySelector('.owl-dt-control-content');

                    if ( isNaN(+newValue.trim()) ) {
                        const monthButton: HTMLElement = document.querySelectorAll('.owl-dt-control-period-button')[0] as HTMLElement;
                        const monthButtonLabel: Element = monthButton.querySelector('.owl-dt-control-content');
                        monthButtonLabel.textContent = newValue.substring(0, 5);
                        this.selectedMonth = monthButtonSpan.textContent;
                        yearButtonLabel.textContent = newValue.substring(5);
                        this.selectedYear = yearButtonSpan.textContent;
                    } else {
                        yearButtonLabel.textContent = newValue;
                        this.selectedYear = yearButtonSpan.textContent;
                    }
                }
            }
        });

        // Configure and start observing the month-year-button's textContent

        observer.observe(monthYearBtn, { characterData: true, subtree: true });
    }
}
