<div class="layout-sidebar" (click)="appMain.onMenuClick($event)">
    <a [routerLink]="['/']" class="logo">
        <img id="app-logo"
             class="logo-image"
             [src]="!appMain.isDesktop() || !(appMain.staticMenuDesktopInactive && appMain.isStatic())
             ? themeService.selectedTheme?.PrimaryLogo : themeService.selectedTheme?.PrimaryShortLogo"
             alt="logo"/>
    </a>

    <div class="layout-menu-container" (scroll)="onScrollSidebar($event)">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model$ | async; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>
    <app-footer></app-footer>
</div>
