<div class="modal-info-container">
    <div *ngFor="let item of dialogInfo" class="modal-info-item">
        <i [class]="item.Icon"></i>
        <div class="flex flex-column flex-gap">
            <div class="item-title">{{ item.Title.toUpperCase() }}</div>
            <div class="item-data-with-navigation"
                 *ngIf="item.HasNavigation; else withoutNavigation" [routerLink]="item.Data.toString()">
                {{ item.Data }}
            </div>
            <ng-template #withoutNavigation>
                <div class="item-detail">{{ item.Data !== null ? item.Data : '-' }}</div>
            </ng-template>
        </div>
    </div>
</div>

