export enum StatesEnum {
    Active = 1,
    Deleted = 2
}

export enum MemberStatesEnum {
    Active = 1,
    Blocked = 2
}

export enum MessageStatesEnum {
    Unread = 1,
    Read = 2,
}

export enum BetStatesEnum {
    New = 1,
    Win = 2,
    Lost = 3,
    Deleted = 4,
    Paid = 5,
    Return = 6,
    Cashouted = 7,
    Rejected = 9,
    TnmBonusComplete = 94,
    TnmBonusClose = 95,
    TnmBonusPreClose = 97,
}

export enum PartnerProductStatesEnum {
    Active = 1,
    Inactive = 2,
}

export enum AdminUserStatesEnum {
    Active = 1,
    Inactive = 2,
}
