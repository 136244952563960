import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import {
    IMenuItem,
    IUserPermissions,
    IPermissions,
    IAgentPermissions,
} from '@core/interfaces';
import { environment } from '@env/environment';
import permissions from '@permissions/permissions.json';

@Injectable({
    providedIn: 'root'
})

export class PermissionsService {

    public userPermissions$: BehaviorSubject<Partial<IUserPermissions | IAgentPermissions>> =
        new BehaviorSubject<Partial<IUserPermissions | IAgentPermissions>>(null);
    public permissions: IPermissions = null;
    private isAdmin: boolean = environment.role === 'admin';

    public filteredAdminMenuItems: IMenuItem[] = [];

    constructor(private cookieService: CookieService) {
        if (this.cookieService.get('permissions')) {
            this.userPermissions$.next(JSON.parse(this.cookieService.get('permissions')) ?
                JSON.parse(this.cookieService.get('permissions')) : null);
        }
        this.permissions = permissions;
        this.filterAdminMenu();
    }

    public filterAdminMenu(): void {
        /* Only For Admin Check Menu Permissions(There Are No Menu Permissions For Agent Mode) */
        if (this.isAdmin) {
            this.userPermissions$
                .pipe(
                    filter(perms => !!perms)
                )
                .subscribe({
                    next: (userPermissions: Partial<IUserPermissions>) => {
                        this.filteredAdminMenuItems = structuredClone(environment.menuItems).items.filter((menuItem: IMenuItem) => {
                            if (!menuItem.items) {
                                return menuItem.permissions
                                    .every((requiredPermission: string) => userPermissions.hasOwnProperty(requiredPermission));
                            } else {
                                menuItem.items = menuItem.items.filter((item: IMenuItem) => {
                                    return item.permissions
                                        .every((requiredPermission: string) => userPermissions.hasOwnProperty(requiredPermission));
                                });
                                return menuItem.items.length;
                            }
                        });
                    }
                });
        }
    }

    public checkPermission(requiredPermissions: string[] | null): boolean {
        let hasPermissions = false;

        for (const requiredPermission of requiredPermissions) {
            if (!this.userPermissions$.getValue()[requiredPermission]) {
                hasPermissions = this.userPermissions$.getValue()[requiredPermission];
                break;
            }

            hasPermissions = this.userPermissions$.getValue()[requiredPermission];
        }

        if (!this.isAdmin && hasPermissions === undefined) {
            /* In This Case We Are Agent, And We Don't Need To Check For Admin Permissions */
            hasPermissions = true;
        }

        return hasPermissions;
    }


}

