import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { EmailOrMobileEnum } from '@core/enums';
import { passwordsMatch } from '@core/validators';
import { IChangePasswordForm } from '@core/interfaces';

@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {
    public changePasswordForm: FormGroup = new FormGroup<IChangePasswordForm>({
            Password: new FormControl(null, [
                Validators.required,
                Validators.maxLength(30)
            ]),
            ConfirmPassword: new FormControl(null, Validators.required),
            // EmailOrMobile: new FormControl(null, Validators.required),
        },
        { validators: passwordsMatch('Password', 'ConfirmPassword') }
    );

    public emailOrMobile = EmailOrMobileEnum;
    public pending: boolean = false;

    constructor(
        public ref: DynamicDialogRef,
        private router: Router,
    ) {
        this.subscribeToRouterEvents();
    }

    public onCancel(): void {
        this.ref.close();
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }

    public onChangePassword(): void {
        if (this.changePasswordForm.valid) {
            this.ref.close(this.changePasswordForm.value);
        } else {
            this.changePasswordForm.markAllAsTouched();
        }
    }
}
