import { Injectable } from '@angular/core';

import { IPartnerConfig, IPartnerConfigs, ISelectedTheme } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {

    public colorScheme = localStorage.getItem('selectedTheme') || 'light';
    public selectedTheme: ISelectedTheme = {};

    public setRootColorVariables(): void {
        // const colors = [
        //     {
        //         colors: {
        //             'primary-color': '#2196F3',
        //             'primary-dark-color': '#0d89ec',
        //             'primary-darker-color': '#0b7ad1',
        //             'primary-light-color': '#a6d5fa',
        //             'primary-color-text': '#fff',
        //             'sidebar-color': '#4a5d62',
        //             'sidebar-color-alt': '#2e3439',
        //             'sidebar-active-menuitem-color': '#3f474e',
        //             'sidebar-text-color': '#fff',
        //             'sidebar-text-active-color': '#fff',
        //             'sidebar-hover-color': '#4b545c',
        //         },
        //         logoUrl: 'assets/layout/images/logo-white.svg'
        //     },
        //
        //     {
        //         colors: {
        //             'primary-color': '#90CAF9',
        //             'primary-dark-color': '#6bb8f7',
        //             'primary-darker-color': '#3ba1f4',
        //             'primary-light-color': '#fcfeff',
        //             'primary-color-text': '#000',
        //             'sidebar-color': '#4a5d62',
        //             'sidebar-color-alt': '#2e3439',
        //             'sidebar-active-menuitem-color': '#3f474e',
        //             'sidebar-text-color': '#fff',
        //             'sidebar-text-active-color': '#fff',
        //             'sidebar-hover-color': '#4b545c',
        //         },
        //         logoUrl: 'assets/layout/images/logo-dark.svg'
        //     }
        // ];
        //
        // localStorage.setItem('colorScheme', JSON.stringify(colors));

        const root = document.documentElement;
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        const selectedScheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'LightColors').Value :
            configs?.find(config => config.Key === 'DarkColors').Value;
        selectedScheme?.forEach(color => {
            root.style.setProperty(`--${color.Key}`, color.Value);
        });
    }

    public setSelectedTheme(): void {
        const configs: IPartnerConfigs[] = JSON.parse(localStorage.getItem('generalConfigs'));
        const selectedTheme: IPartnerConfig[] = this.colorScheme === 'light' ?
            configs?.find(config => config.Key === 'Light').Value :
            configs?.find(config => config.Key === 'Dark').Value;
        selectedTheme?.forEach(scheme => {
            this.selectedTheme[scheme.Key] = scheme.Value;
        });
    }

    public changeStyleSheetsColor(id, value, from): void {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {       // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    private replaceLink(linkElement, href): void {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    private isIE(): boolean {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

}
