<div class="inner-page-main-section">

    <form [formGroup]="commissionPlanForm" (ngSubmit)="saveCommissionPlan()">

        <div class="p-fluid p-formgrid grid form">
                    <div class="field col-12">
                        <label for="Period">{{ 'Period' | translate }} *</label>
                        <p-dropdown [options]="periods"
                                    [panelStyleClass]="'dialog-dropdown'"
                                    appendTo="body"
                                    formControlName="Period"
                                    optionValue="Value"
                                    id="Period"
                                    [placeholder]="'Select' | translate"
                                    [ngClass]="{'ng-touched ng-invalid' : commissionPlanForm.controls.Period.touched
                                                && commissionPlanForm.controls.Period.invalid}">

                            <ng-template let-option pTemplate="selectedItem">
                                <span>{{ ('EnumCommissionPlanPeriod' + option.Name) | translate }}</span>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <span>{{ ('EnumCommissionPlanPeriod' + option.Name) | translate }}</span>
                            </ng-template>
                        </p-dropdown>
                        <small
                            *ngIf="commissionPlanForm.controls.Period.touched
                                           && commissionPlanForm.controls.Period.hasError('required')"
                            class="p-error">
                            {{ 'FieldRequiredErrorMessage' | translate }}
                        </small>
                    </div>
                    <div class="field col-12">
                        <label for="CalculationTime">{{ 'CalculationTime' | translate }} *</label>
                        <p-calendar
                            formControlName="CalculationTime"
                            [showTime]="true"
                            [showIcon]="true"
                            [placeholder]="'SelectCalculationTime' | translate"
                            appendTo="body"
                            [dateFormat]="'dd.mm.yy'"
                            id="CalculationTime"
                        ></p-calendar>
                        <small
                            *ngIf="commissionPlanForm.controls.CalculationTime.touched
                                           && commissionPlanForm.controls.CalculationTime.hasError('required')"
                            class="p-error">
                            {{ 'FieldRequiredErrorMessage' | translate }}
                        </small>
                    </div>
                    <div class="field col-12">
                        <div class="field-radiobutton">
                            <p-checkbox binary="false"
                                        name="negativeCarryOver"
                                        formControlName="IsNegative"
                                        inputId="negativeCarryOver">
                            </p-checkbox>
                            <label [for]="'negativeCarryOver'">{{ 'NegativeCarryOver' | translate }}</label>
                        </div>
                    </div>
        </div>

        <div class="p-dialog-footer">
            <button
                type="button"
                pButton
                pRipple
                [label]="'Cancel' | translate"
                (click)="onCancel()"
                icon="pi pi-times"
                class="p-button-text p-button-secondary"
            ></button>
            <button
                type="submit"
                pButton
                pRipple
                [loading]="pending"
                [label]="'Save' | translate"
                [disabled]="commissionPlanForm.valid && commissionPlanForm.pristine"
                icon="pi pi-check"
                class="p-button-text"
            ></button>
        </div>

    </form>
</div>
